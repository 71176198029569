import _ from 'lodash';
import { strToDate, addOffset } from './convert';
import * as storage from './storage';

/** @module utils/helper */

const dateTypes = [
  "date",
  "time",
  "datetime",
  "dateinput",
  "timeinput",
  "datetimeinput",
  "4",
  "8",
  "13",
  "18",
  "19",
  "20"
];

export const isDateType = (type) => {
  return dateTypes.includes(type);
}


/**
 * Checks if input string is null or empty.
 * e.g., '' -> true
 * @function stringIsNullOrEmpty
 * @param {string} string string input
 * @returns {boolean}
 */
export const stringIsNullOrEmpty = (string) => {
  return (!string || string.length === 0 || !string.trim() || /^\s*$/.test(string));
}

/**
 * Returns url as string which is corrected by set theme.
 * @function buildUrl
 * @param {string} name url
 * @returns {(string|void)}
 */
export const buildUrl = (name) => {
  const theme = document.body.className;
  if (name) {
    switch (theme) {
      case 'theme-dark':
        name = name.replace(".svg", "-dark.svg");
        break;
      case 'theme-pink':
        name = name.replace(".svg", "-pink.svg");
        break;
      case 'theme-underwater':
        name = name.replace(".svg", "-underwater.svg");
        break;
      case 'theme-army':
        name = name.replace(".svg", "-army.svg");
        break;
      default:
        return name;
    }
    return name;
  }
}

/**
 * 
 * @param {array} data 
 * @returns {array} kde je hodnota property x.disabled zmenena na undefined 
 */
export const removeDisabledPropertyFromArrayItems = (data) => {
  if (data && Array.isArray(data) && data.length > 0) {
    let _data = _.cloneDeep(data);
    _data = _data.map((x) => {
      if (x.hasOwnProperty('disabled')) {
        x.disabled = undefined;
      }
      return x;
    });
    return _data;
  } else {
    return [];
  }
}

/**
 * Returns Google OAuth url
 * @function buildGoogleOauthUrl
 * @param {string} redirectURI - Redirect url
 * @param {string} state - State
 * @returns {string}
 */
export const buildGoogleOauthUrl = (redirectURI, state) => {
  const cfg = storage.getLoginConfig();
  return `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&response_type=code&redirect_uri=${redirectURI}&client_id=${cfg.google.clientId}${state !== undefined ? `&state=${state}` : ''}`;
}

/**
 * Returns version of client as string.
 * @function getClientVersion
 * @returns {string}
 */
export const getClientVersion = () => {
  return process.env.REACT_APP_VERSION.substring(0, 4) + '.' + process.env.REACT_APP_VERSION.substring(4);
}

/**
 * Executes every function in child schema elements.
 * @function eachDeep
 * @param {Array} columns array schema object
 * @param {Object} parent parent object
 * @param {Function} fn function to be executed
 */
export var eachDeep = (columns, parent, fn) => {
  if (!_.isArray(columns)) return;
  columns.forEach(item => {
    eachDeep(item.schema, item, fn);
    fn(item, parent);
  });
}

/**
 * Copy string to clipboard.
 * @function copyToClipboard
 * @param {string} data string input
 */
export var copyToClipboard = (data) => {
  var actEl = document.activeElement;
  var el = document.createElement("textarea");
  el.value = data;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  actEl.focus();
}

/**
 * Replaces diacritical mark letters in input string with letters without diacritical mark.
 * @function removeAccents
 * @param {string} str string input
 * @returns {string}
 */
export var removeAccents = (str) => {
  var accents = 'ÁÄáäÓÔóôÉéČčĎďÍíÚúŇňŠšÝýŽžĹĽĺľŕŤť';
  var accentsOut = "AAaaOOooEeCcDdIiUuNnSsYyZzLLllrTt";
  str = str.split('');
  var strLen = str.length;
  var i, x;
  for (i = 0; i < strLen; i++) {
    if ((x = accents.indexOf(str[i])) !== -1) {
      str[i] = accentsOut[x];
    }
  }
  return str.join('');
}

/**
 * Returns new filter for input object.
 * @function buildFilter
 * @param {Object} input input object schema
 * @param {Array} schema
 * @returns {Object}
 */
export const buildFilter = (input, schema) => {
  let newFilter = []
  for (let [key, value] of Object.entries(input)) {
    if (value || value === false) {
      const field = key.replace(/(Value|Operator)$/, '');
      const column = schema.find(item => item.field === field || (item.filter === 'container' && (item.field + '.' + item.textField) === field));

      if (column.filter === 'date' && value instanceof Date) {
        value.setHours(0, 0, 0, 0);
      }

      let oldItem = newFilter.find(item => item.field === field);
      if (oldItem) {
        oldItem.field + 'Operator' === key ? oldItem.operator = value : oldItem.value = value
        newFilter = [...newFilter]
      }
      else {
        let newFilterItem
        if (key.search("Value") !== -1) {
          newFilterItem = {
            field: field,
            operator: column.filter && [...dateTypes, 'numeric', 'unitnumeric', 'boolean', 'enum', 'buttongroup', 'radio'].includes(column.filter) ? 'eq' : 'contains',
            value: value,
            typeId: column.filter === 'datetime' ? 13 : null
          }
        }
        else {
          newFilterItem = {
            field: field,
            operator: value,
            value: "",
            typeId: column.filter === 'datetime' ? 13 : null
          }
        }
        newFilter = [...newFilter, newFilterItem]
      }
    }
  }
  newFilter = {
    logic: "and",
    filters: [
      ...newFilter,
    ]
  }
  return newFilter;
}

/**
 * Returns boolean that determines whether the email is valid. 
 * @function validEmail
 * @param {string} email string input which is going to be validated
 * @returns {boolean}
 */
export const validEmail = (email) => { return /\S+@\S+\.\S+/.test(email); }

/**
 * Adds target route to route history.
 * @function onMenuSelect
 * @param {string} targetRoute target of route
 * @param {string} currLocation current location
 * @param {Array} routeHistory history of route
 */
export const onMenuSelect = (targetRoute, currLocation, routeHistory) => {
  if (targetRoute === undefined || currLocation === undefined)
    return;

  if (currLocation !== targetRoute)
    routeHistory.push(targetRoute);
}

/**
 * Returns object of url params from string. 
 * @function getURLParams
 * @param {Array} names array of names of params.
 * @returns {Object}
 */
export const getURLParamsByNames = (names) => {
  const urlParamsPart = window.location.href.split('?')[1];
  if (urlParamsPart === undefined)
    return {};

  const paramsWithValues = urlParamsPart.split('&');
  let URLParams = {};

  names.forEach(name => {
    const paramWithValue = paramsWithValues.find(p => p.includes(name));
    if (paramWithValue)
      URLParams[name] = paramWithValue.split('=')[1];
  });
  return URLParams;
}

/**
* After Login handle
* @function getURLParams
* @param {string} username user name to log in.
* @returns {Object}
*/
export const afterLoginHandle = (props) => {
  let {
    afterLoginlocation,
    location,
    navigate,
    history,
    user
  } = props;

  afterLoginlocation = afterLoginlocation ? afterLoginlocation : user.Route ? { from: { pathname: user.Route } } : { from: { pathname: '/' } };
  if (location && location.state && location.state.from) {
    const { pathname } = location.state.from;
    if (pathname !== "/" && !pathname.includes("/prihlasenie") && !pathname.includes("/stranka_nenajdena"))
      afterLoginlocation = location.state;
  }

  const { from } = afterLoginlocation;
  navigate ? navigate(from) : history.replace(from);//navigate - react router v6; history - react router v5
}

/**
 * Returns new items without textfield.
 * @function removeTextField
 * @param {Array} items
 * @param {Array} schema
 * @returns {Object}
 */
export const removeTextField = (items, schema) => {
  items.map(el => {
    const col = schema.find(item => item.field + "." + item.textField === el.field)
    if (col) {
      el.field = col.field;
    }
    return el;
  })
  return items;
}

/**
 * Returns new items with textfield.
 * @function removeTextField
 * @param {Array} items
 * @param {Array} schema
 * @returns {Object}
 */
export const addTextField = (items, schema) => {
  items.map(el => {
    const col = schema.find(item => item.field === el.field)
    if (col && col.filter === "container" && el.field !== col.field + "." + col.textField) {
      el.field = col.field + "." + col.textField;
    }
    return el;
  })
  return items;
}

/**
 * Returns field format (replace {0:} chars). 
 * @function fieldFormat
 * @param {string} columnFormat
 * @returns {string}
 */
export const fieldFormat = (columnFormat) => { return columnFormat ? columnFormat.replace(/^\{\d:|\}$/g, "") : null; }

/** 
* Return parsed default value
* @function parseValue
* @param {string} defaultValue
* @param {string} type
* @return {*} 
*/
export const parseValue = (value, type) => {
  if (_.isNil(value)) return null;

  const user = storage.getUser();

  if (value.startsWith('Uzivatel.')) {
    return _.get(user, value);
  }

  switch (value) {
    case "CURRENTUSER":
      return user.I_UZ;
    case "YEAR":
      return (new Date()).getFullYear();
    case "PREV_YEAR":
      return (new Date()).getFullYear() - 1;
    case "MONTH":
      return (new Date()).getMonth() + 1;
    case "PREV_MONTH":
      const month = (new Date()).getMonth() + 1;
      return month === 1 ? 12 : month - 1;
    // no default
  }

  switch (type) {
    case "dateinput":
    case "date":
    case "datetime":
    case "datetimeinput":
    case "time":
    case "timeinput":
      if (value.includes("SYSDATE") || value.includes("TODAY")) {
        let newDate = new Date();
        if (value.includes("TODAY")) {
          newDate.setHours(0, 0, 0, 0);
        }
        if (value !== "SYSDATE" && value !== "TODAY") {
          newDate.setDate(newDate.getDate() + Number(value.replace('SYSDATE', '').replace('TODAY', '')));
        }
        return newDate;
      }
      else {
        return strToDate(value);
      }
    case "boolean":
      // eslint-disable-next-line
      return Boolean(eval(value));
    case "string":
    case "none":
      return value;
    default:
      try {
        // eslint-disable-next-line
        return eval(value);
      } catch (error) {
        return value;
      }
  }
}

/**
 * Get value by path (except null items).
 * @function getValueByPath
 * @param {Object} obj object
 * @param {String} path path
 */
export const getValueByPath = (obj, path) => {
  if (_.isNil(obj)) return null;
  if (_.isArray(obj)) {
    return obj.map(item => getValueByPath(item, path)).filter(item => !_.isNil(item)).flat();
  }
  const paths = path.split('.');
  const item = _.get(obj, paths[0]);
  if (paths.length === 1) {
    return item;
  }
  const subPath = paths.slice(1).join('.');
  return getValueByPath(item, subPath);
}

/**
 * Get primary field without copy/picker part.
 * @function getPrimaryField
 * @param {String} primaryField primaryField
 */
export const getPrimaryField = (primaryField) => {
  return primaryField?.split('-').pop();
}

/**
 * Get decimals count 
 * @function getDecimalsCount
 * @param {decimal} value value
 */
export const getDecimalsCount = (value) => {
  if (!value || Math.floor(value) === value) return 0;
  return value.toString().split(".")[1]?.length || 0;
}

/**
 * Process data before UID request
 * @function processBefore
 * @param {array} schema schema
 * @param {array} d data
 */
export const processBefore = (schema, d) => {
  const dateColumns = schema.filter(x => isDateType(x.type));
  const enumColumns = schema.filter(x => x.type === 'enum' || x.type === 'buttongroup' || x.type === 'radio');
  const data = { ...d };
  dateColumns.forEach(x => {
    _.set(data, x.field, addOffset(_.get(data, x.field)));
  });
  enumColumns.forEach(x => {
    if (x.primaryField === "I_HOD") {
      data[x.field] = _.isNil(data[x.field]) ? null : data[x.field][x.primaryField];
    }
  });
  schema.forEach(x => {
    if ((x.type === "numeric" || x.type === "unitnumeric") && getDecimalsCount(data[x.field]) > 3) {
      data[x.field] = data[x.field].toString();
    }
    if (x.type === "container") {
      x.schema.forEach(column => {
        if (column.type === "enum" || column.type === "buttongroup" || column.type === "radio") {
          if (data[x.field] && data[x.field][column.field] && (typeof data[x.field][column.field] === 'object')) {
            const val = data[x.field][column.field][column.primaryField];
            data[x.field][column.field] = _.isNil(val) ? null : val;
          }
        }
      })
    }
    if (x.type.replace("input", "") === "daterange" || x.type === "21") {
      let dateRangeObj = _.get(data, x.field);
      if (dateRangeObj && dateRangeObj.start && dateRangeObj.end) {
        dateRangeObj.start = addOffset(dateRangeObj.start);
        dateRangeObj.end = addOffset(dateRangeObj.end);
        _.set(data, x.field, dateRangeObj);
      }
    }
  })
  return data;
}

/**
 * Process data after GET request
 * DateTime from server should not end with Z (wrong UTC).
 * @function processAfter
 * @param {array} schema schema
 * @param {array} d data
 */
export const processAfter = (schema, data) => {
  if (!schema || schema.length === 0) { return data; }
  const dateColumns = schema.filter(x => isDateType(x.type));
  dateColumns.forEach(column => {
    const value = _.get(data, column.field);
    _.set(data, column.field, strToDate(!value || value instanceof Date ? value : value.replace(/Z$/, "")));
  })
  const dateRangeColumns = schema.filter(x => x.type?.replace("input", "") === "daterange" || x.type === "21");
  dateRangeColumns.forEach(column => {
    const value = _.get(data, column.field);
    if (value && value.start && value.end) {
      value.start = strToDate(!value.start || value.start instanceof Date ? value.start : value.start.replace(/Z$/, ""))
      value.end = strToDate(!value.end || value.end instanceof Date ? value.end : value.end.replace(/Z$/, ""))
      _.set(data, column.field, value);
    }
  })
  return data;
}
/**
 * Process data after GET request
 * Emus converts to objects.
 * @function processAfter
 * @param {array} schema schema
 * @param {object} data data
 */
export const processEnumsAfter = (schema, data) => {
  const enumColumns = schema?.filter(x => x.type === 'enum' || x.type === 'buttongroup' || x.type === 'radio');
  enumColumns.forEach(column => {
    if (typeof (data[column.field]) !== 'object') {
      const value = column.enumData?.find(el => el[column.primaryField] === _.get(data, column.field) || el[column.primaryField] === _.get(data, column.field + "." + column.primaryField)) || null
      _.set(data, column.field, value);
    }
  })
  return data;
}

/**
 * Builds the validation message for a field based on its configuration and value.
 * @param {Object} field - The field object containing information like field name, type, title, etc.
 * @param {*} value - The current value of the field.
 * @param {boolean} [invalidSecondPassword=false] - Whether the passwords fields are invalid or not.
 * @returns {string} - Returns the validation message for the field.
 */
export const buildValidationMessage = (field, value, invalidSecondPassword = false) => {
  const { type, validationMessage, min, max, minLength, maxLength, required, recommended } = field;

  if (type === "password" && value && invalidSecondPassword) {
    return "Heslá sa nezhodujú";
  }

  if (validationMessage) return validationMessage;

  if (value && ["numeric", "unitnumeric"].includes(type) && ((min && value < min) || (max && value > max))) {
    if (min && max) {
      return `Hodnota musí byť od ${min} do ${max}`;
    } else if (min) {
      return `Hodnota musí byť väčšia ako ${min}`;
    } else if (max) {
      return `Hodnota musí byť menšia ako ${max}`;
    }
  } else if (value && ["string", "password"].includes(type) && ((minLength && value.length < minLength) || (maxLength && value.length > maxLength))) {
    if (minLength && maxLength) {
      return `Dĺžka musí byť od ${minLength} do ${maxLength} znakov`;
    } else if (minLength) {
      return `Minimálny počet znakov: ${minLength}`;
    } else if (maxLength) {
      return `Maximálny počet znakov: ${maxLength}`;
    }
  } else if (value && (type === "date") && value instanceof Date && ((min && value.getTime() < min.getTime()) || (max && value.getTime() > max.getTime()))) {
    if (min && max) {
      return `Dátum musí byť od ${min.toLocaleDateString("sk-SK")} do ${max.toLocaleDateString("sk-SK")}`;
    } else if (min) {
      return `Dátum musí byť po ${min.toLocaleDateString("sk-SK")}`;
    } else if (max) {
      return `Dátum musí byť pred ${max.toLocaleDateString("sk-SK")}`;
    }
  }

  if (type === "email" && value) {
    return "Nesprávny formát emailu";
  }
  if (type === "password" && value && !new RegExp(window.config.passwordPattern).test(value)) {
    return window.config.passwordErrMsg;
  }
  if (isDateType(type) && value && /\d/.test(value)) {
    return "Nesprávne vyplnené údaje";
  }
  if (type === "daterangeinput" && value && ((value.start && !(value.start instanceof Date)) || (value.end && !(value.end instanceof Date)) || ((value.start === null && value.end !== null && !required) || (value.end === null && value.start !== null && !required)))) {
    return "Nesprávne vyplnené údaje";
  }
  if (["container", "autocomplete"].includes(type) && value && !(value instanceof Object)) {
    return "Nesprávne vyplnené údaje";
  }
  if ((type === "multipicker" || type === "multi") && (Array.isArray(value) && value.some(item => !(item instanceof Object)))) {
    return "Nesprávne vyplnené údaje";
  }

  if (!value && recommended && !required) {
    return "Toto pole je odporúčané";
  }

  return "Toto pole je povinné";
};

/**
 * Checks if a field should be recommended and is invalid.
 * @param {Object} field - The field object containing information like field name, type, title, etc.
 * @param {*} value - The current value of the field.
 * @returns {boolean} - Returns true if the field should be recommended as invalid, otherwise false.
 */
export const isRecommendedInvalid = (field, value) => {
  return (hasValue(value) && field.recommended);
}

/**
 * Is value invalid
 * @function isInvalid
 * @param {Object} field field
 * @param {Object} value value
 * @param {Object} data  form data
 */
export const isInvalid = async (field, value, data = null) => {
  if (field.disabled || field.hidden) {
    return false;
  }

  const invalid = field.isInvalid && await field.isInvalid(field, value, data);

  if (invalid) {
    return true;
  }

  switch (field.type) {
    case 'string':
    case 'textarea':
    case 'text':
      return isInValidString(field, value);
    case 'email':
      return isInValidEmail(field, value);
    case 'password':
      return isInValidPassword(field, value);
    case 'numeric':
    case 'unitnumeric':
      return isInValidNumeric(field, value);
    case 'date':
    case 'time':
    case 'datetime':
    case 'dateinput':
    case 'timeinput':
    case 'datetimeinput':
      return isInValidDate(field, value);
    //case 'daterange':
    case 'daterangeinput':
      return isInValidDateRange(field, value);
    case 'container':
    case 'autocomplete':
      return isInValidContainer(field, value);
    case 'multipicker':
    case 'multi':
      return isInValidMulti(field, value);
    default:
      return false;
  }
}

export const hasValue = (disabled, value) => {
  return ((!(value || value === 0 || value === false) || (/^\s+$/gm.test(value)) || (value && (Array.isArray(value) && value.length === 0))) && !disabled);
}

const hasPattern = (pattern, value) => {
  return (value && pattern && !new RegExp(pattern).test(value));
}

/* 
 * Validation for Input.js, Textarea.js 
 * type = string, text, textarea
 */
export const isInValidString = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value && ((field.minLength && (value.length < field.minLength)) || ((field.maxLength && (value.length > field.maxLength)))))
  );
}

/* 
 * Validation for Input.js 
 * type = email
 */
export const isInValidEmail = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value && !new RegExp(/^\S+@\S+\.\S+$/).test(value))
  );
}

/* 
 * Validation for Password.js 
 * type = password
 */
export const isInValidPassword = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value && ((field.minLength && (value.length < field.minLength)) || ((field.maxLength && (value.length > field.maxLength))) || (!new RegExp(window.config.passwordPattern).test(value))))
  );
}

/* 
 * Validation for NumericTextbox.js, UnitNumericTextbox.js
 * type = numeric, unitnumeric
 */
export const isInValidNumeric = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value !== null && value !== undefined && ((field.min && (value < field.min)) || ((field.max && (value > field.max)))))
  );
}

/* 
 * Validation for DatePicker.js, TimePicker.js, DateTimePicker.js, DateInput.js 
 * type = date, time, datetime, dateinput, timeinput, datetimeinput
 */
export const isInValidDate = (field, value) => {
  value = value && !/\d/.test(value) ? null : value;
  return (
    (hasValue(field.disabled, value) && field.required)
    || hasPattern(field.pattern, value)
    || (value && /\d/.test(value) && !(value instanceof Date))
    || (value && ((field.min && (value.getTime() < field.min.getTime())) || (field.max && (value.getTime() > field.max.getTime()))))
  );
}

/* 
 *  Validation for DateRangeInput.js, DateRangePicker.js
 *  type = daterangeinput, daterange
 */
export const isInValidDateRange = (field, value) => {
  return (
    ((hasValue(field.disabled, value.start) && field.required) || (hasValue(field.disabled, value.end) && field.required)) || hasPattern(field.pattern, value)
    || (value && ((value.start !== null && !(value.start instanceof Date)) || (value.end !== null && !(value.end instanceof Date))))
    || (value && ((value.start === null && value.end !== null) || (value.end === null && value.start !== null)))
  );
}

/* 
 * Validation for Picker.js, AutoComplete.js
 * type = container, autocomplete
 */
export const isInValidContainer = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value && !(value instanceof Object))
  );
}

/**
 * Validation for MultiPicker.js, MultiSelect.js 
 * type = multipicker, multi
 */
export const isInValidMulti = (field, value) => {
  return (
    (hasValue(field.disabled, value) && field.required) || hasPattern(field.pattern, value)
    || (value && (Array.isArray(value) && value.some(item => !(item instanceof Object))))
  )
}

/**
 * Text for discard/save changes dialog.
 * @function buildSaveDiscardText
 * @param {Object} id id
 * @param {Boolean} saveChanges saveChanges
 * @param {string} saveChangesDialogText saveChangesDialogText
 * @param {string} discardText discardText
 */
export const buildSaveDiscardText = (id, saveChanges, saveChangesDialogText, discardText) => {
  const akcia = saveChanges ? "uložiť" : "zahodiť";
  const text = saveChanges ? saveChangesDialogText : discardText;

  return text ? text
    : id
      ? `Máte rozpracovaný záznam ${id}. Chcete ${akcia} zmeny?`
      : `Máte rozpracovaný nový záznam. Chcete ${akcia} zmeny?`;
};

/**
 * Add filter to history.
 * @function addHistoryFilter
 * @param {Object} value value
 * @param {string} primaryField primaryField
 * @param {Boolean} isFast isFast
 */
export const addHistoryFilter = (value, primaryField) => {
  if (_.isEmpty(value.filters)) { return; }

  let allFilters = JSON.parse(localStorage.getItem("historyFilterList")) || {};
  const filters = value.filters;
  const oldFilter = (allFilters?.[primaryField] || []).filter(x =>
    _.xorWith(filters, x, (a, b) => a.join === undefined && b.join === undefined ? a.field === b.field && a.operator === b.operator : _.isEqual(a, b)).length !== 0
  );
  const newFilter = [filters, ...oldFilter];
  allFilters[primaryField] = newFilter.slice(0, 20);
  localStorage.setItem("historyFilterList", JSON.stringify(allFilters));
}

/**
 * Get filter from history.
 * @function getHistoryFilter
 * @param {string} primaryField primaryField
 */
export const getHistoryFilter = (primaryField) => {
  const allFilters = JSON.parse(localStorage.getItem("historyFilterList")) || {};
  return allFilters[primaryField] || [];
}

/**
 * Returns filters array wihtout form fields.
 * @function removeFormFields
 * @param {array} filters filters
 */
export const removeFormFields = (filters) => {
  return _.map(filters, item => _.pick(item, ['field', 'operator', 'value', 'left', 'right', 'join']));
}

/**
 * Detect active user filter.
 * @function isUserFilterActive
 * @param {array} filters filters
 * @param {object} userFilter userFilter
 */
export const isUserFilterActive = (filters, userFilter) => {
  const userFilters = removeFormFields(userFilter.filters);
  return _.xorWith(userFilters, _.intersectionWith(removeFormFields(filters), userFilters, _.isEqual), _.isEqual).length === 0;
}


/**
 * Set/remove fixed filter for field and operator
 * @param {object} s 
 * @param {string|arrray} field
 * @param {object} filter
 * @param {boolean} remove
 */
export const setFixedFilter = (s, field, filter, remove) => {
  const fields = _.isArray(field) ? field : [field];
  const filters = _.isArray(filter) ? filter : [filter];
  s.detailSchema = s.detailSchema.map(x => {
    const fixedFilters = x.fixedFilter ?
      _.differenceWith(x.fixedFilter.filters, filters, (a, b) => a.field === b.field && (a.operator === b.operator || !b.operator)) : [];
    return fields.includes(x.field) ? { ...x, fixedFilter: { filters: [...fixedFilters, ...(remove ? [] : filters)] } } : x;
  });
}

/**
 * Set disabled for field
 * @param {object} s
 * @param {string|arrray} field
 * @param {boolean|function} isDisabled
 */
export const setDisabled = (s, field, isDisabled) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const disabled = _.isFunction(isDisabled) ? isDisabled(x, dataItem) : isDisabled;
    return fields.includes(x.field) ? { ...x, disabled: disabled, editable: !disabled } : x
  });
}

/**
 * Set hidden (and set hidden value) for field
 * @param {object} s
 * @param {string|arrray} field 
 * @param {boolean|function} isHidden
 * @param {object|function} hiddenValue
 */
export const setHidden = (s, field, isHidden, hiddenValue) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const hidden = _.isFunction(isHidden) ? isHidden(x, dataItem) : isHidden;
    const l_field = fields.includes(x.field);
    if (hiddenValue !== undefined) {
      const value = _.isFunction(hiddenValue) ? hiddenValue(x, dataItem) : hiddenValue;
      if (l_field && hidden) { setValue(s, x.field, value) };
    }
    return l_field ? { ...x, hidden: hidden, editable: !hidden, className: hidden ? "hidden" : null } : x
  });
}

/**
 * Set required for field
 * @param {object} s 
 * @param {string|arrray} field 
 * @param {boolean|function} isRequired
 */
export const setRequired = (s, field, isRequired) => {
  const primaryField = s.primaryField;
  const dataItem = s[primaryField].detailData;
  const fields = _.isArray(field) ? field : [field];
  s.detailSchema = s.detailSchema.map(x => {
    const required = _.isFunction(isRequired) ? isRequired(x, dataItem) : isRequired;
    return fields.includes(x.field) ? { ...x, required: required } : x
  });
}

/**
 * Set field value in detailData
 * @param {object} s 
 * @param {string|arrray} field 
 * @param {object} value
 */
export const setValue = (s, field, value) => {
  const primaryField = s.primaryField;
  const fields = _.isArray(field) ? field : [field];
  fields.forEach(x => { _.set(s[primaryField].detailData, x, value); });
}

/**
 * Get field value from detailData
 * @param {object} s 
 * @param {string} field 
 * @returns {object} 
 */
export const getValue = (s, field) => {
  const primaryField = s.primaryField;
  return _.get(s[primaryField].detailData, field);
}

/**
 * Get path from menu tree.
 * @function getPath
 * @param {string} route route
 * @param {array} menu menu
 */
export const getPath = (route, menu) => {
  // split("#")[0] - odstránenie parametrov pre dashboard
  if (menu.Route?.split("#")[0] === route) {
    return [menu];
  } else {
    if (menu.children)
      for (let child of menu.children) {
        let tmp = getPath(route, child);
        if (tmp.length) {
          return [menu, ...tmp];
        }
      }
    return [];
  }
}

/**
 * Get path from menu tree by id.
 * @function getPath
 * @param {number} id Menu id
 * @param {array} menu menu
 */
export const getPathById = (id, menu) => {
  if (menu.MenuID === id) {
    return [menu];
  } else {
    if (menu.children)
      for (let child of menu.children) {
        let tmp = getPathById(id, child);
        if (tmp.length) {
          return [menu, ...tmp];
        }
      }
    return [];
  }
}

/**
 * Funkcia vráti či rodné číslo je valídne
 * @function isBICValid
 * @param {string} rc Slovenské rodné číslo  
 * @returns {Boolean} 
 */
export const isBICValid = (rc) => {
  if (stringIsNullOrEmpty(rc)) {
    return false;
  } else {
    if (rc.indexOf('/') !== -1) {
      rc.replace('/', '');
    }
    if (rc.length < 9 || parseInt(rc) % 11 !== 0) {
      return false;
    } else return true;
  }
}

/**
 * Funkcia vráti dátum narodenia a pohlavie z rodného čisla
 * @function getBirthDateFromBIC
 * @param {string} rc Slovenské rodné číslo  
 * @returns {Object} {DateOfBitrh, Sex}
 */
export const getBirthDateFromBIC = (rc) => {
  if (isBICValid(rc)) {
    if (rc.indexOf('/') !== -1) {
      rc.replace('/', '');
    }
    let rok, mesiac, den, pohlavie;
    let parts = rc.substring(0, 6).match(/.{2}/g).map(x => parseInt(x));
    //vypocet roku
    if (rc.length === 9) {
      rok = 1900 + parts[0];
    } else if (parts[0] > 54) {
      rok = 1900 + parts[0];
    } else {
      rok = 2000 + parts[0];
    }
    //vypocet mesiaca a pohlavia
    if (parts[1] > 50) {
      mesiac = parts[1] - 51;
      pohlavie = "Ž";
    } else {
      mesiac = parts[1] - 1;
      pohlavie = "M";
    }
    //vypoced datumu
    den = parts[2];
    let date = new Date(rok, mesiac, den);
    return { DateOfBitrh: date, Sex: pohlavie };
  }
  else return null;
}

/** Parser hodnôt identifikátora použitia stĺpca v gride, detaile, vyhľadávaní a triedení formulára */
export class ColumnFlagParser {

  /** Konštruktor */
  constructor(iValue) {
    this.iColumnFlag = iValue;
  }

  /** Hodnota s 1-tkou na zadanej pozícii */
  #getBitValue = (iPos) => 1 << (iPos - 1);

  /** Prečítanie hodnoty na zadanej pozícii */
  #getBoolValue = (iPos) => (this.iColumnFlag & this.#getBitValue(iPos)) > 0;

  /** Nastavenie určenej hodnoty na zadanej pozícii */
  #setBoolValue = (iPos, bValue) => {
    const iBitValue = this.#getBitValue(iPos);
    this.iColumnFlag = (bValue) ? this.iColumnFlag | iBitValue : this.iColumnFlag & ~iBitValue;
  }

  /** Indikátor použitia stĺpca  */
  get lVisible() { return this.#getBoolValue(1); }
  set lVisible(bValue) { this.#setBoolValue(1, bValue); }

  /** Nemôže/Može používateľ nastavovať property LVisible  */
  get lNoCheck() { return this.#getBoolValue(2); }
  set lNoCheck(bValue) { this.#setBoolValue(2, bValue); }

  /** Nemôže/Može používateľ meniť poradie */
  get lNoOrder() { return this.#getBoolValue(3); }
  set lNoOrder(bValue) { this.#setBoolValue(3, bValue); }

  /** Nemôže/Može používateľ meniť šírku stĺpca */
  get lNoLength() { return this.#getBoolValue(4); }
  set lNoLength(bValue) { this.#setBoolValue(4, bValue); }

  /** Nemôže/Može používateľ meniť titulok */
  get lNoName() { return this.#getBoolValue(5); }
  set lNoName(bValue) { this.#setBoolValue(5, bValue); }

  /** Nebudú/Budú metadáta stĺpca v JSON-e getSchema */
  get lNoSchema() { return this.#getBoolValue(6); }
  set lNoSchema(bValue) { this.#setBoolValue(6, bValue); }
}

/** Funkcia na získanie ikony podla typu prílohy */
export const getFileIcon = (suffix, path = "assets/icons/") => {
  switch (suffix) {
    case ".multi":
      return `${path}toolbar_multiformat.svg`;
    case ".chart":
      return `${path}toolbar_chart.svg`;
    case '.pdf':
      return `${path}toolbar_cg_pdf.svg`;
    case '.docx':
    case '.doc':
      return `${path}toolbar-ikony_ms-word.svg`;
    case '.xlsx':
    case '.xls':
      return `${path}toolbar_cg_xlsx.svg`;
    case ".xml":
      return `${path}toolbar_xml_file.svg`;
    case '.jpg':
    case '.jpeg':
    case '.png':
    case '.gif':
    case '.bmp':
      return `${path}toolbar_image_file.svg`;
    case '.txt':
      return `${path}toolbar_multiformat.svg`;
    default:
      return `${path}toolbar_tlac_zostavy.svg`;
  }
}