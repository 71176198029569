import React from "react";
import PropTypes from "prop-types";

import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";
import classNames from "classnames";

const styles = (theme) => ({
  btn: {
    boxShadow: "none!important",
    fontSize: '0.9375rem',
  },
  btnDanger: {
    backgroundColor: "#f44336",
    "&:hover": {
      backgroundColor: "#d43c30",
    },
  },
  btnSuccess: {
    backgroundColor: "#4caf50",
    "&:hover": {
      backgroundColor: "#3f9443",
    },
  },
  btnRounded: {
    borderRadius: '100px',
  },
  btnBlue: {
    backgroundColor: '#0384fc',
    '&:hover': {
      backgroundColor: '#616161',
    },
  },
});

class ButtonComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Button
        color={this.props.color}
        className={classNames(
          this.props.className,
          classes.btn,
          this.props.type === "danger" && classes.btnDanger,
          this.props.type === "success" && classes.btnSuccess,
          this.props.type === "blue" && classes.btnBlue,
          this.props.rounded && classes.btnRounded
        )}
        label={this.props.label}
        title={this.props.title}
        fullWidth={this.props.fullwidth}
        disabled={this.props.disabled}
        style={this.props.style}
        onClick={this.props.onClick}
        keyboardfocused={this.props.keyboardFocused}
        variant={this.props.variant}
        size={this.props.size}
        mini={this.props.mini}
        type={this.props.type}
        disableRipple={this.props.disableRipple}
        startIcon={this.props.startIcon}
        endIcon={this.props.endIcon}
      >
        {this.props.children}
      </Button>
    );
  }
}

ButtonComponent.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
  keyboardFocused: PropTypes.bool,
  variant: PropTypes.string,
  size: PropTypes.string,
  mini: PropTypes.bool,
  type: PropTypes.string,
  disableRipple: PropTypes.bool,
  startIcon: PropTypes.func,
  endIcon: PropTypes.node,
};

export default withStyles(styles)(ButtonComponent);
