import React from 'react';
import HomeIcon from '../../coraWebMComponents/dataDisplay/icons/HomeIcon';
import ParkingIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingIcon';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import PersonIcon from '../../coraWebMComponents/dataDisplay/icons/PersonIcon';
import DogIcon from '../../coraWebMComponents/dataDisplay/icons/DogIcon';
import AddIcon from '../../coraWebMComponents/dataDisplay/icons/AddIcon';
import MessageIcon from '../../coraWebMComponents/dataDisplay/icons/MessageIcon';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import routes from '../../routes';

const menu = [
  {
    MenuID: 1,
    Title: 'Domov',
    Route: routes.HOME,
    Icon: <HomeIcon />,
  },
  {
    MenuID: 2,
    Title: 'Parkovanie',
    Route: routes.PARKING_SEARCH,
    Icon: <ParkingIcon />
  },
  {
    MenuID: 3,
    Title: 'Vozidlo',
    Route: routes.TRESPASS_CAR_SEARCH,
    Icon: <CarIcon />
  },
  {
    MenuID: 4,
    Title: 'Osoba',
    Route: routes.PERSON_SEARCH,
    Icon: <PersonIcon />
  },
  {
    MenuID: 5,
    Title: 'Pes',
    Route: routes.DOG_SEARCH,
    Icon: <DogIcon />
  },
  {
    MenuID: 6,
    Title: 'EVO',
    Route: routes.EVO_SEARCH,
    Icon: <CarIcon />
  },
  {
    MenuID: 7,
    Title: 'Evidencia',
    Route: routes.TRESPASS_ADD,
    Icon: <AddIcon />
  },
  {
    MenuID: 8,
    Title: 'Správy',
    Route: routes.MSG_READ,
    Icon: <MessageIcon />
  },
  {
    MenuID: 9,
    Title: 'Hlásenia',
    Route: routes.MAP_REPORT,
    Icon: <PlaceIcon />
  },
];


export default menu;