import React from 'react';
import PropTypes from 'prop-types';
import Divider from '@material-ui/core/Divider';


/**
 * Divider component
 * @module
 * @param {string} [variant] - type of divider
 * @param {object} [style] - style of divider
 */
class DividerComponent extends React.Component {
  render() {
    return (
      < Divider
        variant={this.props.variant}
        style={this.props.style}
      />
    )
  }
}

DividerComponent.propTypes = {
  variant: PropTypes.string,
  style: PropTypes.object,
}


export default DividerComponent;