import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@mui/material/Tooltip'


class ToolTipComponent extends React.Component {
  render() {
    const { label = '', place = 'top' } = this.props;
    
    return (
      <Tooltip
        title={label}
        placement={place}  // 'top' is the default if place is not defined
      >
        {this.props.children}
      </Tooltip>
    )
  }
}

ToolTipComponent.propTypes = {
  label: PropTypes.string,
  place: PropTypes.string,
}

export default ToolTipComponent;