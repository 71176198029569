import React from 'react';
import AddIcon from '@mui/icons-material/Add';


class AddIconComponent extends React.Component {
  render() {
    return (
      <AddIcon />
    )
  }
}

export default AddIconComponent;