import React from 'react';
import MessageIcon from '@mui/icons-material/MailOutline';
import PropTypes from 'prop-types';

class MessageIconComponent extends React.Component {
  render() {
    return (
      <MessageIcon
        style={this.props.style}
      />
    )
  }
}

MessageIconComponent.propTypes = {
  style: PropTypes.object
}

export default MessageIconComponent;