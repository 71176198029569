import React from 'react';
import PropTypes from 'prop-types';
import L from 'leaflet';
import { MapContainer, TileLayer, Marker, Popup, LayerGroup, Circle, Tooltip, ZoomControl } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Typography from '../dataDisplay/Typography';
import './Map.css';

const getMap = (props, zoom, setZoom) => {
  return function () {
    function onCreated(map) {
      map.on('zoomend', function () {
        setZoom(map.getZoom())

        if (props.setCenter) {
          props.setCenter([map._lastCenter.lat, map._lastCenter.lng]);
        }

      })
    }

    const { position, center, maxZoom, minZoom, scrollWheelZoom, height, markers, maxBounds, noZoomControl, zoomControlPosition } = props;
    return (
      <MapContainer
        whenCreated={onCreated}
        center={center || position}
        zoom={zoom || 14}
        maxZoom={maxZoom || 20}
        minZoom={minZoom || 13}
        scrollWheelZoom={scrollWheelZoom}
        style={{ minHeight: height || 860, maxHeight: height + 1 || 860, ...props.style }}
        maxBounds={maxBounds}
        maxBoundsViscosity={1.0}
        zoomControl={false}
      >
        <React.Fragment>
          {!noZoomControl && <ZoomControl position={zoomControlPosition ?? "topleft"} />}
          <TileLayer
            position="bottomleft"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            maxNativeZoom={19}
            maxZoom={20}
          />
          {props.currentPositionMarker &&
            <LayerGroup>
              <Circle center={position} pathOptions={{ fillColor: 'blue' }} radius={5000000 / Math.pow(zoom, 4)} stroke={false} />
              <Circle
                center={position}
                pathOptions={{ fillColor: 'red' }}
                radius={600000 / Math.pow(zoom, 4)}
                stroke={false}
              />

            </LayerGroup>
          }
          {
            markers.map(m => {
              const icon = new L.Icon({
                iconUrl: m.iconUrl,
                iconSize: m.iconSize
              })

              return (
                <Marker position={m.position} icon={icon} key={m.key}>
                  {m.popup &&
                    <Popup class='leaflet-popup-close-button'>
                      {m.popup}
                    </Popup>
                  }
                  {m.toolTip &&
                    <Tooltip direction="top" offset={[0, -15]} opacity={1} permanent className='mapTooltip'>
                      <Typography variant="body1" style={{ fontWeight: 'bold' }}>{m.toolTip}</Typography>
                    </Tooltip>
                  }
                </Marker>
              );
            })
          }
          {props.children}


        </React.Fragment>
      </MapContainer >
    );
  }
}

class MapWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.zoom = props.zoom;
    this.state = {
    };
  }

  setZoom = (zoom) => {
    this.zoom = zoom
  }

  render() {
    const Map = getMap(this.props, this.zoom, this.setZoom);
    return (
      <Map />
    )
  }
}

MapWrapper.propTypes = {
  position: PropTypes.array.isRequired,
  center: PropTypes.array,
  setCenter: PropTypes.func,
  height: PropTypes.number,
  zoom: PropTypes.number,
  maxZoom: PropTypes.number,
  minZoom: PropTypes.number,
  scrollWheelZoom: PropTypes.bool,
  maxBounds: PropTypes.array,
  currentPositionMarker: PropTypes.bool,
  markers: PropTypes.arrayOf(PropTypes.shape({
    position: PropTypes.array.isRequired,
    iconUrl: PropTypes.string.isRequired,
    popup: PropTypes.element,
    iconSize: PropTypes.array.isRequired,
    key: PropTypes.string.isRequired,
  })),
  NoZoomControl: PropTypes.bool,
  zoomControlPosition: PropTypes.string,
  style: PropTypes.object,
};

export default MapWrapper;