import actionTypes from '../../actions/ActionTypes';

const _initState = {
  isLoading: false,
  data: []
}

const mapMarkers = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_MAP_MARKER_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_MAP_MARKER_SUCCESS:
      return { isLoading: false, data: action.res.Data }

    case actionTypes.GET_MAP_MARKER_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default mapMarkers;