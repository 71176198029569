import React from 'react';
import Clear from '@mui/icons-material/Clear';
import PropTypes from 'prop-types';

class ClearIconComponent extends React.Component {
  render() {
    return (
      <Clear
        style={this.props.style}
        className={this.props.className}
      />
    );
  }
}

ClearIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
};

export default ClearIconComponent;
