import React from 'react';
import { connect } from 'react-redux';
import AppBar from '../appBar/AppBarDrawer';
import Map from '../../coraWebMComponents/map/Map';
import * as locationUtils from '../../utils/LocationUtils';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import * as mapMarkerActions from '../../actions/MapMarkerActions';
import * as convert from '../../coraWebMComponents/utils/convert';
import Switch from '../../coraWebMComponents/inputs/Switch';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Button from '../../coraWebMComponents/inputs/Button';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import routes from '../../routes';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import Clear from '../../coraWebMComponents/dataDisplay/icons/ClearIcon';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import storage from '../../coraWebMComponents/utils/withStorage';
import GpsFixedIcon from '../../coraWebMComponents/dataDisplay/icons/GpsFixedIcon';
import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class MapReport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      actualPosition: window.config.map.defaultPosition,
      firstPosition: window.config.map.defaultPosition,
      center: window.config.map.defaultPosition,
      allocated: true,
      isLoading: true,
      openNotFound: false,
      openFoto: false,
      openFotoDetail: false,
      foto: null,
      iHlasPr: null,
      minZoomLevel: 13,
      maxZoomLevel: 20
    };
  }

  componentDidMount = async () => {
    var alloc = this.props.storage.getItemLocal('MAMP_MAP_ALLOCATED');
    if (alloc === null) {
      alloc = true;
      this.props.storage.setItemLocal('MAMP_MAP_ALLOCATED', true);
    }

    await this.getMarkers(alloc);
    const pos = await this.getActualPosition();

    let minZL = window.config.map.minZoomLevel ? window.config.map.minZoomLevel : 13;
    let maxZL = window.config.map.maxZoomLevel ? window.config.map.maxZoomLevel : 20;

    if (minZL < 13 || minZL > 14)
      minZL = 13;

    if (maxZL < 14 || maxZL > 20)
      maxZL = 20;
    const minZoomLevel = parseInt(minZL);
    const maxZoomLevel = parseInt(maxZL);

    this.setState({ firstPosition: pos, center: pos, actualPosition: pos, isLoading: false, allocated: alloc, minZoomLevel, maxZoomLevel });
  }

  refreshMap = async () => {
    await this.getMarkers(this.state.allocated);
    const pos = await this.getActualPosition();
    this.setState({ center: pos, actualPosition: pos });
  }

  getActualPosition = async () => {
    const actPosition = await locationUtils.getPosition();
    const gpsx = actPosition?.coords?.latitude ?? 0;
    const gpsy = actPosition?.coords?.longitude ?? 0;
    return [gpsx, gpsy];
  }

  getMarkers = async (alloc) => {
    await this.props.dispatch(mapMarkerActions.getMapMarkers(alloc, this.props.meta.checkAppOffline));
  }

  getPopUp = (hod) => {
    return (
      <div>
        {hod.ECV &&
          <div className="row" style={{ marginTop: '27px' }}>
            <Typography variant="body1" style={{ margin: '0 0 0 0' }}>EČV</Typography>
            <Typography variant="body1" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{hod.ECV}</Typography>
          </div>
        }
        {hod.TXT_VOZ &&
          <div className="row" >
            <Typography variant="body2" style={{ margin: '0 0 0 0' }}>Vozidlo</Typography>
            <Typography variant="body2" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{hod.TXT_VOZ}</Typography>
          </div>
        }
        {hod.TXT_VOZ_FARBA &&
          <div className="row" >
            <Typography variant="body1" style={{ margin: '0 0 0 0' }}>Farba</Typography>
            <Typography variant="body1" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{hod.TXT_VOZ_FARBA}</Typography>
          </div>
        }
        {hod.OZN_PARK_M &&
          <div className="row" >
            <Typography variant="body1" style={{ margin: '0 0 0 0' }}>Parkovacie miesto</Typography>
            <Typography variant="body1" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{hod.OZN_PARK_M}</Typography>
          </div>
        }
        {hod.N_ZONA &&
          <div className="row" >
            <Typography variant="body1" style={{ margin: '0 0 0 0' }}>Zóna</Typography>
            <Typography variant="body1" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{hod.N_ZONA}</Typography>
          </div>
        }
        {hod.D_VYTV &&
          <div className="row" >
            <Typography variant="body1" style={{ margin: '0 0 0 0' }}>Dátum zistenia</Typography>
            <Typography variant="body1" style={{ margin: '0 0 0 0', fontWeight: 'bold' }}>{convert.convertDateTime(hod.D_VYTV)}</Typography>
          </div>
        }
        <div >
          <RaisedButton
            fullwidth={true}
            onClick={() => this.handleParking(hod.ECV, hod.I_HLAS_PR)}
            disabled={this.state.isLoading}
            style={{ backgroundColor: '#4caf50' }}
          >
            {'Overiť parkovanie'}</RaisedButton>
        </div>
        <div >
          <RaisedButton
            fullwidth={true}
            onClick={() => this.handleFotoOpen(hod)}
            disabled={this.state.isLoading}
          >
            {'Fotografie'}</RaisedButton>
        </div>
        <div  >
          <RaisedButton
            fullwidth={true}
            onClick={() => this.handleOpenNotFound(hod)}
            disabled={this.state.isLoading}
            style={{ backgroundColor: '#ff0000' }}
          >
            {'Vozidlo nenájdené'}</RaisedButton>
        </div>
      </div>
    )
  }

  handleParking = (ecv, iHlasPr) => {
    this.props.navigate(`${routes.PARKING_SEARCH}?ecv=${ecv}&iHlasPr=${iHlasPr}`);
  }

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
    this.getMarkers(event.target.checked);
    this.props.storage.setItemLocal('MAMP_MAP_ALLOCATED', event.target.checked);
  };

  handleFotoOpen = async (hod) => {
    await this.props.dispatch(mapMarkerActions.getMapFotos(hod.I_HLAS_PR, this.props.meta.checkAppOffline));
    this.setState({ openFoto: true, center: [hod.GPS_X, hod.GPS_Y] });
  }

  handleFotoClose = () => {
    this.setState({ openFoto: false });
  };

  openFotoDetail = (foto) => {
    this.setState({ openFotoDetail: true, foto });
  };

  closeFotoDetail = () => {
    this.setState({ openFotoDetail: false });
  };

  handleOpenNotFound = (hod) => {
    this.setState({ openNotFound: true, iHlasPr: hod.I_HLAS_PR, center: [hod.GPS_X, hod.GPS_Y] });
  }

  handleCloseNotFound = () => {
    this.setState({ openNotFound: false, iHlasPr: null });
  };

  setNotFound = async () => {
    this.setState({ isLoading: true });
    await this.props.dispatch(mapMarkerActions.setMapMarker(this.state.iHlasPr, 5, this.props.meta.checkAppOffline));
    this.setState({ isLoading: false, openNotFound: false, iHlasPr: null });
    await this.getMarkers(this.state.allocated);
  }

  render() {
    const markers = this.props.mapMarkers.map(m => (
      {
        position: [m.GPS_X, m.GPS_Y],
        iconUrl: 'marker.svg',
        iconSize: [40, 40],
        popup: this.getPopUp(m),
        key: m.I_HLAS_PR.toString(),
        toolTip: m.ECV,
      }
    ))

    const fotoList = this.props.mapFotos.map(m => (
      <div key={m.I_ZAZ_ZAZ}>
        {m.HODN_DAT ? (
          <img
            style={{ border: '0px solid #ddd', borderRadius: '10px', padding: '7px', width: '350px' }}
            src={`data:image/png;base64, ${m.HODN_DAT}`}
            onClick={() => this.openFotoDetail(m.HODN_DAT)} //Otvorí sa fotka vo fullscreen
          />
        ) : (
          <Typography variant="caption" style={{ marginBottom: '10px', textAlign: "center" }} >Fotografia neexistuje</Typography>
        )}
      </div>
    ))

    const fotoFrm =
      <div>
        <div className="row">
          <Typography variant="subtitle1" style={{ marginLeft: '10px', alignSelf: 'center' }}>Fotografie</Typography>
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => this.handleFotoClose()}
          >
            <Clear />
          </Button>
        </div>
        {this.props.mapFotos.length > 0 && fotoList}
        {this.props.mapFotos.length === 0 &&
          <Typography variant="caption" style={{ margin: '30px 30px 30px 30px', textAlign: "center" }} >Fotografie neexistujú!</Typography>
        }
      </div>

    const fotoDetail =
      <div >
        <img
          width="300%"
          height="auto"
          max-width="600%"
          min-width="10%"
          src={`data:image/png;base64, ${this.state.foto}`}
          onClick={() => this.closeFotoDetail()}
        />
      </div>

    return (
      < div >
        <AppBar title='Hlásenia' />
        <div >
          <React.Fragment>
            <CircularProgress loading={this.state.isLoading || this.props.isLoadingMarker || this.props.isLoadingFoto} style={{ backgroundColor: 'transparent' }} />
            <div style={{ marginLeft: '10px' }}>
              <Switch
                color='primary'
                checked={this.state.allocated}
                onChange={this.handleChangeSwitch('allocated')}
                value='allocated'
                label={this.state.allocated ? 'Mne pridelené' : 'Mne pridelené a všetky nepridelené'}
              />
            </div>
            <div style={{ position: 'relative' }}>
              <Map
                setCenter={(center) => this.setState({ center })}
                center={this.state.center}
                position={this.state.actualPosition}
                //zoom={14}
                maxZoom={this.state.maxZoomLevel}
                minZoom={this.state.minZoomLevel}
                scrollWheelZoom={false}
                height={screen.height * 0.72}
                markers={markers}
                maxBounds={[[this.state.firstPosition[0, 0] - window.config.map.maxBoundsOffsetX,
                this.state.firstPosition[0, 1] - window.config.map.maxBoundsOffsetY],
                [this.state.firstPosition[0, 0] + window.config.map.maxBoundsOffsetX,
                this.state.firstPosition[0, 1] + window.config.map.maxBoundsOffsetY]]}
              />
              <ButtonFab
                label=""
                onClick={() => this.refreshMap()}
                size='medium'
                style={{ backgroundColor: '#000000', zIndex: '400', position: 'absolute', bottom: '5%', right: '2%' }}
              >
                <GpsFixedIcon style={{ color: '#ffffff' }} />
              </ButtonFab>
            </div>
          </React.Fragment>
        </div>
        <Dialog
          open={this.state.openNotFound}
          onClose={this.handleCloseNotFound}
          contentText='Chcete označiť hlásenie ako neopodstatnené?'
          title='Vozidlo nenájdené'
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: this.handleCloseNotFound,
              keyboardFocused: false
            },
            {
              label: 'Áno',
              color: 'primary',
              onClick: this.setNotFound,
              keyboardFocused: true
            }
          ]}
        />
        <FormModal
          disableMargins={true}
          open={this.state.openFoto}
          onClose={this.handleFotoClose}
          elements={fotoFrm}
        />
        <FormModal
          fullScreen={true}
          open={this.state.openFotoDetail}
          onClose={this.closeFotoDetail}
          elements={fotoDetail}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    mapMarkers: state.mapMarkers.data,
    isLoadingMarker: state.mapMarkers.isLoading,
    mapFotos: state.mapFotos.data,
    isLoadingFoto: state.mapFotos.isLoading,
  }
}

export default withRouter(withMeta(storage(connect(mapStateToProps)(MapReport))));