import actionTypes from '../../actions/ActionTypes';

const _initState = {
  isLoading: false,
  data: { sprac: [], nesprac: [] }
}

const trespassesByCar = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRESPASSES_BY_CAR_REQUEST:
      return { data: { sprac: [], nesprac: [] }, isLoading: true };

    case actionTypes.GET_TRESPASSES_BY_CAR_SUCCESS:
      return { isLoading: false, data: action.res.Data }

    case actionTypes.GET_TRESPASSES_BY_CAR_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default trespassesByCar;