import React from 'react';
import PropTypes from 'prop-types';
import FileInput from './FileInput';

class AttachmentInput extends React.Component {
  resize = (src, height) => {
    return new Promise((resolve, reject) => {
      const image = new Image();

      image.onload = () => {
        var canvas = document.getElementById('canvas');
        if (image.height > height) {
          image.width *= height / image.height;
          image.height = height;
        }
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        canvas.width = image.width;
        canvas.height = image.height;
        ctx.drawImage(image, 0, 0, image.width, image.height);

        const data = canvas.toDataURL('image/jpeg');
        resolve(data);
      }

      image.src = src;
    });
  }

  onChange = (file) => {
    this.resize(file.data, 1024).then((data) => {
      file.data = data;
      file.size = data.length * 3 / 4
      this.props.onChange(file);
    });
  }

  render() {
    return (
      <div>
        <FileInput
          disabled={this.props.disabled}
          onChange={this.onChange}
          mimetype="image/jpeg"
          multiple={this.props.multiple}
          title="Foto"
          iconType={this.props.iconType}
        />
        <canvas style={{ display: 'none' }} id='canvas' />
      </div>
    );
  }
}

AttachmentInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  iconType: PropTypes.number,
  multiple: PropTypes.bool,
}

export default AttachmentInput;