const getSharedStyles = (theme) => (
  {
    separator: {
      border: 0,
      borderBottom: '1px solid #e1e1e1',
      margin: 0,
    },
    textCenter: {
      textAlign: 'center',
    },
    textRight: {
      textAlign: 'right',
    },
    fontBold: {
      fontWeight: 'bold'
    },
    textItalic: {
      fontStyle: 'italic',
    },
    textTransformNone: {
      textTransform: 'none',
    },
    dialogWarningText: {
      color: 'red!important',
      fontWeight: '500'
    },
    bgColorTransparent: {
      backgroundColor: 'transparent!important'
    },
    //margin helpers
    mt1: {
      marginTop: '5px!important',
    },
    mt2: {
      marginTop: '10px!important',
    },
    mt3: {
      marginTop: '15px!important',
    },
    mt4: {
      marginTop: '25px!important',
    },
    mt5: {
      marginTop: '30px!important',
    },
    mt6: {
      marginTop: '35px!important',
    },
    mb1: {
      marginBottom: '5px!important',
    },
    mb2: {
      marginBottom: '10px!important',
    },
    mb3: {
      marginBottom: '15px!important',
    },
    mb4: {
      marginBottom: '25px!important',
    },
    mb5: {
      marginBottom: '30px!important',
    },
    mb6: {
      marginBottom: '35px!important',
    },
    ml0: {
      marginLeft: '0px!important',
    },
    ml1: {
      marginLeft: '5px!important',
    },
    ml2: {
      marginLeft: '10px!important',
    },
    ml3: {
      marginLeft: '15px!important',
    },
    ml4: {
      marginLeft: '25px!important',
    },
    ml5: {
      marginLeft: '30px!important',
    },
    ml6: {
      marginLeft: '35px!important',
    },
    mr0: {
      marginRight: '0px!important',
    },
    mr1: {
      marginRight: '5px!important',
    },
    mr2: {
      marginRight: '10px!important',
    },
    mr3: {
      marginRight: '15px!important',
    },
    mr4: {
      marginRight: '25px!important',
    },
    mr5: {
      marginRight: '30px!important',
    },
    mr6: {
      marginRight: '35px!important',
    },
    [theme.breakpoints.up('lg')]: {
      mlLg0: {
        marginLeft: '0px!important',
      }
    },
    mrAuto: {
      marginRight: 'auto'
    },
    mlAuto: {
      marginLeft: 'auto'
    },
    //padding helpers
    p0: {
      padding: '0px!important'
    },
    pt1: {
      paddingTop: '5px!important',
    },
    pt2: {
      paddingTop: '10px!important',
    },
    pt3: {
      paddingTop: '15px!important',
    },
    pt4: {
      paddingTop: '25px!important',
    },
    pb1: {
      paddingBottom: '5px!important',
    },
    pb2: {
      paddingBottom: '10px!important',
    },
    pb3: {
      paddingBottom: '15px!important',
    },
    pb4: {
      paddingBottom: '25px!important',
    },
    pl1: {
      paddingLeft: '5px',
    },
    pl2: {
      paddingLeft: '10px',
    },
    pl3: {
      paddingLeft: '15px',
    },
    pr1: {
      paddingRight: '5px',
    },
    pr2: {
      paddingRight: '10px',
    },
    pr3: {
      paddingRight: '15px',
    },
    //width 
    w50: {
      width: '50%',
    },
    w100: {
      width: '100%',
    },
    alignItemsleft: {
      alignItems: 'left',
    },
    alignItemsCenter: {
      alignItems: 'center',
    },
    alignItemsRight: {
      alignItems: 'right',
    },
    alignSelfCenter: {
      alignSelf: 'center',
    },
    row: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between'
    },
    dFlex: {
      display: 'flex',
    },
    flexColumn: {
      display: 'flex',
      flexDirection: 'column',
    },
    flexGrow1: {
      flexGrow: 1,
    },
    justifyContentBetween: {
      justifyContent: 'space-between'
    },
    justifyContentCenter: {
      justifyContent: 'center'
    },
    justifyContentEnd: {
      justifyContent: 'flex-end'
    },
    justifyContentStart: {
      justifyContent: 'flex-start'
    },
    paperCard: {
      boxSizing: 'border-box',
      padding: '15px',
      minHeight: 'calc(100vh - 56px)',
      [theme.breakpoints.up('sm')]: {
        padding: '30px',
        minHeight: 'calc(100vh - 64 px)',
      },
      [theme.breakpoints.up('md')]: {
        padding: '45px',
        minHeight: 'auto'
      },
      [theme.breakpoints.up('lg')]: {
        padding: '60px',
      },
    },
    paperCardNoAppbar: {
      boxSizing: 'border-box',
      padding: '15px',
      minHeight: '100vh',
      [theme.breakpoints.up('sm')]: {
        padding: '30px',
      },
      [theme.breakpoints.up('md')]: {
        padding: '45px',
        minHeight: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '60px',
      },
    },
    cardContent: {
      padding: '20px',
    },
    paperWrapper: {
      width: 'auto',
      display: 'inline-block',
    },
    //buttons
    raisedButton: {
      width: '80%',
      maxWidth: '350px',
      padding: '13px 30px'
    },
    formButton: {
      margin: 0,
      borderRadius: '0px',
    },
    navigationButton: {
      width: "4em",
      height: "2em",
      margin: 0,
      padding: '0.3em',
      borderRadius: '6px',
    },
    navigationBackButton: {
      // backgroundColor: "#FAFAFA",
      color: "#555",
      // border: "1px solid #eAeAeA",
    },
    navigationForwardButton: {
      color: "#555"
    },
    adminButton: {
      backgroundColor: theme.palette.adminBlue.main,
      "&:hover": {
        backgroundColor: theme.palette.adminBlue.dark,
      }
    }
  }
);

export default getSharedStyles;