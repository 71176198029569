import actionTypes from './ActionTypes';
import * as streetUtils from '../utils/StreetUtils';
import * as userActions from './UserActions';
import CoraError from '../coraWebMComponents/utils/coraError';

const getStreetsSuccess = (res) => ({
  type: actionTypes.GET_STREETS_SUCCESS,
  res
});

const getStreetsRequest = () => ({
  type: actionTypes.GET_STREETS_REQUEST
});

const getStreetsFailure = (error) => ({
  type: actionTypes.GET_STREETS_FAILURE,
  error
});

export const getStreets = (text, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getStreetsRequest());
      let res = await streetUtils.getStreets(text);
      dispatch(getStreetsSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getStreetsFailure(error))
        console.error(error);
      }
    }
  }
}