import * as requests from '../coraWebMComponents/utils/requests';


export const getPersonList = async (searchText, countCharStart) => {
  if (searchText.length === 0) {
    return [];
  }

  try {
    return await requests.get(`/api/Person?filter=${searchText}&countCharStart=${countCharStart}`);
  }
  catch (error) {
    throw error;
  }
}


export const getPerson = async (id) => {
  if (!id) {
    return [];
  }

  try {
    return await requests.get(`/api/Person/${id}`);
  }
  catch (error) {
    throw error;
  }
}