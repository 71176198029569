export const formatMapping = {
  'sk-SK': {
    codes: { // Kendo kodovanie v tvare {0:kod} - mapovanie formatovacich kodov na konkretne formaty - Kvoli kompatibilite s Retecom
      'd': 'dd.MM.yyyy',
      'D': 'dddd, dd. MMMM yyyy',
      'f': 'd. MMMM yyyy HH:mm',
      'F': 'd. MMMM yyyy HH:mm:ss',
      'g': 'dd.MM.yyyy HH:mm',
      'G': 'dd.MM.yyyy HH:mm:ss',
      'm': 'd. MMMM',
      'M': 'd. MMMM',
      's': 'yyyy-MM-ddTHH:mm:ss',
      't': 'HH:mm',
      'T': 'HH:mm:ss',
      'u': 'yyyy-MM-dd HH:mm:ssZ',
      'y': 'MMMM yyyy',
      'Y': 'MMMM yyyy'
    },
    // Definovanie nazvov dni a mesiacov
    days: ["nedeľa", "pondelok", "utorok", "streda", "štvrtok", "piatok", "sobota"],
    daysShort: ["ne", "po", "ut", "st", "št", "pi", "so"],
    months: ["január", "február", "marec", "apríl", "máj", "jún", "júl", "august", "september", "október", "november", "december"],
    monthsShort: ["jan", "feb", "mar", "apr", "máj", "jún", "júl", "aug", "sep", "okt", "nov", "dec"],
  },

  'de-DE': {
    codes: {
      'd': 'dd.MM.yyyy',
      'D': 'dddd, d. MMMM yyyy',
      'f': 'd. MMMM yyyy HH:mm',
      'F': 'd. MMMM yyyy HH:mm:ss',
      'g': 'dd.MM.yyyy HH:mm',
      'G': 'dd.MM.yyyy HH:mm:ss',
      'm': 'd. MMMM',
      'M': 'd. MMMM',
      's': 'yyyy-MM-ddTHH:mm:ss',
      't': 'HH:mm',
      'T': 'HH:mm:ss',
      'u': 'yyyy-MM-dd HH:mm:ssZ',
      'y': 'MMMM yyyy',
      'Y': 'MMMM yyyy'
    },
    days: ["Sonntag", "Montag", "Dienstag", "Mittwoch", "Donnerstag", "Freitag", "Samstag"],
    daysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"],
    months: ["Januar", "Februar", "März", "April", "Mai", "Juni", "Juli", "August", "September", "Oktober", "November", "Dezember"],
    monthsShort: ["Jan", "Feb", "Mär", "Apr", "Mai", "Jun", "Jul", "Aug", "Sep", "Okt", "Nov", "Dez"]
  },
  
  'en-US': {
    codes: {
      'd': 'MM/dd/yyyy',
      'D': 'dddd, MMMM dd, yyyy',
      'f': 'MMMM dd, yyyy HH:mm',
      'F': 'MMMM dd, yyyy HH:mm:ss',
      'g': 'MM/dd/yyyy HH:mm',
      'G': 'MM/dd/yyyy HH:mm:ss',
      'm': 'MMMM dd',
      'M': 'MMMM dd',
      's': 'yyyy-MM-ddTHH:mm:ss',
      't': 'HH:mm',
      'T': 'HH:mm:ss',
      'u': 'yyyy-MM-dd HH:mm:ssZ',
      'y': 'MMMM yyyy',
      'Y': 'MMMM yyyy'
    },
    days: ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"],
    daysShort: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
    months: ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
    monthsShort: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"]
  },
};