import React from 'react';
import ArrowBack from '@mui/icons-material/ArrowBack';
import PropTypes from 'prop-types';

class ArrowBackComponent extends React.Component {
  render() {
    return (
      <ArrowBack
        style={this.props.style}
        color={this.props.color}
        fontSize={this.props.fontSize}
        variant={this.props.variant}
      />
    );
  }
}

ArrowBackComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
};

export default ArrowBackComponent;