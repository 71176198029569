import * as convert from "./convert";
import * as storage from "./storage";
import CoraError from "./coraError";
import { getClientVersion } from "./helper";
import { getAbortController } from "./abortController";

/** @module utils/requests */

/** @typedef {string} UNAUTHORIZED_MSG Unauthorized message*/
export const UNAUTHORIZED_MSG = "Vaše prihlásenie vypršalo.";

/** @typedef {string} MAINTENANCE_MSG Maintenance message*/
export const MAINTENANCE_MSG = "Práve prebieha údržba systému.";

const checkVersion = (versionServer) => {
  const versionClient = getClientVersion();

  let clientVersionWithoutBuildNumber = versionClient.split(".");
  let serverVersionWithoutBuildNumber = versionServer.split(".");
  clientVersionWithoutBuildNumber.pop();
  serverVersionWithoutBuildNumber.pop();

  return (
    process.env.NODE_ENV === "development" ||
    !versionServer ||
    JSON.stringify(clientVersionWithoutBuildNumber) === JSON.stringify(serverVersionWithoutBuildNumber)
  );
};

const sleep = (delay) => new Promise((resolve) => setTimeout(resolve, delay))

/**
 * Asynchronously returns filename from response header content disposition.
 * @function parseFilenameFromContentDisposition
 * @param {string} contentDisposition
 * @returns {(string | null)}
 */
export const parseFilenameFromContentDisposition = (contentDisposition) => {
  if (!contentDisposition) return null;
  let matches = /filename=(.*)/.exec(contentDisposition);

  return matches && matches.length > 1 ? matches[1].replace(/"/g, "").trim() : null;
};

/**
 * Asynchronously response from signing in.
 * @function signIn
 * @param {string} username username to sign in
 * @param {string} password password to sign in
 * @returns {Object}
 */
export const signIn = async (username, password) => {
  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  const url = "/api/Login";
  const data = {
    username,
    password,
  };

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    const res = await response.json();
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        throw new CoraError({
          UserMessage: "Je potrebné aktualizovať aplikáciu.",
          Code: "403.000",
        });
      }
    }

    if (res.Data.Expired) {
      localStorage.setItem("username", username)
      const pathname = window.location.hash || "#/prihlasenie"
      window.location.replace(`${pathname}?code=${res.Data.Token}&expired=true`)
      window.location.reload()
      return { Data: null };
    }

    storage.setUser(res.Data);
    return res;
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: "Nesprávne meno alebo heslo.",
      Code: "401.000",
    });
  } else if (response.status === 403) {
    const res = await response.json();
    if (res?.Code === "403.001") {
      return res;
    } else throw new CoraError(res);
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously response from signing in.
 * @function signIn
 * @param {string} username username to sign in
 * @param {string} password password to sign in
 * @returns {Object}
 */
export const signInMFa = async (username, password, code) => {
  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  const url = "/api/Login/MFa";
  const data = {
    username,
    password,
    code
  };

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    const res = await response.json();
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        throw new CoraError({
          UserMessage: "Je potrebné aktualizovať aplikáciu.",
          Code: "403.000",
        });
      }
    }

    if (res.Data.Expired) {
      localStorage.setItem("username", username)
      const pathname = window.location.hash || "#/prihlasenie"
      window.location.replace(`${pathname}?code=${res.Data.Token}&expired=true`)
      window.location.reload()
      return { Data: null };
    }

    storage.setUser(res.Data);
    return res;
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: "Nesprávne meno alebo heslo.",
      Code: "401.000",
    });
  } else if (response.status === 403) {
    const res = await response.json();
    if (res?.Code === "403.001") {
      return res;
    } else throw new CoraError(res);
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously response from register.
 * @function register
 * @param {Object} registerData data to register
 * @param {String} urlExtension extend default URL
 * @returns {Object}
 */
export const register = async (registerData, urlExtension = "") => {
  const url = `/api/register/${urlExtension}`;
  const data = registerData;

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status === 200) {
    const res = await response.json();
    return res;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously response from verify.
 * @function verify
 * @param {object} data data to verify
 * @param {String} urlExtension extend default URL
 * @returns {Object}
 */
export const verify = async (data, urlExtension = "") => {
  const url = `/api/verify/${urlExtension}`;

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status === 200) {
    const res = await response.json();
    return res;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously response from sign in with redirect token.
 * @function signInRedirect
 * @param {string} token generated redirect token
 * @returns {Object}
 */
export const signInRedirect = async (token) => {
  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  const url = "/api/Login/redirect";
  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (response.status === 200) {
    const res = await response.json();
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        storage.removeUser();
        return false;
      }
    }

    storage.setUser(res.Data);
    return true;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    storage.removeUser();
    return false;
  }
};

/**
 * Asynchronously response from oAuth sign in.
 * @function signInOAuth
 * @param {string} code generated code from oAuth response
 * @param {string} redirect_uri redirect uri used in oAuth request
 * @param {string} provider provider ID
 * @returns {Object}
 */
export const signInOAuth = async (code, redirect_uri, provider) => {
  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  const url = `/api/oauth/login`;
  const data = {
    provider,
    code: decodeURIComponent(code),
    redirect_uri,
  };

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: data ? JSON.stringify(data) : null,
  });

  if (response.status === 200) {
    const res = await response.json();
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        throw new CoraError({
          UserMessage: "Je potrebné aktualizovať aplikáciu.",
          Code: "403.000",
        });
      }
    }

    storage.setUser(res.Data);
    return res;
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: "Nesprávne meno alebo heslo.",
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously response from oAuth.
 * @function oAuthRegister
 * @param {string} code generated code from oAuth response
 * @param {string} redirect_uri redirect uri used in oAuth request
 * @param {string} provider provider ID
 * @param {string} urlExtension extend default URL
 * @returns {Object}
 */
export const oAuth = async (
  code,
  redirect_uri,
  provider,
  urlExtension = ""
) => {
  const url = `/api/oauth/${urlExtension}`;
  const data = {
    provider,
    code: decodeURIComponent(code),
    redirect_uri,
  };

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    const res = await response.json();
    return res;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously returns response from verifying code.
 * @function verifyCode
 * @param {string} code code to be verified
 * @returns {Object}
 */
export const verifyCode = async (code) => {
  const url = "/api/VerifyCode";
  const data = {
    code,
  };

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    const res = await response.json();
    storage.setUser(res.Data);
    return res;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    throw new CoraError(await response.json());
  }
};

/**
 * Asynchronously returns response from creating code.
 * @function createCode
 * @param {string} username username
 * @returns {Object}
 */
export const createCode = async (username) => {
  const url = `/api/CreateCode?login=${username}`;

  try {
    let res = await getNoRefresh(url);
    return res;
  }
  catch (error) {
    throw error;
  }
};


/**
 * 
 * @param {*} username 
 * @returns 
 */
export const generateMfaCode = async (username, password) => {
  const data = {
    username,
    password,
  };

  const url = `/api/Mfa/not-available`;

  try {
    let res = await post(url, data);
    return res;
  }
  catch (error) {
    throw error;
  }
};

/**
 * Asynchronously returns response from get redirect token.
 * @function getRedirectToken
 * @returns {Object}
 */
export const getRedirectToken = async () => {
  const url = `/api/redirect`;

  try {
    let res = await get(url);
    return res?.Data;
  }
  catch (error) {
    throw error;
  }
};

/**
 * Asynchronously get pattern and validation text for change password.
 * @function getPattern
 * @param {string} code code
 * @returns {Object}
 */
export const getPattern = async (code) => {
  const url = `/api/Pattern`;

  let response = await fetch(`${window.config.url}${url}`, {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: code ? `Bearer ${code}` : null,
    }
  });

  if (response.status === 200 || response.status === 201) {
    const res = await response.json()
    return res?.Data;
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns response from update password with code.
 * @function updatePasswordWithCode
 * @param {string} code code
 * @param {string} password password
 * @returns {Object}
 */
export const updatePasswordWithCode = async (code, password) => {
  const url = '/api/changePasswordWithCode';

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: code ? `Bearer ${code}` : null,
    },
    body: JSON.stringify({ password }),
  });

  if (response.status === 200 || response.status === 201) {
    return await response.json();
  } else if (response.status === 401) {
    throw new CoraError({
      UserMessage: UNAUTHORIZED_MSG,
      Code: "401.000",
    });
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
}

/**
 * Asynchronously returns response from refresh token.
 * @function refreshToken
 * @returns {bool}
 */
export const refreshToken = async () => {
  const isCheckVersionRequired = window.config.isVersionCheckRequired !== undefined ? window.config.isVersionCheckRequired : true;
  const url = "/api/Login/refresh";
  const user = storage.getUser();
  if (!user) {
    return false;
  }

  let lastReq;
  let infinityLoopProtect = 0;

  do {
    lastReq = storage.getRefreshStatus();
    infinityLoopProtect = infinityLoopProtect + 1;
    if (infinityLoopProtect > 1000) {
      console.info("Infinity loop protection breaks loop!");
      break;
    }
    if (lastReq.Status === true) {
      console.info("Pending refresh token request! Wait 500ms");
      await sleep(500);
    }
  } while (lastReq.Status === true);

  if (((new Date().getTime() - lastReq.Time) / 1000) < 60) {
    return true;
  }
  storage.setPendingStatus();

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: user ? `Bearer ${user.RefreshToken}` : null,
    },
  });

  if (response.status === 200) {
    const res = await response.json();
    if (isCheckVersionRequired) {
      if (!checkVersion(res.Data.Version)) {
        storage.removeUser();
        return false;
      }
    }

    storage.setUser(res.Data);
    return true;
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    storage.removeUser();
    return false;
  }
};

/**
 * Asynchronously returns response from post data.
 * @function post
 * @param {string} url url
 * @param {object} data data
 * @returns {Object}
 */
export const post = async (url, data) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
    body: data ? JSON.stringify(data) : null,
  });
  if (response.status === 200 || response.status === 201) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await post(url, data);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns response from post data with file
 * @function put
 * @param {string} url url
 * @param {object} data data
 * @param {object} file file
 * @returns {Object}
 */
export const postWithFile = async (url, data, file) => {
  const formData = new FormData();

  formData.append("file", file);
  formData.append("additionalData", JSON.stringify(data));

  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
    body: formData,
  });
  if (response.status === 200 || response.status === 201) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await postWithFile(url, data, file);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns response from put data.
 * @function put
 * @param {string} url url
 * @param {object} data data
 * @returns {Object}
 */
export const put = async (url, data) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "PUT",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await put(url, data);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 403) {
    const res = await response.json();
    if (res?.Code === "403.001") {
      return res;
    } else throw new CoraError(res);
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Returns authorization header token or null.
 * @function getAuthorizationHeader
 * @param {boolean} tryUseGuestToken Použi guestToken, ak je k dispozícii
 * @returns {string}
 */
export const getAuthorizationHeader = (tryUseGuestToken = false) => {
  const user = storage.getUser();
  let token = user?.Token;
  const cfg = storage.getLoginConfig();
  if (!token && tryUseGuestToken) {
    token = cfg?.guestToken;
  }
  return token ? `Bearer ${token}` : null;
};

/**
 * Returns filter with offset(time zone) to given date.
 * @function processFilter
 * @param {object} filter filter
 * @returns {Object}
 */
export const processFilter = (filter) => {
  return {
    ...filter, value: (filter.value instanceof Date ? convert.addOffset(filter.value) :
      (filter.operator === 'between' && filter.value ?
        (filter.value.start ? convert.addOffset(filter.value.start).toISOString() : '') + ',' +
        (filter.value.end ? convert.addOffset(filter.value.end).toISOString() : '') :
        filter.value))
  }
};

/**
 * Asynchronously returns response from get list of data.
 * @function getList
 * @param {string} url url
 * @param {object} filter filter
 * @param {object} sort sort
 * @param {object} page page
 * @returns {Object}
 */
export const getList = async (url, filter = { filters: [] }, sort = [], page = { skip: 0, take: 5 }, args = null) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  let _url = `${window.config.url}${url}?filter=${encodeURIComponent(JSON.stringify(
    processedFilter
  ))}&sort=${JSON.stringify({ sorts: sort })}&page=${page.skip / page.take + 1
    }&limit=${page.take}`;
  if (args) {
    _url = _url + `&${new URLSearchParams(args)}`;
  }
  let response = await fetch(_url
    ,
    {
      headers: {
        Authorization: getAuthorizationHeader(true),
      },
      signal: getAbortController().signal
    }
  );

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404) {
    return { Data: { Items: [], TotalRecords: page.skip } };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await getList(url, processedFilter, sort, page, args);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

export const getTotal = async (url, filter = { filters: [] }, args = null) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  let _url = `${window.config.url}${url}?filter=${encodeURIComponent(JSON.stringify(
    processedFilter
  ))}`;
  if (args) {
    _url = _url + `&${new URLSearchParams(args)}`;
  }
  let response = await fetch(_url
    ,
    {
      headers: {
        Authorization: getAuthorizationHeader(),
      },
    }
  );

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 404) {
    return { Data: { TotalRecords: null } };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await getTotal(url, filter, args);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns response get data.
 * @function get
 * @param {string} url url
 * @returns {Object}
 */
export const get = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    headers: {
      Authorization: getAuthorizationHeader(true),
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 204) {
    return { Data: {} };
  } else if (response.status === 404) {
    return { Data: null };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await get(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

export const getNoRefresh = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously deletes data.
 * @function del
 * @param {string} url url
 * @returns {Object}
 */
export const del = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "DELETE",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

  if (response.status === 204 || response.status === 200) {
    return;
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await del(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously deletes data and return response.
 * @function delWithResData
 * @param {string} url url
 * @returns {Object}
 */
export const delWithResData = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await delWithResData(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously cancels data.
 * @function cancel
 * @param {string} url url
 * @returns {Object}
 */
export const cancel = async (url) => {
  let response = await fetch(`${window.config.url}${url}/zrus`, {
    method: "DELETE",
    headers: {
      Authorization: getAuthorizationHeader(),
    },
  });

  if (response.status === 200) {
    return;
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await cancel(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously cancels data and return response.
 * @function cancel
 * @param {string} url url
 * @returns {Object}
 */
export const cancelWithResData = async (url) => {
  let response = await fetch(`${window.config.url}${url}/zrus`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      Authorization: getAuthorizationHeader(),
    },
  });

  if (response.status === 200) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await cancel(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously deletes data with data.
 * @function delWithData
 * @param {string} url url
 * @param {object} data data
 * @returns {Object}
 */
export const delWithData = async (url, data) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
    body: JSON.stringify(data),
  });

  if (response.status === 200 || response.status === 201) {
    return await response.json();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await delWithData(url, data);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously posts print.
 * @function postPrint
 * @param {string} url url
 * @param {object} filter filter
 * @param {object} sort sort
 * @param {object} fileType fileType
 * @param {object} schema schema
 * @returns {Object}
 */
export const postPrint = async (url, filter, sort, fileType, schema, db = null) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  let _url = `${window.config.url}${url}?filter=${encodeURIComponent(JSON.stringify(
    processedFilter
  ))}&sort=${JSON.stringify({ sorts: sort })}&fileType=${fileType}`
  if (db) {
    _url = _url + `&db=${db}`
  }
  let response = await fetch(
    _url,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(true),
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      body: JSON.stringify(schema),
    }
  );
  let fileName = parseFilenameFromContentDisposition(
    response.headers.get("content-disposition")
  );

  if (response.status === 200) {
    return { blob: await response.blob(), fileName: fileName };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await postPrint(url, filter, sort, fileType, schema);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = { blob: await response.blob(), fileName: fileName };
    throw new CoraError(res);
  }
};

export const postPrintNoMeta = async (url, filter, sort, fileType, schema, formName, formType, iZiadost) => {
  const processedFilter = { filters: filter.filters.map((x) => processFilter(x)) };
  let response = await fetch(
    `${window.config.url}${url}?filter=${encodeURIComponent(JSON.stringify(
      processedFilter
    ))}&sort=${JSON.stringify({ sorts: sort })}&fileType=${fileType}&formName=${formName}&formType=${formType}&iZiadost=${iZiadost}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: getAuthorizationHeader(),
        "Access-Control-Expose-Headers": "Content-Disposition",
      },
      body: JSON.stringify(schema),
    }
  );
  let fileName = parseFilenameFromContentDisposition(
    response.headers.get("content-disposition")
  );


  if (response.status === 200) {
    return { blob: await response.blob(), fileName: fileName };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await postPrintNoMeta(url, filter, sort, fileType, schema, formName, formType, iZiadost);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = { blob: await response.blob(), fileName: fileName };
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns blob.
 * @function getBlob
 * @param {string} url url
 * @returns {Object}
 */
export const getBlob = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(true),
    },
  });
  if (response.status === 200) {
    return await response.blob();
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await getBlob(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns blob with file name.
 * @function getBlobWithFileName
 * @param {string} url url
 * @returns {Object}
 */
export const getBlobWithFileName = async (url) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "GET",
    headers: {
      Authorization: getAuthorizationHeader(true),
    },
  });
  if (response.status === 200) {
    let contentDisposition = response.headers.get("content-disposition");
    let filename = `doc${new Date().toISOString().replace(/[^0-9]/g, "")}${convert.getDefaultExtension(response.headers.get("content-type"))}`;
    if (contentDisposition !== null) {
      filename = contentDisposition
        .split(";")
        .find((n) => n.includes("filename="))
        .replace(/"/g, "")
        .replace('filename=', "")
        .trim();
    }
    if (filename.startsWith("=?utf-8?B?")) {
      filename = convert.base64ToUnicodeString(filename.replace("=?utf-8?B?", "").replace("?=", ""));
    }
    return { blob: await response.blob(), fileName: filename };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await getBlob(url);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns blob with file name.
 * @function postTlac
 * @param {string} url url
 * @returns {Object}
 */
export const postTlac = async (url, tlac) => {
  let response = await fetch(`${window.config.url}${url}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: getAuthorizationHeader(),
    },
    body: JSON.stringify(tlac)
  });
  if (response.status === 200) {
    let contentDisposition = response.headers.get("content-disposition");
    let filename = `doc${new Date().toISOString().replace(/[^0-9]/g, "")}${convert.getDefaultExtension(response.headers.get("content-type"))}`;
    if (contentDisposition !== null) {
      filename = contentDisposition
        .split(";")
        .find((n) => n.includes("filename="))
        .replace(/"/g, "")
        .replace('filename=', "")
        .trim();
    }
    if (filename.startsWith("=?utf-8?B?")) {
      filename = convert.base64ToUnicodeString(filename.replace("=?utf-8?B?", "").replace("?=", ""));
    }
    return { blob: await response.blob(), fileName: filename };
  } else if (response.status === 401) {
    if (await refreshToken()) {
      return await postTlac(url, tlac);
    } else {
      throw new CoraError({
        UserMessage: UNAUTHORIZED_MSG,
        Code: "401.000",
      });
    }
  } else if (response.status === 503) {
    throw new CoraError({ UserMessage: MAINTENANCE_MSG, Code: "503" });
  } else {
    const res = await response.json();
    throw new CoraError(res);
  }
};

/**
 * Asynchronously returns appOffline status from server
 * @returns {Object} CoraResponse with bool Data
 */
export const getAppOffline = async () => {
  try {
    const response = await fetch(`${window.config.url}/api/meta/appOffline`);
    if (response.status === 200 || response?.status === 503) {
      return await response.json();
    }
  } catch (error) {
    console.log("unable to getAppOffline", error);
  }
  return { Data: false };
}

/**
 * Asynchronne získa konfiguráciu pre prihlasovanie.
 * @function getLoginConfig
 */
export const loadLoginConfig = async () => {

  const url = `/api/login/config`;
  try {
    const response = await fetch(`${window.config.url}${url}`, { method: "GET" });
    if (response.status === 200) {
      const res = await response.json();
      const cfg = res?.Data;
      if (cfg) {
        storage.setLoginConfig(cfg);
      }
    }
  }
  catch (error) {
    throw error;
  }
};
