
const USER_KEY = 'User';
const REFRESH_TOKEN_STATUS = 'RTStatus';
const LOGIN_CONFIG_KEY = 'LoginConfig';

/** @module utils/storage */

/**
 * Initializes session provider.
 * @function sessionProviderInit
 * @param {object} cb Callback
 */
export const sessionProviderInit = (cb) => {
  if (!sessionStorage.length) {
    // Ask other tabs for session storage
    localStorage.setItem('getSession', Date.now());
  };

  window.addEventListener('storage', function (event) {

    if (event.key === 'getSession') {
      // Some tab asked for the sessionStorage -> send it

      localStorage.setItem('session', sessionStorage.getItem('User'));
      localStorage.removeItem('session');

    } else if (event.key === 'session' && !sessionStorage.length) {
      // sessionStorage is empty -> fill it

      // var data = JSON.parse(event.newValue);
      sessionStorage.setItem('User', event.newValue);
      cb();

      // console.log(data)

      // for (const key in data) {
      //   sessionStorage.setItem(key, data[key]);
      // }

    }
  });

  window.onbeforeunload = function () {
    //sessionStorage.clear();
  };
}
/**
 * Returns 'User' from session storage.
 * @function getUser
 * @returns {Object}
 */
export const getUser = () => {
  return getItem(USER_KEY);
}

/**
 * Sets value for 'User' to session storage.
 * @function setUser
 * @param {Object} user value to be set
 */
export const setUser = (user) => {
  setItem(USER_KEY, user);
  setItem(REFRESH_TOKEN_STATUS, { Status: false, Time: new Date().getTime() });
}

/**
 * Sets value for 'RTSendTSTMP' to session storage. Time last requested refresh
 * @function setPendingStatus
 *  * @param {Object} status value to be set true - pending, false - awaited
 */
export const setPendingStatus = () => {
  setItem(REFRESH_TOKEN_STATUS, { Status: true, Time: new Date().getTime() });
}

/**
 * Returns 'RTSendTSTMP' from session storage.
 * @function getRefreshTime
 * @returns {Object} - 
 */
export const getRefreshStatus = () => {
  return getItem(REFRESH_TOKEN_STATUS);
}

/**
 * Removes 'User' from session storage.
 * @function removeUser
 */
export const removeUser = () => {
  removeItem(USER_KEY);
  removeItem(REFRESH_TOKEN_STATUS);
}

/**
 * Sets item in session storage.
 * @function setItem
 * @param {string} key item key to be set
 * @param {Object} value value to be set
 */
export const setItem = (key, value) => {
  sessionStorage.setItem(key, JSON.stringify(value));
}

/**
 * Returns item from session storage.
 * @function getItem
 * @param {string} key item key
 * @returns {Object}
 */
export const getItem = (key) => {
  return JSON.parse(sessionStorage.getItem(key));
}

/**
 * Removes item from session storage.
 * @function removeItem
 * @param {string} key key to be removed from session storage
 */
export const removeItem = (key) => {
  sessionStorage.removeItem(key);
}

/**
 * Prečíta konfiguráciu pre prihlasovanie xo SessionStorage.
 * @function getLoginConfig
 * @returns {Object}
 */
export const getLoginConfig = () => {
  return getItem(LOGIN_CONFIG_KEY);
}

/**
 * Zapíše konfiguráciu pre prihlasovanie do SessionStorage.
 * @function setLoginConfig
 * @param {Object} cfg Konfigurácia pre prihlasovanie
 */
export const setLoginConfig = (cfg) => {
  setItem(LOGIN_CONFIG_KEY, cfg);
}

