import React from 'react';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Error from '../../coraWebMComponents/feedBack/Error';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import * as storage from '../../coraWebMComponents/utils/storage';
import { connect } from 'react-redux';
import routes from '../../routes';
import * as userActions from '../../actions/UserActions';
import * as msgActions from '../../actions/MsgActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class Login extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      errorText: '',
      errorTextUser: '',
      errorTextPwd: '',
      user: '',
      pwd: '',
      loading: false,
    };
  }

  componentDidMount = async () => {
    storage.removeUser();

    if (this.props.isAuth)
      await this.props.dispatch(userActions.logout());
  };

  handleLogin = async (event) => {
    var username = this.state.user.trim();
    var pwd = this.state.pwd;

    if (!username) {
      this.setState({ errorTextUser: 'Je nutné zadať prihlasovacie meno.' });
    }
    else {
      this.setState({ errorTextUser: '' });
    }

    if (!pwd) {
      this.setState({ errorTextPwd: 'Je nutné zadať heslo.' });
    }
    else {
      this.setState({ errorTextPwd: '' });
    }

    this.setState({ errorText: '' });
    if (username && pwd) {
      await this.props.dispatch(userActions.login(username, pwd, this.props.meta.checkAppOffline));
      if (this.props.isAuth) {
        this.setState({ loading: true });
        await this.props.dispatch(msgActions.getMsgUnRead(this.props.meta.checkAppOffline));
        this.setState({ loading: false });

        if (this.props.msgUnRead.length > 0)
          this.props.navigate(routes.MSG_UNREAD);
        else
          this.props.navigate(routes.HOME);
      }
      else {
        this.setState({ errorText: 'Nesprávne prihlasovacie údaje.' });
      }
    }
  };

  handleChange = (name, value) => {
    this.setState({ [name]: value });
  };

  render() {

    return (
      <div>
        <LinearProgress loading={this.props.loading || this.state.loading} />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <React.Fragment>
              <h3>Mobilná aplikácia mestskej polície</h3>
              <TextField
                label="Prihlasovacie meno"
                fullwidth={true}
                errorText={this.state.errorTextUser}
                onChange={this.handleChange}
                name='user'
                value={this.state.user}
                variant="standard"
              />
              <TextField
                label="Heslo"
                type="password"
                fullwidth={true}
                errorText={this.state.errorTextPwd}
                onChange={this.handleChange}
                name='pwd'
                value={this.state.pwd}
                variant="standard"
              />
              <RaisedButton
                fullwidth={true}
                onClick={this.handleLogin}
                disabled={this.props.loading}
              >
                {'Prihlásiť sa'}
              </RaisedButton>
              <Error text={this.state.errorText} />
            </React.Fragment>
          </Paper>
        </div>
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
    loading: state.user.isLoading,
    userAuth: state.user.data,
    msgUnRead: state.msgUnRead.data
  };
};

export default withRouter(withMeta(connect(mapStateToProps)(Login)));