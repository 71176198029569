import actionTypes from './ActionTypes';
import * as msgUtils from '../utils/MsgUtils';
import CoraError from '../coraWebMComponents/utils/coraError';

const getMsgReadSuccess = (res) => ({
  type: actionTypes.GET_MSG_READ_SUCCESS,
  res
});

const getMsgReadRequest = () => ({
  type: actionTypes.GET_MSG_READ_REQUEST
});

const getMsgReadFailure = (error) => ({
  type: actionTypes.GET_MSG_READ_FAILURE,
  error
});

export const getMsgRead = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getMsgReadRequest());
      let res = await msgUtils.getMsg(true);
      dispatch(getMsgReadSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getMsgReadFailure(error))
        console.error(error);
      }
    }
  }
}

const getMsgUnReadSuccess = (res) => ({
  type: actionTypes.GET_MSG_UNREAD_SUCCESS,
  res
});

const getMsgUnReadRequest = () => ({
  type: actionTypes.GET_MSG_UNREAD_REQUEST
});

const getMsgUnReadFailure = (error) => ({
  type: actionTypes.GET_MSG_UNREAD_FAILURE,
  error
});

export const getMsgUnRead = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getMsgUnReadRequest());
      let res = await msgUtils.getMsg(false);
      dispatch(getMsgUnReadSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getMsgUnReadFailure(error))
        console.error(error);
      }
    }
  }
}

const setReadedRequest = () => ({
  type: actionTypes.SET_READED_REQUEST
});

const setReadedSuccess = (res) => ({
  type: actionTypes.SET_READED_SUCCESS,
  res
});

const setReadedFailure = (error) => ({
  type: actionTypes.SET_READED_FAILURE,
  error
});

export const setReaded = (iUzMsg, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(setReadedRequest());
      let res = await msgUtils.setReaded(iUzMsg);
      dispatch(setReadedSuccess(res));
      if (res === '1')
        return true;
      else
        return false;
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(setReadedFailure(error));
      }
      return false;
    }
  }
}
