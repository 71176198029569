import * as requests from '../coraWebMComponents/utils/requests';

export const getMsg = async (lread) => {
  try {
    let res = await requests.get(`/api/Sprava?lread=${lread}`);
    return res;
  } catch (error) {
    throw error;
  }
}

export const setReaded = async (iUzMsg) => {
  try {
    let res = await requests.put(`/api/Sprava/${iUzMsg}`);
    return res;
  } catch (error) {
    throw error;
  }
}

