import * as convert from '../coraWebMComponents/utils/convert';
import * as requests from '../coraWebMComponents/utils/requests';


export const getTrespassesByCar = async (ecv) => {
  try {
    return await requests.get(`/api/Trespass/GetByCar?ecv=${ecv}`)
  }
  catch (error) {
    throw error;
  }
}

export const getTrespassPhotos = async (id, isProcessed) => {
  try {
    return await requests.get(`/api/Trespass/GetTrespassPhotos?id=${id}&isProcessed=${isProcessed}`)
  }
  catch (error) {
    throw error;
  }
}

export const getTrespassesByPerson = async (form) => {
  try {
    return await requests.get(`/api/Trespass/GetByPerson?rc=${form.rc}&meno=${form.name}&priezvisko=${form.surname}&datumNarodenia=${convert.dateToDatestamp(form.birthDate)}`)
  }
  catch (error) {
    throw error;
  }
}

export const addTrespass = async (form, position) => {
  let body = {
    I_U: form.street ? form.street.I_U : null,
    I_CM: form.partTown ? form.partTown.I_CM : null,
    D_PR: convert.dateTimeToTimestamp(form.date, form.time),
    OPIS_PR: form.description,
    ECV: form.ecv,
    MENO: form.name,
    PF: form.surname,
    RC: form.rc,
    I_RIES: form.solutionId,
    C_OP: form.IDNumber,
    POKUTA: form.POKUTA === 0 ? null : form.POKUTA,
    BLOKY: form.BLOKY,
    I_UDAL_DR: form.I_UDAL_DR,
    I_FE_REG_LOG: form.I_FE_REG_LOG,
    I_O_TYP: form.I_O_TYP,
    I_O: form.person.I_O && form.person.I_O > 0 ? form.person.I_O : null,
    I_HLAS_PR: form.I_HLAS_PR,
    POZN: form.pozn
  }

  if (position) {
    Object.assign(body, {
      GPS_X: position.coords.longitude,
      GPS_Y: position.coords.latitude
    });
  }

  if (form.prescriptId) {
    Object.assign(body, {
      I_PRED: form.prescriptId
    });
  }

  try {
    return await requests.post(`/api/Trespass/Record`, body)
  }
  catch (error) {
    throw error;
  }
}

export const addAttachment = async (id, attachment, position) => {
  let body = {
    I_PR_MAMP: id,
    N_FILE: attachment.name,
    HODN_DAT: attachment.data.replace(/^.+,/, ''),
    D_VYTV: convert.dateToTimestamp(attachment.date)
  };

  if (position) {
    Object.assign(body, {
      GPS_X: position.coords.longitude,
      GPS_Y: position.coords.latitude
    });
  }

  try {
    let response = await requests.post(`/api/Trespass/Attachment`, body)
    if (response.Data) {
      let res = convert.escape(response.Data)
      if (res.startsWith('-')) {
        throw new Error(res);
      }
    }
    return response;
  }
  catch (error) {
    throw error;
  }
}

export const getPrescripts = async (iUdalDr) => {
  try {
    return await requests.get(`/api/Trespass/GetPrescrips?iUdalDr=${iUdalDr}`)
  }
  catch (error) {
    throw error;
  }
}

export const getSolutions = async (iUdalDr) => {
  try {
    return await requests.get(`/api/Trespass/GetSolutions/${iUdalDr}`);
  }
  catch (error) {
    throw error;
  }
}

export const getPeople = async (form) => {
  try {
    return await requests.get(`/api/Trespass/GetByPersonWithRelations?rc=${form.rc}&meno=${form.name}&priezvisko=${form.surname}&datumNarodenia=${convert.dateToDatestamp(form.birthDate)}`)
  }
  catch (error) {
    throw error;
  }
}


export const getBlocks = async (iUz, nezpl, iM) => {
  try {
    return await requests.get(`/api/Trespass/bloky?iUz=${iUz}&nezapl=${nezpl}&iM=${iM}`);
  }
  catch (error) {
    throw error;
  }
}


export const getPredDef = async (id) => {
  try {
    return await requests.get(`/api/Trespass/preddef/${id}`);
  }
  catch (error) {
    throw error;
  }
}

export const getEventTypes = async () => {
  try {
    return await requests.get(`/api/Trespass/GetEventTypes`);
  }
  catch (error) {
    throw error;
  }
}