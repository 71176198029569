let abortController = null;

const createAbortController = () => {
  abortController = new AbortController();
  abortController.signal.onabort = createAbortController;
}

/**
 * Vrati referenciu na AbortController.
 * Pouzitie: ako parameter pre fetch "signal: getAbortController().signal"
 * @returns 
 */
export const getAbortController = () => {
  if (!abortController) {
    createAbortController();
  }
  return abortController;
}
