import PropTypes from 'prop-types';

const isLocalHost = hostname => !!(
  hostname === 'localhost' ||
  hostname === '[::1]' ||
  hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

/**
 * Redirect na https podla priznaku forceHttps v config.js
 * @param {*} param0 
 * @returns 
 */
const HttpsRedirect = ({ children }) => {
  if (!isLocalHost(window.location.hostname) && String(window.config?.forceHttps)?.trim()?.toLowerCase() === "true" && window.location.protocol !== "https:") {
    window.location.href = window.location.href.replace(/^http(?!s)/, 'https');
    return null;
  }

  return children;
}

HttpsRedirect.propTypes = {
  children: PropTypes.node
}

export default HttpsRedirect;