import React from 'react';
import PropTypes from 'prop-types';

const style = {
  color: 'black',
  fontSize: '16px',
  marginTop: 8,
  fontWeight: 'bold'
};

/**
 * Info component
 * @module
 * @param {string} [text] - Info text
 */
class InfoComponent extends React.Component {
  render() {
    return (<div style={style}>{this.props.text}</div>);
  }
}

InfoComponent.propTypes = {
  text: PropTypes.string
}

export default InfoComponent;
