import actionTypes from './ActionTypes';
import * as dogUtils from '../utils/DogUtils';
import CoraError from '../coraWebMComponents/utils/coraError';

const getDogsSuccess = (res) => ({
  type: actionTypes.GET_DOGS_SUCCESS,
  res
});

const getDogsRequest = () => ({
  type: actionTypes.GET_DOGS_REQUEST
});

const getDogsFailure = (error) => ({
  type: actionTypes.GET_DOGS_FAILURE,
  error
});

export const getDogs = (form, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getDogsRequest());
      let res = await dogUtils.getDogs(form);
      dispatch(getDogsSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getDogsFailure(error))
        console.error(error);
      }
    }
  }
}