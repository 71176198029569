import actionTypes from './ActionTypes';
import * as partTownUtils from '../utils/PartTownUtils';
import * as userActions from './UserActions';
import CoraError from '../coraWebMComponents/utils/coraError';

const getPartsTownSuccess = (res) => ({
  type: actionTypes.GET_PARTS_TOWN_SUCCESS,
  res
});

const getPartsTownRequest = () => ({
  type: actionTypes.GET_PARTS_TOWN_REQUEST
});

const getPartsTownFailure = (error) => ({
  type: actionTypes.GET_PARTS_TOWN_FAILURE,
  error
});

export const getPartsTown = (text, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getPartsTownRequest());
      let res = await partTownUtils.getPartsTown(text);
      dispatch(getPartsTownSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getPartsTownFailure(error))
        console.error(error);
      }
    }
  }
}