import actionTypes from '../../actions/ActionTypes';

const _initState = {
  isLoading: false,
  data: []
}

const phRequiredBlocks = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_REQUIRED_BLOCKS_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_REQUIRED_BLOCKS_SUCCESS:
      return { isLoading: false, data: action.res.Data }

    case actionTypes.GET_REQUIRED_BLOCKS_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default phRequiredBlocks;