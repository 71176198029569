import React from 'react';
import PersonIcon from '@mui/icons-material/Person';
import PropTypes from 'prop-types';

class PersonIconComponent extends React.Component {
  render() {
    return (
      <PersonIcon
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

PersonIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string,
}

export default PersonIconComponent;
