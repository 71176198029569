import React, { useCallback, useMemo } from 'react';
import * as requests from './requests';
import { sessionProviderInit, getUser } from './storage';

const PAGE_KEY = 'Page';
const TABS_TYPE_KEY = 'TabsType';
const FILTERABLE = 'Filterable';
const THEME = 'Theme';
const APPLICATION_SETTINGS_KEY = 'AppSettings';
const GRIDSIZE_KEY = 'GridSize';
const DETAILSIZE_KEY = 'DetailSize';

export const useStorage = () => {
  const getItem = useCallback(async (key) => {
    const iUz = getUser()?.I_UZ;
    const url = `/api/meta/def/3001001/${key}?iUz=${iUz}`;
    try {
      var item = await requests.get(url);
      return JSON.parse(item.Data.Value);
    }
    catch (error) {
      return JSON.parse(sessionStorage.getItem(key));
    }
  }, [])

  const setItem = useCallback(async (key, value) => {
    const v = JSON.stringify(value);
    sessionStorage.setItem(key, v);

    const url = `/api/meta/def`;
    try {
      await requests.put(url, { Key: key, Value: v });
    }
    catch (error) {
    }
  }, [])

  const getApplicationSettings = useCallback(async () => {
    return await getItem(`${APPLICATION_SETTINGS_KEY}`);
  }, [getItem]);

  const setApplicationSettings = useCallback(async (data) => {
    await setItem(`${APPLICATION_SETTINGS_KEY}`, data);
  }, [setItem]);

  const getPage = useCallback(async () => {
    return await getItem(PAGE_KEY);
  }, [getItem]);

  const setPage = useCallback(async (page) => {
    await setItem(PAGE_KEY, page);
  }, [setItem]);

  const getTabsType = useCallback(async () => {
    return await getItem(TABS_TYPE_KEY);
  }, [getItem]);

  const setTabsType = useCallback(async (type) => {
    await setItem(TABS_TYPE_KEY, type);
  }, [setItem]);

  const getFilterable = useCallback(async () => {
    return await getItem(FILTERABLE);
  }, [getItem]);

  const setFilterable = useCallback(async (filterable) => {
    await setItem(FILTERABLE, filterable);
  }, [setItem]);

  const getTheme = useCallback(async () => {
    return await getItem(THEME);
  }, [getItem]);

  const setTheme = useCallback(async (theme) => {
    await setItem(THEME, theme);
  }, [setItem]);

  const getItemLocal = (key) => {
    return JSON.parse(localStorage.getItem(key));
  }

  const setItemLocal = (key, value) => {
    return localStorage.setItem(key, JSON.stringify(value));
  }

  const removeItemLocal = (key) => {
    localStorage.removeItem(key);
  }

  const getGridSize = useCallback(async () => {
    return await getItem(GRIDSIZE_KEY);
  }, [getItem]);

  const setGridsize = useCallback(async (gridsize) => {
    await setItem(GRIDSIZE_KEY, gridsize);
  }, [setItem]);

  const getDetailSize = useCallback(async () => {
    return await getItem(DETAILSIZE_KEY);
  }, [getItem]);

  const setDetailsize = useCallback(async (detailsize) => {
    await setItem(DETAILSIZE_KEY, detailsize);
  }, [setItem]);

  const getFilter = useCallback(async (primaryField) => {
    const url = `/api/filter/${primaryField}`;
    try {
      let filter = await requests.get(url);
      return {
        filters: filter.Data.filters.map(x => {
          const isDate = x.type === 'date' || x.field.startsWith('D_') || x.field.startsWith('Datum');
          return {
            field: x.field,
            operator: x.operator,
            value: isDate ? new Date(x.value) : x.value
          }
        })
      }
    }
    catch (error) {
      return { filters: [] }
    }
  }, []);


  const storage = useMemo(() => ({
    /**
     * Get item function
     * @function getItem
     * @param {string} key key
     * @returns {object} Item
     */
    getItem: getItem,
    /**
     * Get item function
     * @function getItemLocal
     * @param {string} key key
     * @returns {object} Item
     */
    getItemLocal: getItemLocal,
    /**
     * Set item function
     * @function setItemLocal
     * @param {string} key key
     * @param {string} value value
     */
    setItemLocal: setItemLocal,
    /**
     * Remove item function
     * @function removeItemLocal
     * @param {string} key key
     */
    removeItemLocal: removeItemLocal,
    /**
     * Set item function
     * @function getItem
     * @param {string} key key
     * @param {object} value value
     * @returns {object} Item
     */
    setItem: setItem,
    /**
     * Get filter function
     * @function getFilter
     * @param {string} primaryField Primary field
     * @returns {object} Filter
     */
    getFilter: getFilter,
    /**
     * Get page function
     * @function getPage
     * @returns {object} Page
     */
    getPage: getPage,
    /**
     * Set page function
     * @function setPage
     * @param {string} page Page
     */
    setPage: setPage,
    /**
     * Get tabs type function
     * @function getTabsType
     * @returns {object} Tabs type
     */
    getTabsType: getTabsType,
    /**
     * Set tabs type function
     * @function setTabsType
     * @param {string} type Type
     */
    setTabsType: setTabsType,
    /**
     * Get filterable function
     * @function getFilterable
     * @returns {object} Filterable
     */
    getFilterable: getFilterable,
    /**
     * Set filterable function
     * @function setFilterable
     * @param {string} filterable Filterable
     */
    setFilterable: setFilterable,
    /**
     * Get gridsize function
     * @function gridsize
     * @returns {object} Gridsize
     */
    getGridSize: getGridSize,
    /**
     * Set gridsize function
     * @function gridsize
     * @param {string} gridsize Gridsize
     */
    setGridsize: setGridsize,
    /**
     * Get detailsize function
     * @function detailsize
     * @returns {object} Detailsize
     */
    getDetailSize: getDetailSize,
    /**
     * Set detailsize function
     * @function detailsize
     * @param {string} detailsize Detailsize
     */
    setDetailsize: setDetailsize,
    /**
     * Get theme function
     * @function getTheme
     * @returns {object} Theme
     */
    getTheme: getTheme,
    /**
     * Set page function
     * @function setTheme
     * @param {string} theme Theme
     */
    setTheme: setTheme,
    /**
     * Initializes session provider.
     * @function sessionProviderInit
     * @param {object} cb Callback
     */
    sessionProviderInit,
    /**
     * Set application settings function
     * @function setApplicationSettings
     * @param {string} data data
     */
    setApplicationSettings: setApplicationSettings,
    /**
     * Get application settings function
     * @function getApplicationSettings
     * @returns {object} Application settings
     */
    getApplicationSettings: getApplicationSettings,
  }), [
    getItem, setItem,
    getApplicationSettings, getDetailSize, getFilterable, getGridSize, getPage,
    getTabsType, getTheme, setApplicationSettings, setDetailsize, setFilterable,
    setGridsize, setPage, setTabsType, setTheme, getFilter
  ])

  return storage;
}

/**
 * Returns wrapped component with storage props
 * @module
 * @param {element} WrappedComponent - Wrapped component
 */
const withStorage = (WrappedComponent) => {
  class HOC extends React.Component {

    render() {
      const { forwardedRef, ...props } = this.props;
      return (
        <WrappedComponent
          ref={forwardedRef}
          {...props}
        />
      );
    }
  }

  return React.forwardRef((props, ref) => {
    const storage = useStorage();
    return <HOC {...props} forwardedRef={ref} storage={storage} />;
  });
};

export default withStorage;