import * as requests from '../coraWebMComponents/utils/requests';


export const getMapMarkers = async (allocated) => {

  try {
    return await requests.get(`/api/MapMarker?bAllocated=${allocated ? 'true' : 'false'}`);
  }
  catch (error) {
    throw error;
  }
}

export const setMapMarker = async (iHlasPr, iState) => {

  try {
    return await requests.put(`/api/MapMarker?iHlasPr=${iHlasPr}&iState=${iState}`);
  }
  catch (error) {
    throw error;
  }
}

export const getMapFotos = async (iHlasPr) => {

  try {
    return await requests.get(`/api/MapFoto?iHlasPr=${iHlasPr}`);
  }
  catch (error) {
    throw error;
  }
}