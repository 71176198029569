import actionTypes from '../../actions/ActionTypes';

const _initState = { 
  isLoading: false, 
  data: [],
  total: 0
}

const parkingCardsList = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_CARDS_LIST_REQUEST: 
      return { data: [], isLoading: true, total: 0 };

    case actionTypes.GET_PARKING_CARDS_LIST_SUCCESS:  {
      return { isLoading: false, data: action.data.Data.Items, total: action.data.Data.TotalRecords };
    }

    case actionTypes.GET_PARKING_CARDS_LIST_FAILURE:
      return { ...state, isLoading: false };

    case actionTypes.CLEAR_PARKING_CARDS_LIST:
      return _initState;
  
    default:
      return state
  }
}

export default parkingCardsList;