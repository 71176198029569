import * as requests from '../coraWebMComponents/utils/requests';

export const getStreets = async (searchText) => {
  if(searchText.length === 0) {
    return [];
  }
  try {
    return await requests.get(`/api/Street?filter=N_U|${searchText}`);
  } 
  catch (error) {
    throw error;  
  }
}