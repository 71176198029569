import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Typography from "../dataDisplay/Typography";
import Button from "../inputs/Button";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  preloadWrapper: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#343434",
  },
  appOfflineText: {
    color: "#bdbdbd",
    padding: "20px 0 50px 0",
    letterSpacing: "0.25rem",
  },
  outlineBtn: {
    letterSpacing: "0.15rem",
    padding: "8px 14px",
    width: "250px",
  },
  appLogo: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "250px",
      height: "250px",
    },
  }
});

/**
 * AppOffline component
 * @module
 */
const AppOffline = (props) => {
  const { classes } = props;

  return (
    <div className={classNames(classes.preloadWrapper)}>
      <div
        className={classNames(classes.row, classes.justifyContentCenter)}
      >
        <img
          className={classNames(classes.appLogo)}
          src={"assets/datamesta_logo_preloading.svg"}
          alt="datamesta"
        />
      </div>
      <div className={classNames(classes.textCenter)}>
        <Typography
          variant="subtitle1"
          className={classNames(classes.appOfflineText)}
        >
          {"Údržba portálu"}
        </Typography>
      </div>
      <div className={classNames(classes.textCenter)}>
        <Typography
          variant="body1"
          className={classNames(classes.appOfflineText)}
        >
          {"Prebieha plánovaná údržba portálu. Ďakujeme za porozumenie a prajeme príjemný deň."}
        </Typography>
      </div>
      <div
        className={classNames(
          classes.row,
          classes.justifyContentCenter,
          classes.mb3
        )}
      >
        <Button
          variant="outlined"
          color="primary"
          size="large"
          onClick={() => { window.location.reload() }}
          className={classNames(classes.outlineBtn)}
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {"Overiť dostupnosť portálu"}
        </Button>
      </div>
    </div>
  );
}

export default withStyles(styles)(AppOffline);