import React from 'react';
import PropTypes from 'prop-types';

const style = {
  color: 'red',
  fontSize: '16px',
  marginTop: 8,
  fontWeight: 'bold',
  fontFamily: 'Roboto, Arial'
};

/**
 * Error component
 * @module
 * @param {string} [text] - Error text
 */
class ErrorComponent extends React.Component {
  render() {
    return (<div style={style}>{this.props.text}</div>);
  }
}

ErrorComponent.propTypes = {
  text: PropTypes.string
}

export default ErrorComponent;
