import actionTypes from './ActionTypes';
import * as trespassUtils from '../utils/TresspassUtils';
import * as locationUtils from '../utils/LocationUtils';
import * as userActions from './UserActions';
import CoraError from '../coraWebMComponents/utils/coraError';

const getTrespassesByCarSuccess = (res) => ({
  type: actionTypes.GET_TRESPASSES_BY_CAR_SUCCESS,
  res
});

const getTrespassesByCarRequest = () => ({
  type: actionTypes.GET_TRESPASSES_BY_CAR_REQUEST
});

const getTrespassesByCarFailure = (error) => ({
  type: actionTypes.GET_TRESPASSES_BY_CAR_FAILURE,
  error
});

export const getTrespassesByCar = (ecv, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getTrespassesByCarRequest());
      let res = await trespassUtils.getTrespassesByCar(ecv);
      dispatch(getTrespassesByCarSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getTrespassesByCarFailure(error))
        console.error(error);
      }
    }
  }
}

const getTrespassPhotosSuccess = (res) => ({
  type: actionTypes.GET_TRESPASS_PHOTOS_SUCCESS,
  res
});

const getTrespassPhotosRequest = () => ({
  type: actionTypes.GET_TRESPASS_PHOTOS_REQUEST
});

const getTrespassPhotosFailure = (error) => ({
  type: actionTypes.GET_TRESPASS_PHOTOS_FAILURE,
  error
});

export const getTrespassPhotos = (id, isProcessed, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getTrespassPhotosRequest());
      let res = await trespassUtils.getTrespassPhotos(id, isProcessed);
      dispatch(getTrespassPhotosSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getTrespassPhotosFailure(error))
        console.error(error);
      }
    }
  }
}

const getTrespassesByPersonSuccess = (res) => ({
  type: actionTypes.GET_TRESPASSES_BY_PERSON_SUCCESS,
  res
});

const getTrespassesByPersonRequest = () => ({
  type: actionTypes.GET_TRESPASSES_BY_PERSON_REQUEST
});

const getTrespassesByPersonFailure = (error) => ({
  type: actionTypes.GET_TRESPASSES_BY_PERSON_FAILURE,
  error
});

export const getTrespassesByPerson = (form, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getTrespassesByPersonRequest());
      let res = await trespassUtils.getTrespassesByPerson(form);
      dispatch(getTrespassesByPersonSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getTrespassesByPersonFailure(error))
        console.error(error);
      }
    }
  }
}

const addTrespassSuccess = () => ({
  type: actionTypes.ADD_TRESPASS_SUCCESS
});

const addTrespassRequest = () => ({
  type: actionTypes.ADD_TRESPASS_REQUEST
});

const addTrespassFailure = (error) => ({
  type: actionTypes.ADD_TRESPASS_FAILURE,
  error
});

let _trespassId = null;
let _position = null;
export const addTrespass = (form, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(addTrespassRequest());
      if (!_trespassId) {
        _position = await locationUtils.getPosition();
        _trespassId = await trespassUtils.addTrespass(form, _position);
      }

      let promises = [];
      for (let attachment of form.images) {
        promises.push(trespassUtils.addAttachment(_trespassId, attachment, _position));
      }
      await Promise.all(promises);
      if (_trespassId == "-650902") {
        throw { name: _trespassId, message: 'Priestupok sa nepodarilo zaevidovať - chýbajúce prepojenie v Portal ISS' }
      }
      _trespassId = null;
      dispatch(userActions.showMsg('Priestupok evidovaný.'));
      dispatch(addTrespassSuccess());
      return true;
    } catch (error) {
      if (error.name === "-650902") {
        dispatch(userActions.showErrorMsg(error.message));
        _trespassId = null;
        console.error(error);
      } else {
        if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
          await checkAppOffline();
        } else {
          dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
          dispatch(addTrespassFailure(error))
          console.error(error);
        }
        return false;
      }
    }
  }
}

const getPrescriptsSuccess = (res) => ({
  type: actionTypes.GET_PRESCRIPTS_SUCCESS,
  res
});

const getPrescriptsRequest = () => ({
  type: actionTypes.GET_PRESCRIPTS_REQUEST
});

const getPrescriptsFailure = (error) => ({
  type: actionTypes.GET_PRESCRIPTS_FAILURE,
  error
});

export const getPrescripts = (iUdalDr, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getPrescriptsRequest());
      let res = await trespassUtils.getPrescripts(iUdalDr);
      dispatch(getPrescriptsSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getPrescriptsFailure(error));
        console.error(error);
      }
    }
  }
}

const getSolutionsSuccess = (res) => ({
  type: actionTypes.GET_SOLUTIONS_SUCCESS,
  res
});

const getSolutionsRequest = () => ({
  type: actionTypes.GET_SOLUTIONS_REQUEST
});

const getSolutionsFailure = (error) => ({
  type: actionTypes.GET_SOLUTIONS_FAILURE,
  error
});

export const getSolutions = (iUdalDr, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getSolutionsRequest());
      let res = await trespassUtils.getSolutions(iUdalDr);
      dispatch(getSolutionsSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getSolutionsFailure(error));
        console.error(error);
      }
    }
  }
}

const getPeopleSuccess = (res) => ({
  type: actionTypes.GET_PEOPLE_SUCCESS,
  res
});

const getPeopleRequest = () => ({
  type: actionTypes.GET_PEOPLE_REQUEST
});

const getPeopleFailure = (error) => ({
  type: actionTypes.GET_PEOPLE_FAILURE,
  error
});

export const getPeople = (form, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getPeopleRequest());
      let res = await trespassUtils.getPeople(form);
      dispatch(getPeopleSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getPeopleFailure(error))
        console.error(error);
      }
    }
  }
}

const getBlocksSuccess = (res) => ({
  type: actionTypes.GET_BLOCKS_SUCCESS,
  res
});

const getBlocksRequest = () => ({
  type: actionTypes.GET_BLOCKS_REQUEST
});

const getBlocksFailure = (error) => ({
  type: actionTypes.GET_BLOCKS_FAILURE,
  error
});

export const getBlocks = (iUz, nezpl, iM, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getBlocksRequest());
      let res = await trespassUtils.getBlocks(iUz, nezpl, iM);
      dispatch(getBlocksSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getBlocksFailure(error));
        console.error(error);
      }
    }
  }
}

const getRequiredBlocksRequest = () => ({
  type: actionTypes.GET_REQUIRED_BLOCKS_REQUEST
});

const getRequiredBlocksSuccess = (res) => ({
  type: actionTypes.GET_REQUIRED_BLOCKS_SUCCESS,
  res
});

const getRequiredBlocksFailure = (error) => ({
  type: actionTypes.GET_REQUIRED_BLOCKS_FAILURE,
  error
});

export const getRequiredBlocks = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getRequiredBlocksRequest());
      let res = await trespassUtils.getPredDef(902004);
      dispatch(getRequiredBlocksSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getRequiredBlocksFailure(error));
      }
    }
  }
}

const getOrderBlocksRequest = () => ({
  type: actionTypes.GET_ORDER_BLOCKS_REQUEST
});

const getOrderBlocksSuccess = (res) => ({
  type: actionTypes.GET_ORDER_BLOCKS_SUCCESS,
  res
});

const getOrderBlocksFailure = (error) => ({
  type: actionTypes.GET_ORDER_BLOCKS_FAILURE,
  error
});

export const getOrderBlocks = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getOrderBlocksRequest());
      let res = await trespassUtils.getPredDef(902002);
      dispatch(getOrderBlocksSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getOrderBlocksFailure(error));
      }
    }
  }
}

const getEventTypesSuccess = (res) => ({
  type: actionTypes.GET_EVENT_TYPES_SUCCESS,
  res
});

const getEventTypesRequest = () => ({
  type: actionTypes.GET_EVENT_TYPES_REQUEST
});

const getEventTypesFailure = (error) => ({
  type: actionTypes.GET_EVENT_TYPES_FAILURE,
  error
});

export const getEventTypes = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getEventTypesRequest());
      let res = await trespassUtils.getEventTypes();
      dispatch(getEventTypesSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showMsg('Neočakávaná chyba.'));
        dispatch(getEventTypesFailure(error));
        console.error(error);
      }
    }
  }
}

const getAccessPersonRequest = () => ({
  type: actionTypes.GET_ACCESS_PERSON_REQUEST
});

const getAccessPersonSuccess = (res) => ({
  type: actionTypes.GET_ACCESS_PERSON_SUCCESS,
  res
});

const getAccessPersonFailure = (error) => ({
  type: actionTypes.GET_ACCESS_PERSON_FAILURE,
  error
});

export const getAccessPerson = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getAccessPersonRequest());
      let res = await trespassUtils.getPredDef(2563005);
      dispatch(getAccessPersonSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getAccessPersonFailure(error));
      }
    }
  }
}

const getEditableFieldsRequest = () => ({
  type: actionTypes.GET_EDITABLE_FIELDS_REQUEST
});

const getEditableFieldsSuccess = (res) => ({
  type: actionTypes.GET_EDITABLE_FIELDS_SUCCESS,
  res
});

const getEditableFieldsFailure = (error) => ({
  type: actionTypes.GET_EDITABLE_FIELDS_FAILURE,
  error
});

export const getEditableFields = (checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getEditableFieldsRequest());
      let res = await trespassUtils.getPredDef(2563006);
      dispatch(getEditableFieldsSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(getEditableFieldsFailure(error));
      }
    }
  }
}