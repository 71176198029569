import actionTypes from '../../actions/ActionTypes';

const _initState = {
  IsLoading: false,
  Status: 200,
  Data: []
}

const alpr = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_ALPR_REQUEST:
      return { IsLoading: true, Status: 200, Data: [] };
    case actionTypes.GET_PARKING_ALPR_SUCCESS:
      return { ...action.res, IsLoading: false };
    case actionTypes.GET_PARKING_ALPR_FAILURE:
      return _initState;
    default:
      return state
  }
}

export default alpr;