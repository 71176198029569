import * as requests from '../coraWebMComponents/utils/requests';

export const login = async (username, password) => {
  try {
    let response = await requests.signIn(username, password);
    return response;
  } 
  catch (error) {
    throw error;
  }
}
