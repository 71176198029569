import actionTypes from '../../actions/ActionTypes';

const _initState = {
  isLoading: false,
  data: [],
  total: 0
}

const personList = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERSON_LIST_REQUEST:
      return { data: [], isLoading: true, total: 0 };

    case actionTypes.GET_PERSON_LIST_SUCCESS:
      return { isLoading: false, data: action.res.Data.Items, total: action.res.Data.TotalRecords }

    case actionTypes.GET_PERSON_LIST_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default personList;