import React from 'react';
import { useLocation, useNavigate } from "react-router";

/**
 * WithRouter will pass updated match, location, and history props to the wrapped component whenever it renders.
 * @module
 * @param {element} WrappedComponent - Wrapped component
 */

const withRouter = (WrappedComponent) => {
  return React.forwardRef((props, ref) => (<WrappedComponent {...props} ref={ref} location={useLocation()} navigate={useNavigate()} />));

  // return props => <WrappedComponent {...props} location={useLocation()} navigate={useNavigate()} />;
  // const HOC = withRouterComponent(({ forwardedRef, ...props }) => (
  //   <WrappedComponent {...props} ref={forwardedRef} />
  // ));

  // return React.forwardRef((props, ref) => (<HOC {...props} forwardedRef={ref} />));
};

export default withRouter;