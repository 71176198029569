import React from 'react';
import List from '../../coraWebMComponents/dataDisplay/List';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBarDrawer';
import Info from '../../coraWebMComponents/feedBack/Info';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import * as convert from '../../coraWebMComponents/utils/convert';
import { connect } from 'react-redux';
import * as msgActions from '../../actions/MsgActions';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class MsgRead extends React.Component {

  render() {
    return (
      <div>
        <AppBar title='Správy' />
        <LinearProgress loading={this.props.isLoading} />
        <div className='container'>

          {(this.props.msgRead.length === 0 && !this.props.isLoading) &&
            <Info text='Žiadné správy.' />
          }
          <List>

            {this.props.msgRead.map((msg) =>
              <Paper className='content' elevation={3} >
                <Item key={msg.I_UZ_MSG} data={msg} />
              </Paper>
            )}
          </List>

        </div>
      </div>
    );
  }

  componentDidMount = async () => {
    await this.props.dispatch(msgActions.getMsgRead(this.props.meta.checkAppOffline));
  }
}

class Item extends React.Component {
  render() {
    return (
      <ListItem>
        <div className="item" style={{ overflow: 'overlay' }}>
          <div> <b>Názov: </b>{this.props.data.MSG_HL}</div>
          <div> <b>Platnosť od: </b>{convert.convertDate(this.props.data.D_VYTV)}</div>
          <div> <b>Platnosť do: </b>{convert.convertDate(this.props.data.D_PLAT_DO)}</div>
          <div> <b>Správa: </b>{this.props.data.MSG_TXT}</div>
        </div>
      </ListItem >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    msgRead: state.msgRead.data,
    isLoading: state.msgRead.isLoading,
  }
}

export default withMeta(connect(mapStateToProps)(MsgRead));