import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { makeStyles } from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Tooltip from './ToolTip';

const useStyles = makeStyles((_theme) => ({
  button: {
    padding: '7px',
  },
  disableHover: {
    background: 'transparent!important',
  },
}));

const IconButtonComponent = (props) => {
  const classes = useStyles();

  return (
    <Tooltip
      label={props.toolTip}
      place={props.toolTipPlace}
      disableFocusListener={props.disableTooltip}
      disableTouchListener={props.disableTooltip}
      disableHoverListener={props.disableTooltip}
    >
      {/* Fix for warning: You are providing a disabled `button` child to the Tooltip component. Add a simple wrapper element, such as a `span`. */}
      <span>
        <IconButton
          className={classNames(
            classes.button,
            props.className,
            props.disableHover && classes.disableHover
          )}
          color={props.color}
          label={props.label}
          disabled={props.disabled}
          onClick={props.onClick}
          style={props.style}
          disableRipple={props.disableRipple}
        >
          {props.children}
        </IconButton>
      </span>
    </Tooltip>
  );
};

IconButtonComponent.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  onClick: PropTypes.func,
  toolTip: PropTypes.string,
  toolTipPlace: PropTypes.string,
  disableRipple: PropTypes.bool,
  disableHover: PropTypes.bool,
  disableTooltip: PropTypes.bool,
};

export default IconButtonComponent;
