import actionTypes from '../../actions/ActionTypes';

const _initState = {
    isLoading: false,
    data: [],
}

const editableFields = (state = _initState, action) => {
    switch (action.type) {
        case actionTypes.GET_EDITABLE_FIELDS_REQUEST:
            return { data:[], isLoading: true };

        case actionTypes.GET_EDITABLE_FIELDS_SUCCESS:
            return { isLoading: false, data: action.res.Data};

        case actionTypes.GET_EDITABLE_FIELDS_FAILURE:
            return _initState;

        default:
            return state
    }
}

export default editableFields;