import React from 'react';
import PropTypes from 'prop-types';
import AttachFile from '@mui/icons-material/AttachFile';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import FolderSharp from '@mui/icons-material/FolderSharp';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@mui/material/Tooltip'
import { fileTypeFromBuffer } from 'file-type';
import Dialog from '../feedBack/Dialog';

const getIcon = (icon) => {
  const style = { width: '30px', height: '30px' };
  switch (icon) {
    case 1:
      return <PhotoCamera style={style} />;
    case 2:
      return <FolderSharp style={style} />;
    default:
      return <AttachFile style={style} />;
  }
}

class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this.fileInputRef = React.createRef();
    this.state = {
      openDialog: false,
      dialogContent: '',
    };
  }

  handleDialogOpen = (content) => {
    this.setState({ openDialog: true, dialogContent: content });
  };

  handleDialogClose = () => {
    this.setState({ openDialog: false, dialogContent: '' });
  };

  onChange = async (event) => {
    event.preventDefault();
    const target = event.target;

    for (let i = 0; i < event.target.files.length; i++) {
      const file = event.target.files[i];
      const reader = new FileReader();
      const mimetypes = this.props.mimetype.split(/\s*,\s*/);

      const buffer = await file.arrayBuffer();
      const type = await fileTypeFromBuffer(buffer);

      if (!type || !mimetypes.includes(type.mime) || type.mime !== file.type) {
        this.handleDialogOpen("Nahrávanie súboru zlyhalo. Súbor je poškodený.");
        continue;
      }

      reader.onloadend = () => {
        const f = {
          name: file.name,
          date: file.lastModifiedDate,
          data: reader.result,
          size: file.size,
          type: file.type
        };

        this.props.onChange(f);
        target.value = null;
      }
      reader.readAsDataURL(file);
    }
  }

  openFileDialog = () => {
    this.fileInputRef.current.click();
  }

  render() {
    if (this.props.disabled) {
      return null;
    }
    else {
      return (
        <div>
          <Tooltip
            title={this.props.title}
            placement="right-start"
            color='secondary'
          >
            <IconButton
              onClick={this.openFileDialog}
            >
              {getIcon(this.props.iconType)}
            </IconButton>
          </Tooltip>
          <input
            ref={this.fileInputRef}
            style={{ display: 'none' }}
            className="file"
            multiple={this.props.multiple}
            type="file"
            accept={this.props.mimetype}
            onChange={this.onChange}
          />
          <Dialog
            modal={false}
            open={this.state.openDialog}
            onClose={this.handleDialogClose}
            contentText={this.state.dialogContent}
            title={'Upozornenie'}
            actions={[
              {
                label: 'Zatvoriť',
                color: 'primary',
                onClick: this.handleDialogClose,
                keyboardFocused: true
              },
            ]}
          />
        </div>
      );
    }
  }
}

FileInput.propTypes = {
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  mimetype: PropTypes.string,
  title: PropTypes.string.isRequired,
  iconType: PropTypes.number,
  multiple: PropTypes.bool,
}

export default FileInput;