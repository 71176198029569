import * as requests from '../coraWebMComponents/utils/requests';


export const getPartsTown = async (searchText) => {
  if (searchText.length === 0) {
    return [];
  }

  try {
    return await requests.get(`/api/PartOfTown?filter=N_CM|${searchText}`);
  } 
  catch (error) {
    throw error;  
  }
}