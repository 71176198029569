import React from 'react';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBarDrawer';

export default class Home extends React.Component {
  getLogoSrc = () => {
    let basename = window.config.basename;

    if (!basename) {
      basename = '/';
    }

    if (!basename.endsWith('/')) {
      basename += '/';
    }

    return basename + 'logo.svg';
  }

  getVer = () => {
    let verzia = process.env.REACT_APP_VERSION;
    let verzia1 = verzia.substring(0, 4);
    let verzia2 = verzia.substring(4, verzia.lastIndexOf('.'));
    return verzia1 + '.' + verzia2;
  }

  render() {
    return (
      <div>
        <AppBar title='MAMP' />
        <div className='container'>
          <Paper className='content' elevation={10} >
            <React.Fragment>
              <h3>Mobilná aplikácia mestskej polície</h3>
              <div className='center'>
                <img style={{ width: '100%' }} src={this.getLogoSrc()} alt='MAMP' />
              </div>
              <h5 className='center'>Verzia: {this.getVer()}</h5>
            </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }
}

