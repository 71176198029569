import actionTypes from '../../actions/ActionTypes';

const _initState = { 
  isLoading: false, 
  disabled: false,
}

const trespass = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.ADD_TRESPASS_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.ADD_TRESPASS_SUCCESS:
      return { isLoading: false, disabled: false };

    case actionTypes.ADD_TRESPASS_FAILURE:
      return { isLoading: false, disabled: true };
  
    default:
      return state
  }
}

export default trespass;