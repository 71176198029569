import * as requests from '../coraWebMComponents/utils/requests';


export const getParkingCards = async (ecv, page) => {
  try {
    return await requests.get(`/api/Parking?&page=${page}&limit=${10}&ecvfilter=%25${ecv}%25`)
  }
  catch (error) {
    throw error;  
  }
}

export const getParkingTickets = async (ecv, page) => {
  try {
    return await requests.get(`/api/Ext?page=${page}&limit=${10}&ecvfilter=%25${ecv}%25`)
  }
  catch (error) {
    throw error;  
  }
}

export const getParkingCardsList = async (ecv) => {
  try {
    return await requests.get(`/api/Parking?allEcvfilter=%25${ecv}%25`)
  }
  catch (error) {
    throw error;  
  }
}

export const getParkingExtInfo = async() => {
  try {
    return await requests.get(`/api/Ext?getInfo=true`)
  }
  catch (error) {
    throw error;  
  }
}

export const getParkingAlpr = async(imageData) => {
  try {
    let data = imageData.replace(/^.+,/, '');
    return await requests.post(`/api/Alpr`, {data})
  }
  catch (error) {
    throw error;  
  }
}