import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import { withStyles } from "@material-ui/core";

const styles = (theme) => ({
  paper: {
    margin: '3px'
  },
});

function Transition(props) {
  return <Slide direction="down" {...props} />;
}

class FormModalComponent extends React.Component {
  render() {
    const { classes } = this.props;

    return (
      <Dialog
        fullScreen={this.props.fullScreen || false}
        classes={{ paper: this.props.disableMargins ? classes.paper : null }}
        open={this.props.open}
        onClose={this.props.onClose}
        aria-labelledby='ariaLabelledby'
        aria-describedby='ariaDescribedby'
        TransitionComponent={Transition}
      >
        {this.props.elements}
      </Dialog>
    )
  }
}

FormModalComponent.propTypes = {
  classes: PropTypes.object,
  fullScreen: PropTypes.bool,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  elements: PropTypes.element,
  disableMargins: PropTypes.bool
}


export default withStyles(styles)(FormModalComponent);