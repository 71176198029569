import moment from 'moment';
import _ from 'lodash';

import { formatMapping } from "./convertIntl";

/** @module utils/convert */

/**
 * Adds offset(time zone) to given date.
 * @function addOffset
 * @param {Date} date date without offset(time zone)
 * @returns {(Date | null)}
 */
export const addOffset = date => {
  if (!date) {
    return null;
  }

  const offset = date.getTimezoneOffset() * 60000;
  return new Date(date.getTime() - offset);
};

/**
 * Converts string to Date.
 * @function strToDate
 * @param {string} str date as string
 * @returns {(Date | null)}
 */
export const strToDate = str => {
  if (!str) {
    return null;
  }
  if (str instanceof Date) {
    return str;
  }
  return new Date(str);
};

/**
 * Creates instance of date or update date with specified time.
 * @function strToTime
 * @param {Date | null} date date or null
 * @param {string} str time as string
 * @returns {Date}
 */
export const strToTime = (date, str) => {
  if (!date) {
    return new Date();
  }
  const time = str.split(':');
  return new Date(date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    time[0],
    time[1]);
};

/**
 * Formatuje datetime na sk-SK format dany patternom: '{0:format}' alebo '{0:kod}' (kod je jeden znak) alebo formatovaci string napr. 'dd.mm.yyyy hh:mm'
 *
 * @param {*} date
 * @param {*} formatPattern
 * @return {*} 
 */
function formatDateSK(date, formatPattern, locale = "sk-SK") {
  const formatMappingLocale = formatMapping[locale].codes;
  const days = formatMapping[locale].days;
  const daysShort = formatMapping[locale].daysShort;
  const months = formatMapping[locale].months;
  const monthsShort = formatMapping[locale].monthsShort;

  let format = formatPattern;
  // Hladaj pattern '{0:X}'
  const match = formatPattern.match(/\{0:([a-zA-Z])\}/);
  // Ak je pattern '{0:X}', premapuj na skutocny format
  if (match) {
    const formatKey = match[1];
    if (formatMappingLocale.hasOwnProperty(formatKey)) {
      format = formatPattern.replace(`{0:${formatKey}}`, formatMappingLocale[formatKey]);
    }
  } else if (formatPattern.startsWith('{0:')) {
    // Ak je pattern '{0:format}', vytiahneme z patternu 'format'
    format = formatPattern.slice(3, -1); // Odstránime '{0:' a '}'
  }

  const is12HourFormat = format.includes('tt'); // Kontrola pritomnosti "tt" vo formate
  const dateGetMilliseconds = date.getMilliseconds();
  const dateGetSeconds = date.getSeconds();
  const dateGetMinutes = date.getMinutes();
  const dateGetHours = date.getHours();
  const dateGetDate = date.getDate();
  const dateGetMonth = date.getMonth();
  const dateGetFullYear = date.getFullYear();
  const dateGetDay = date.getDay();

  const replacements = {
    'd': dateGetDate,
    'dd': String(dateGetDate).padStart(2, '0'),
    'ddd': daysShort[dateGetDay],
    'dddd': days[dateGetDay],
    'f': String(dateGetMilliseconds).substring(0, 1),
    'ff': String(dateGetMilliseconds).padStart(3, '0').substring(0, 2),
    'fff': String(dateGetMilliseconds).padStart(3, '0'),
    'M': dateGetMonth + 1,
    'MM': String(dateGetMonth + 1).padStart(2, '0'),
    'MMM': monthsShort[dateGetMonth],
    'MMMM': months[dateGetMonth],
    'h': is12HourFormat ? (dateGetHours % 12 || 12) : dateGetHours,
    'hh': String(is12HourFormat ? (dateGetHours % 12 || 12) : dateGetHours).padStart(2, '0'),
    'H': dateGetHours,
    'HH': String(dateGetHours).padStart(2, '0'),
    'm': dateGetMinutes,
    'mm': String(dateGetMinutes).padStart(2, '0'),
    's': dateGetSeconds,
    'ss': String(dateGetSeconds).padStart(2, '0'),
    'tt': dateGetHours < 12 ? 'AM' : 'PM',
    'y': String(dateGetFullYear),
    'yy': String(dateGetFullYear).substring(2, 4),
    'yyy': 'yyy', // nekonvertovat
    'yyyy': dateGetFullYear,
    'zzz': (() => {
      const offset = date.getTimezoneOffset();
      const sign = offset > 0 ? "-" : "+";
      const absOffset = Math.abs(offset);
      const hours = String(Math.floor(absOffset / 60)).padStart(2, '0');
      const minutes = String(absOffset % 60).padStart(2, '0');
      return `${sign}${hours}:${minutes}`;
    })()
  };

  return format.replace(/d{1,4}|M{1,4}|yyyy|yyy|yy|y|([Hhms]{1,2})|f{1,3}|tt|zzz/g, match => replacements[match]);
}

/**
 * Returns a string with a language sensitive representation of this date. MUI version
 * @function dateToStr
 * @param {Date | null} date date or null
 * @param {object | string | null} format null or object which specifies how given date is going to be formatted
 * @returns {string}
 */

export const dateToStr = (date, format, locale = "sk-SK") => {
  if (!date) {
    return '';
  }
  if (_.isString(date)) {
    date = new Date(date);
  }
  if (format) {
    if (_.isString(format)) {
      return formatDateSK(date, format, locale);
    } else {
      return date.toLocaleString(locale, format);
    }
  } else {
    return date.toLocaleString(locale);
  }
};

/**
 * Returns a string with a language sensitive representation of this date. MUI version
 * @function dataToLocaleStr
 * @param {Date | null} date date or null
 * @param {object | string | null} format null or object which specifies how given date is going to be formatted
 * @returns {string}
 */
export const dataToLocaleStr = (date, format) => {
  return dateToStr(date, format);
};


/**
 * Returns a string with a language sensitive representation of the date portion of this date. 
 * @function dataToLocaleDateStr
 * @param {Date | null} date date or null
 * @param {object | null} format null or object which specifies how given date is going to be formatted
 * @returns {string}
 */
export const dataToLocaleDateStr = (date, format) => {
  if (!date) {
    return '';
  }
  if (format) {
    return date.toLocaleDateString('sk-SK', format);
  } else {
    return date.toLocaleDateString('sk-SK');
  }
};

/**
 * Returns the time portion of a Date object as a string, using locale conventions.
 * @function dataToLocaleTimeStr
 * @param {Date | null} date date or null
 * @param {object | null} format null or object which specifies how given time is going to be formatted
 * @returns {string}
 */
export const dataToLocaleTimeStr = (date, format) => {
  if (!date) {
    return '';
  }
  if (format) {
    return date.toLocaleTimeString('sk-SK', format);
  } else {
    return date.toLocaleTimeString('sk-SK');
  }
};

/**
 * Returns date as formatted string, 
 * e.g., 2.6.2015(date) -> 02.06.2015 
 * @function dateToDateStr
 * @param {Date | null} date date or null
 * @returns {string}
 */export const dateToDateStr = date => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, 0);
  const day = `${date.getDate()}`.padStart(2, 0);
  return `${day}.${month}.${year}`;
};

/**
 * Returns date as formatted string, 
 * e.g., 2.6.2015(date) -> 02062015 
 * @function dateToDbStr
 * @param {Date | null} date date or null
 * @returns {string}
 */
export const dateToDbStr = date => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, 0);
  const day = `${date.getDate()}`.padStart(2, 0);
  return `${year}${month}${day}`;
};

/**
 * Returns date as formatted string, 
 * e.g., 2.6.2015(date) -> 02.06.2015 
 * @function dateToValidation
 * @param {Date | null} date date or null
 * @returns {string}
 */
export const dateToValidation = date => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = `${date.getMonth() + 1}`.padStart(2, 0);
  const day = `${date.getDate()}`.padStart(2, 0);
  return `${day}.${month}.${year}`;
};

/**
 * Returns date time as formatted string, 
 * e.g., 8:33:5(date) -> 08:33:05 
 * @function dateToValidation
 * @param {Date | null} date date or null
 * @returns {string}
 */
export const dateToTimeStr = date => {
  if (!date) {
    return '';
  }
  const hours = `${date.getHours()}`.padStart(2, 0);
  const minutes = `${date.getMinutes()}`.padStart(2, 0);
  const seconds = `${date.getSeconds()}`.padStart(2, 0);
  return `${hours}:${minutes}:${seconds}`;
};

/**
 * Returns date as formatted string, 
 * e.g., 2017-05-21T00:00:00 -> 21.05.2017 
 * @function convertDate
 * @param {string | null} timestamp date as string or null
 * @returns {string}
 */
export const convertDate = (timestamp) => {
  if (!timestamp) {
    return '';
  }

  const datetime = timestamp.split('T');
  const date = datetime[0].split('-');

  return date[2] + '.' + date[1] + '.' + date[0];
};

/**
 * Returns date as formatted string, 
 * e.g., 2017-05-21T08:33:05 -> 21.05.2017 8:33
 * @function convertDateTime
 * @param {string | null} timestamp date as string or null
 * @returns {string}
 */
export const convertDateTime = (timestamp) => {
  if (!timestamp) {
    return '';
  }

  const datetime = timestamp.split('T');
  const date = datetime[0].split('-');
  const time = datetime[1].split(':');

  return date[2] + '.' + date[1] + '.' + date[0] + ' ' + time[0] + ':' + time[1];
};

/**
 * Returns number as formatted string
 * e.g., 5 -> 05
 * @function checkZero
 * @param {number} input number
 * @returns {string}
 */
const checkZero = (input) => {
  return input < 10 ? '0' + input : input;
};

/**
 * Returns timestamp from date and time.
 * @function dateTimeToTimestamp
 * @param {Date} date date
 * @param {Date} time time
 * @returns {string}
 */
export const dateTimeToTimestamp = (date, time) => {
  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T'
    + checkZero(time.getHours()) + ':' + checkZero(time.getMinutes());
};

/**
 * Returns timestamp from date.
 * @function dateToTimestamp
 * @param {Date | null} date date or null
 * @returns {string}
 */
export const dateToTimestamp = (date) => {
  if (!date) {
    return '';
  }

  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate() + 'T'
    + checkZero(date.getHours()) + ':' + checkZero(date.getMinutes()) + ':' + checkZero(date.getSeconds());
};


/**
 * Returns a new string without character ".
 * @function escape
 * @param {string} input input string
 * @returns {string}
 */
export const escape = (input) => {
  return input.replace(/"/g, '');
};

/**
 * Returns a timestamp from string.
 * @function strToTimestamp
 * @param {string} str date as string
 * @returns {string}
 */
export const strToTimestamp = (str) => {
  if (!str) {
    return '';
  }

  const date = moment(str, 'D.M.YYYY', true);
  return dateToTimestamp(date.toDate());
};

/**
 * Returns a datestamp from date.
 * @function dateToDatestamp
 * @param {Date} date date
 * @returns {string}
 */
export const dateToDatestamp = (date) => {
  if (!date) {
    return '';
  }

  return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
};


/**
 * Returns a word with capitalized first letter.
 * @function toUpperCaseFirst
 * @param {string} word string input
 * @returns {string}
 */
export const toUpperCaseFirst = (word) => {
  let Txt = word ? word : '';
  Txt = Txt.substr(0, 1).toUpperCase() + Txt.substr(1);
  return Txt;
};

/**
 * Returns date with time set to zero.
 * e.g., 2017-05-21T08:33:05 -> Sun May 21 2017 00:00:00 GMT+0200 (Central European Summer Time)
 * @function dateTimeToDate
 * @param {Date} date date
 * @returns {Date}
 */
export const dateTimeToDate = (date) => {
  const d = new Date(date);
  d.setHours(0, 0, 0, 0);
  return d;
};

/**
 * Returns string date formated to RRRRMMDD.
 * e.g., 2017-05-21T08:33:05 -> Sun May 21 2017 00:00:00 GMT+0200 (Central European Summer Time)
 * @function dateTimeToRRRRMMDD
 * @param {Date} date date
 * @returns {string}
 */
export const dateTimeToRRRRMMDD = (date) => {
  const d = new Date(date);
  let mm = d.getMonth() + 1; // getMonth() is zero-based
  let dd = d.getDate();

  return [d.getFullYear(),
  (mm > 9 ? '' : '0') + mm,
  (dd > 9 ? '' : '0') + dd
  ].join('');
};

/**
 * Returns string date formated to RRRRMM.
 * e.g., 2017-05-21T08:33:05 -> Sun May 21 2017 00:00:00 GMT+0200 (Central European Summer Time)
 * @function dateTimeToRRRRMM
 * @param {Date} date date
 * @returns {string}
 */
export const dateTimeToRRRRMM = (date) => {
  const d = new Date(date);
  let mm = d.getMonth() + 1; // getMonth() is zero-based

  return [d.getFullYear(),
  (mm > 9 ? '' : '0') + mm
  ].join('');
};

/**
 * Returns date from specified string input.
 * e.g., 170521 -> Sun May 21 2017 00:00:00 GMT+0200 (Central European Summer Time)
 * @function rcToDnar
 * @param {string} rc string input
 * @returns {Date}
 */
export const rcToDnar = (rc) => {
  if (!rc)
    return null;

  rc = rc.trim();
  rc = rc.replace("/", "");

  if (rc.length < 9)
    return null;

  let year = Number(rc.substr(0, 2));

  if (year >= 54)
    year = 1900 + year;
  else {

    if (rc.length === 9)
      year = 1900 + year;
    else
      year = 2000 + year;

  }

  let month = Number(rc.substr(2, 2));

  if (month > 50)
    month = month - 50;

  if (month < 1 || month > 12)
    return null;

  let day = Number(rc.substr(4, 2));

  if (day < 1 || day > 31)
    return null;

  var dNar = new Date(year, month - 1, day);

  if (dNar >= new Date(1954, 0, 1) && rc.length < 10)
    return null;

  if (dNar > new Date())
    return null;

  return dNar;
};

/**
 * Returns numeric size in bytes
 * @function stringToBytes
 * @param {string} string string input
 * @returns {number}
 */
export const stringToBytes = (string) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  for (let i = 0; i < sizes.length; i++) {
    let cur = sizes[i];
    if (string.indexOf(cur) > -1) {
      const size = Number(string.split(sizes[i])[0]);
      return size * Math.pow(1024, i);
    }
  }
  return 0;
};

/**
 * Returns string size of numeric size
 * @function bytesToSize
 * @param {number} bytes number input
 * @returns {string}
 */
export const bytesToSize = (bytes) => {
  var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  if (bytes === 0) return '0 Byte';
  var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
  return Math.round(bytes / Math.pow(1024, i), 2) + ' ' + sizes[i];
};

/**
 * Returns File for url/base64 data
 * @function base64ToFile
 * @param {string} dataURL string input
 * * @param {string} filename string input
 * @returns {File}
 */
export const base64ToFile = (dataurl, filename) => {
  var arr = dataurl.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
};

/**
 * Returns Unicode string from base64
 * @param {string} base64String input base64 string
 * @returns {string} decoded unicode string
 */
export const base64ToUnicodeString = (base64String) => {
  return decodeURIComponent(window.atob(base64String).split('').map((c) => {
    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
  }).join(''));
};

/**
 * getDefaultExtension
 * Metóda vracajúca prípony podla contentType hlavičky
 * @param {string} contentType obsah v hlavicke  
 * @returns {string} prípona súbory aj s bodkou
 */
export const getDefaultExtension = (contentType) => {
  switch (contentType) {
    case "text/plain": return ".txt";
    case "text/html": return ".html";
    case "text/css": return ".css";
    case "text/xml": return ".xml";
    case "image/gif": return ".gif";
    case "image/jpeg": return ".jpg";
    case "image/png": return ".png";
    case "image/svg+xml": return ".svg";
    case "application/java-archive": return ".jar";
    case "application/msword": return ".doc";
    case "application/pdf": return ".pdf";
    case "application/postscript": return ".ps";
    case "application/rtf": return ".rtf";
    case "application/vnd.ms-excel": return ".xls";
    case "application/vnd.ms-powerpoint": return ".ppt";
    case "application/x-7z-compressed": return ".7z";
    case "application/zip": return ".zip";
    case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": return '.xlsx';
    case "application/vnd.openxmlformats-officedocument.wordprocessingml.document": return '.docx';
    case "application/vnd.openxmlformats-officedocument.presentationml.presentation": return ".pptx";
    case "application/vnd.openxmlformats-officedocument.presentationml.slideshow": return ".ppsx";
    default: return "";
  }
};
/**
 * Replace all spaces with non-breaking spaces (&nbsp;)
 *
 * @param {*} text
 * @return {*} 
 */
export const spacesToNbsp = (text) => {
  return text.replace(/ /g, '\u00A0');
}

/**
 * Convert url-safe base64 string to uint8 array
 * @param {*} base64String 
 * @returns 
 */
export const urlB64ToUint8Array = (base64String) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4);
  const base64 = (base64String + padding)
    //eslint-disable-next-line
    .replace(/\-/g, '+')
    .replace(/_/g, '/');

  const rawData = window.atob(base64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
}