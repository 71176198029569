import actionTypes from '../../actions/ActionTypes';

const _initState = { 
  isLoading: false, 
  data: [],
  page: 0,
  hasMore: true,
}

const parkingCards = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_CARDS_REQUEST:
      const data = action.page === 1 ? [] : [...state.data];
      return { ...state, data, isLoading: true };

    case actionTypes.GET_PARKING_CARDS_SUCCESS:
      const hasMore = action.res.length > 0;
      return { isLoading: false, data: [...state.data, ...action.res.Data], page: action.page, hasMore }

    case actionTypes.GET_PARKING_CARDS_FAILURE:
      return { ...state, isLoading: false };
    
      case actionTypes.CLEAR_PARKING_CARDS:
      return _initState;

    default:
      return state
  }
}

export default parkingCards;