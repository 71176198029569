import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Button from './Button';
import classNames from 'classnames';


const styles = theme => ({
  button: {
    marginTop: "10px",
    fontWeight: "bold",
    letterSpacing: '0.2rem'
  },
});

class RaisedButtonComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <Button
        className={classNames(classes.button, this.props.className)}
        label={this.props.label}
        fullwidth={this.props.fullwidth}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        style={this.props.style}
        mini={false}
        variant={this.props.variant ? this.props.variant : 'contained'}
        size='medium'
        color={this.props.color ? this.props.color : "secondary"}
        type='button'
      >
        {this.props.children}
      </Button >
    )
  }
}

RaisedButtonComponent.propTypes = {
  color: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  fullwidth: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  size: PropTypes.string,
  mini: PropTypes.bool,
  variant: PropTypes.string
}

export default withStyles(styles)(RaisedButtonComponent);