import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@mui/material/TextField'

class TextFieldComponent extends React.Component {
  onBlur = (event) => {
    if (this.props.onBlur) {
      this.props.onBlur(this.props.name, event.target.value);
    }
  }

  render() {
    return (
      <TextField
        id={this.props.id}
        label={this.props.label}
        type={this.props.type}
        value={this.props.value}
        onChange={event => this.props.onChange(this.props.name, event.target.value)}
        onBlur={this.onBlur}
        margin={this.props.margin}
        style={this.props.style}
        helperText={this.props.errorText}
        error={this.props.error}
        fullWidth={this.props.fullwidth}
        disabled={this.props.disabled}
        multiline={this.props.multiLine}
        rows={this.props.rows}
        minRows={this.props.minRows}
        maxRows={this.props.maxRows}
        variant={this.props.variant}
        InputProps={this.props.imputProps}
        InputLabelProps={this.props.inputLabelProps}
        inputProps={this.props.inputProps}
        onKeyPress={this.props.onKeypress}
        className={this.props.className}
        classes={this.props.classes}
      />
    )
  }
}

TextFieldComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  margin: PropTypes.string,
  style: PropTypes.object,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  multiLine: PropTypes.bool,
  rows: PropTypes.number,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string,
  imputProps: PropTypes.object,
  inputLabelProps: PropTypes.object,
  onKeypress: PropTypes.func,
  className: PropTypes.string,
  classes: PropTypes.object,
  inputProps: PropTypes.object,
}

export default TextFieldComponent;