import actionTypes from '../../actions/ActionTypes';

const _initState = { 
  isLoading: false, 
  data: null,
  status: null,
  i_fe_reg_log: null
}

const vehicle = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_EVO_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_EVO_SUCCESS:
      return { isLoading: false, data: action.res.VOZIDLO, status: action.res.MSG, i_fe_reg_log: action.res.I_FE_REG_LOG }

    case actionTypes.GET_EVO_FAILURE:
      return _initState;
  
    default:
      return state
  }
}

export default vehicle;