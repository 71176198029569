import { showRequestFailedDialog } from '../actions/ErrorActions';

const checkRequestFailtures = (store, action)=> {
    if(action.type.indexOf('FAILURE') > -1 
    && action.type.indexOf('FAILURE_DIALOG') === -1) {
        store.dispatch(showRequestFailedDialog(action.error.message))
    }
};

const errorHandler =  store => next => action => {
    checkRequestFailtures(store, action);
    let result = next(action);
    return result;
}
  
export default errorHandler;