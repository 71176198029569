import React from 'react';
import get from 'lodash/get';
import ReactHtmlParser from 'react-html-parser';

import * as requests from '../utils/requests';
import sk from './sk.json';
import en from './en.json';
import de from './de.json';

const Context = React.createContext();

class LocalizationProviderWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.locales = {
      'en-US': { ...en, ...this.props.en },
      'sk-SK': { ...sk, ...this.props.sk },
      'de-DE': { ...de, ...this.props.de }
    };
  }

  translate = locale => key => {
    return get(this.locales[locale], key);
  };

  translateWithLocale = (locale, key) => {
    return this.translate(locale)(key);
  };

  translateAsync = locale => async key => {
    try {
      const url = `/api/meta/localization?key=${key}&locale=${locale}`;

      const txt = await requests.get(url);
      if (txt.Data && txt.Data.length > 0) {
        return ReactHtmlParser(txt.Data[0].TxtFrm);
      }

      return '';
    }
    catch (error) {
      return '';
    }
  };

  translateWithLocaleAsync = (locale, key) => {
    return this.translateAsync(locale)(key);
  };

  render() {
    return (
      <Context.Provider
        value={{
          locale: this.props.locale,
          translate: this.translate(this.props.locale),
          translateAsync: this.translateAsync(this.props.locale),
          translateWithLocale: this.translateWithLocale,
          translateWithLocaleAsync: this.translateWithLocaleAsync
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const withLocalizationConsumer = (WrappedComponent) => {
  return (props) => {
    return (
      <Context.Consumer>
        {x =>
          <WrappedComponent
            {...props}
            {...x}
          />
        }
      </Context.Consumer>
    );
  };
};

export default LocalizationProviderWrapper;