import React from 'react';
import PropTypes from 'prop-types';

const style = {
  position: 'fixed',
  left: 0,
  bottom: 0,
  width: '100%',
  backgroundColor: 'rgb(224, 224, 224)',
  color: 'rgba(0, 0, 0, 0.87)',
  fontFamily: 'Roboto, sans-serif',
  textAlign: 'center',
  zIndex: 99,
  padding: 8
};

/**
 * Info component
 * @module
 * @param {string} [text] - Footer text
 */
class Info extends React.Component {
  render() {
    if(this.props.text)
      return (
        <div style={style}>
          {this.props.text}
        </div>
      );
    else
      return null;
  }
}

Info.propTypes = {
  text: PropTypes.string,
}

export default Info;