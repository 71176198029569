import { useState, useEffect, createContext, forwardRef } from 'react';
import { addTextField, removeTextField, parseValue, fieldFormat } from './helper';
import * as requests from './requests';
import CoraError from './coraError';

export const MetaContext = createContext({});

export const MetaProvider = props => {
  const [menu, setMenu] = useState([]);
  const [menuTree, setMenuTree] = useState({ children: [] });
  const [menuError, setMenuError] = useState(false);

  /** Stav Offline mode alebo Maintenance mode */
  const [isAppOffline, setIsAppOffline] = useState(false);

  useEffect(() => {
    const initLoginConfig = async () => {
      await requests.loadLoginConfig();
    };
    initLoginConfig();
  }, []);

  const init = async (isPublic = false, applicationId = null) => {
    await initMenu(isPublic, applicationId);
  };

  const initMenu = async (isPublic = false, applicationId = null) => {
    const menu = await getMenu(isPublic, applicationId);
    const menuTree = getPreprocessedMenuData(menu.find(x => x.NadMenuID === null), menu.filter(x => x.Visible !== 3));
    setMenu(menu);
    setMenuTree(menuTree);
  };

  const clearMenu = () => {
    setMenu([]);
    setMenuTree({ children: [] });
  };

  const getMenu = async (isPublic, applicationId = null) => {
    try {
      let url = isPublic ? "/api/meta/menuPublic" : "/api/meta/menu";
      if (applicationId) {
        url = url + `/${applicationId.toString()}`;
      }
      const menu = await requests.get(url);
      setMenuError(menu.Data.length === 0 ? "Nemáte priradenú žiadnu položku menu. Kontaktujte správcu." : null);
      return menu.Data;
    }
    catch (error) {
      setMenuError("Neočakávaná chyba pri získavaní menu.");
      return [];
    }
  };

  const updateHomePage = async (id) => {
    const url = `/api/meta/home-page/${id}`;

    try {
      await requests.put(url);
    }
    catch (error) {
      console.error(error);
    }
  };

  const getList = async (primaryField, filter, sort, page, db = null) => {
    const url = `/api/meta/form/${primaryField}`;
    const args = db ? { db } : null;
    try {
      let res = await requests.getList(url, filter, sort, page, args);
      return res;
    }
    catch (error) {
      return {
        Data: {
          Items: [],
          TotalRecords: 0
        }
      };
    }
  };

  const getTotal = async (primaryField, filter, db = null) => {
    const url = `/api/meta/form/${primaryField}/total-records`;
    const args = db ? { db } : null;
    try {
      let res = await requests.getTotal(url, filter, args);
      return res;
    }
    catch (error) {
      return {
        Data: {
          TotalRecords: null
        }
      };
    }
  };

  const getTabs = async (primaryField, db = null) => {
    let url = `/api/meta/form/${primaryField}/tabs`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.get(url);
      return res?.Data;
    }
    catch (error) {
      throw error;
    }
  };

  const getPageNumber = async (primaryField, id, filter, sort, page, db = null) => {
    const url = `/api/meta/form/${primaryField}/${id}/page-number`;
    const args = db ? { db } : null;
    try {
      let res = await requests.getList(url, filter, sort, page, args);
      return res;
    }
    catch (error) {
      return {
        Data: {
          Page: 0
        }
      };
    }
  };

  const get = async (primaryField, id, db = null) => {
    let url = `/api/meta/form/${primaryField}/${id}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.get(url);
      return res;
    }
    catch (error) {
      throw error;
    }
  };

  const create = async (primaryField, data, db = null) => {
    let url = `/api/meta/form/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.post(url, data);
      return res;
    }
    catch (error) {
      throw error;
    }
  };

  const update = async (primaryField, id, data, db = null) => {
    let url = `/api/meta/form/${primaryField}/${id}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.put(url, data);
      return res;
    }
    catch (error) {
      throw error;
    }
  };

  //delete je blokované slovo
  const deleteItem = async (primaryField, id, db = null) => {
    let url = `/api/meta/form/${primaryField}/${id}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.delWithResData(url);
      return res;
    }
    catch (error) {
      throw error;
    }
  };

  const getSchema = async (primaryField, db = null) => {
    let url = `/api/meta/schema/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.get(url);

      res.Data.Detail = getValueField(res.Data.Detail, true);
      res.Data.Grid = getValueField(res.Data.Grid, false);

      return res.Data;
    }
    catch (error) {
      if (error instanceof CoraError && error.code === "503") {
        await checkAppOffline();
      }
      return null;
    }
  };

  const updateSchema = async (primaryField, schema, db = null) => {
    let url = `/api/meta/schema/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {

      await requests.put(url, schema.Grid);
    }
    catch (error) {

    }
  };

  const updateUI = async (primaryField, schema, db = null) => {
    let url = `/api/meta/schema/${primaryField}/UI`;
    if (db) url = url + `?db=${db}`;

    try {
      await requests.put(url, schema);
    }
    catch (error) {
      throw error;
    }
  }

  const getSort = async (primaryField, schema, db = null) => {
    let url = `/api/meta/sort/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requests.get(url);
      if (res && res.Data) {
        res.Data.map(item => {
          const col = schema.find(el => el.field === item.field);
          if (col.filter === "container") {
            item.field = `${item.field}.${col.textField}`;
          }
          return item;
        });
        return res.Data;
      }
      return [];
    }
    catch (error) {
      return [];
    }
  };

  const updateSort = async (primaryField, sort, db = null) => {
    let url = `/api/meta/sort/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {
      await requests.put(url, sort);
    }
    catch (error) {
    }
  };

  const createFilter = async (primaryField, filter, schema, iUz = null, db = null) => {
    let url = `/api/meta/user-filters/${primaryField}?iUz=${iUz}`;
    if (db) url = url + `&db=${db}`;
    let newFilter = filter;
    newFilter.filters = removeTextField(newFilter.filters, schema);
    try {
      await requests.post(url, newFilter);
    }
    catch (error) {

    }
  };

  const updateFilter = async (primaryField, id, disabled, type, iUz = null, db = null) => {
    let url = `/api/meta/user-filters/${primaryField}/${id}?disabled=${disabled}&type=${type}&iUz=${iUz}`;
    if (db) url = url + `&db=${db}`;
    try {
      await requests.put(url);
    }
    catch (error) {
      console.error(error);
    }
  };

  const setUzPravaPrj = (data, db = null) => {
    let url = `/api/meta/uz-prava-prj`;
    if (db) url = url + `?db=${db}`;
    return requests.put(url, data);
  };

  const setPravaPrj = (data, db = null) => {
    let url = `/api/meta/prava-prj`;
    if (db) url = url + `?db=${db}`;
    return requests.put(url, data);
  };

  const setPravaUz = (data, db = null) => {
    let url = `/api/meta/prava-uz`;
    if (db) url = url + `?db=${db}`;
    return requests.put(url, data);
  };

  const setSeq = (nSeq) => {
    const url = `/api/meta/seq?nSeq=${nSeq}`;
    return requests.put(url);
  };

  const setPravaSkup = (data, db = null) => {
    let url = `/api/meta/prava-skup`;
    if (db) url = url + `?db=${db}`;
    return requests.put(url, data);
  };

  const deleteFilter = async (primaryField, id, iUz = null, db = null) => {
    let url = `/api/meta/user-filters/${primaryField}/${id}?iUz=${iUz}`;
    if (db) url = url + `&db=${db}`;
    try {
      await requests.del(url);
    }
    catch (error) {
      throw error;
    }
  };

  const deleteSchema = async (primaryField, value, db = null) => {
    let url = `/api/meta/schema/${primaryField}?value=${value}`;
    if (db) url = url + `&db=${db}`;

    try {
      await requests.del(url);
    }
    catch (error) {
    }
  };

  const postPrint = async (primaryField, filter, sort, fileType, schema, db = null) => {
    const url = `/api/meta/print/${primaryField}`;

    try {
      let res = await requests.postPrint(url, filter, sort, fileType, schema, db);
      return res;
    }

    catch (error) { }
  };

  const postPrintNoMeta = async (primaryField, filter, sort, fileType, schema, formName, formType, iZiadost) => {
    const url = `/api/meta/noMetaPrint`;

    try {
      let res = await requests.postPrintNoMeta(url, filter, sort, fileType, schema, formName, formType, iZiadost);
      return res;
    }

    catch (error) { }
  };

  const getAkc = async (id, pod, db = null) => {
    let url = `/api/meta/akc?id=${id}&pod=${pod}`;
    if (db) url = url + `&db=${db}`;

    try {
      let res = await requests.get(url);
      return res.Data;
    }
    catch (error) {
      return {
        hidden: 0,
        disabled: 0,
        akc: []
      };
    }
  };

  const getAkcByPrimaryField = async (primaryField, pod, db = null) => {
    let url = `/api/meta/akc?primaryField=${primaryField}&pod=${pod}`;
    if (db) url = url + `&db=${db}`;

    try {
      let res = await requests.get(url);
      return res.Data;
    }
    catch (error) {
      return {
        hidden: 0,
        disabled: 0,
        akc: []
      };
    }
  };

  const getApl = async (applicationId = null) => {
    try {
      let url = "/api/meta/apps";
      if (applicationId) {
        url = url + `/${applicationId.toString()}`;
      }
      const apps = await requests.get(url);
      return apps.Data;
    }
    catch (error) {
      return [];
    }
  };

  const getFilters = async (primaryField, schema, iUz = null, db = null) => {
    let url = `/api/meta/filters/${primaryField}?iUz=${iUz}`;
    if (db) url = url + `&db=${db}`;
    try {
      let filters = await requests.get(url);
      if (filters && filters.Data) {
        return filters.Data.map(f => ({
          ...f,
          filters: addTextField(f.filters, schema).map(x => {
            return {
              field: x.field,
              operator: x.operator,
              value: x.operator === 'in' ? x.value?.split(",").map(s => { return parseValue(s, x.type); }).join() : parseValue(x.value, x.type),
              left: x.left,
              right: x.right,
              join: x.join,
              order: x.order,
              frmPod: x.frmPod,
              parentField: x.parentField,
              childField: x.childField,
              filterId: f.id,
              filterType: f.type
            };
          })
        }));
      }

      return [];
    }
    catch (error) {
      if (error instanceof CoraError && error.code === "503") {
        await checkAppOffline();
      }
      return [];
    }
  };

  const getSprav = async (db = null) => {
    let url = `/api/meta/sprav`;
    if (db) url = url + `?db=${db}`;
    try {
      let res = await requests.get(url);
      return res.Data;
    }
    catch (error) {
      return [];
    }
  };

  const getUser = async (db = null) => {
    let url = `/api/meta/user`;
    if (db) url = url + `?db=${db}`;
    try {
      let res = await requests.get(url);
      return res.Data;
    }
    catch (error) {
      return [];
    }
  };

  const getTlac = async (primaryField) => {
    let url = `/api/meta/tlac/${primaryField}`;
    try {
      let res = await requests.get(url);
      return res?.Data;
    }
    catch (error) {
      return null;
    }
  };

  const postTlac = async (primaryField, tlac, target) => {
    let url = `/api/meta/tlac/${primaryField}`;
    if (target) {
      url = url + `?target=${target}`;
    }
    try {
      let res = await requests.postTlac(url, tlac);
      return res;
    }
    catch (error) {
    }
  };

  const getDef = async (iDefHl, defOb, iUz = null, iOrg = null, db = null) => {
    const urlParams = new URLSearchParams(window.location.search);
    iUz && urlParams.set('iUz', iUz);
    iOrg && urlParams.set('iOrg', iOrg);
    db && urlParams.set('db', db);
    const params = urlParams.toString() ? `?${urlParams.toString()}` : "";

    let url = `/api/meta/def/${iDefHl}/${defOb}${params}`;
    try {
      let res = await requests.get(url);
      return res?.Data?.Value;
    }
    catch (error) {
      return null;
    }
  };

  const getDashboard = async (iPrehl, params = null, duration = null, db = null) => {
    const urlParams = new URLSearchParams(window.location.search);
    duration && urlParams.set('durationDays', duration);
    db && urlParams.set('db', db);
    let url = `/api/meta/dashboard/${iPrehl}${(urlParams.toString() ? `?${urlParams.toString()}` : "")}`;
    try {
      let res = await requests.post(url, params);
      if (duration) {
        url = url + `?durationDays=${duration}`;
      }
      return res?.Data;
    }
    catch (error) {
      return null;
    }
  };

  const get2faQR = async () => {
    try {
      let res = await requests.get("/api/Mfa",);
      return res?.Data;
    }
    catch (error) {
      return null;
    }
  }

  const enable2fa = async (code, enable) => {
    try {
      let res = await requests.put("/api/Mfa", { code: code, enable: enable });
      console.log("enable2fa ~ res:", res)
      return res;
    }
    catch (error) {
      return null;
    }
  }

  /** Overenie Offline mode alebo Maintenance mode */
  const checkAppOffline = async () => {
    try {
      if (String(window?.config?.appOffline)?.trim()?.toLowerCase() === "true") {
        setIsAppOffline(true);
        return;
      }
      if (String(window?.config?.isAppOfflineCheckRequired)?.trim()?.toLowerCase() === "true") {
        const res = await requests.getAppOffline();
        setIsAppOffline(res?.Data ?? false);
      }
    } catch (error) {
    }
  };

  useEffect(() => {
    checkAppOffline();
  }, []);

  return (
    <MetaContext.Provider
      value={{
        /**
         * Init
         * @function init
         * @param {element} [isPublic] - is public
         */
        init: init,
        /**
         * Init menu
         * @function initMenu
         * @param {element} [isPublic] - is public
         */
        initMenu: initMenu,
        /**
         * Clear menu
         * @function clearMenu
         */
        clearMenu: clearMenu,
        /**
         * Menu
         */
        menu: menu,
        /**
         * Menu tree with children
         */
        menuTree: menuTree,
        /**
         * Error message after getting menu
         */
        menuError: menuError,
        /** Stav Offline mode alebo Maintenance mode */
        isAppOffline: isAppOffline,
        /** Overenie Offline mode alebo Maintenance mode */
        checkAppOffline: checkAppOffline,
        /**
         * Asynchronously update Home page.
         * @function updateHomePage
         * @param {number} id id
         */
        updateHomePage: updateHomePage,
        /**
         * Asynchronously returns response from get list of data.
         * @function getList
         * @param {string} primaryField Primary field
         * @param {object} filter filter
         * @param {object} sort sort
         * @param {object} page page
         * @param {string} [db] db
         * @returns {Object}
         */
        getList: getList,
        /**
         * Asynchronously returns response from get Total of data.
         * @function getList
         * @param {string} url url
         * @param {object} filter filter
         * @param {object} args aditional arguments
         * @returns {Object}
         */
        getTotal: getTotal,
        /**
         * Asynchronously returns tabs for specific form
         * @function getList
         * @param {string} primaryField Primary field
         * @param {string} [db] db
         * @returns {Object}
         */
        getTabs: getTabs,

        /**
         * Asynchronously returns response from get item.
         * @function get
         * @param {string} primaryField Primary field
         * @param {string} id Item id
         * @param {string} [db] db
         * @returns {Object}
         */
        get: get,
        /**
         * Asynchronously returns response from crete item.
         * @function create
         * @param {string} primaryField Primary field
         * @param {object} data data
         * @param {string} [db] db
         * @returns {Object}
         */
        create: create,
        /**
         * Asynchronously returns response from update item.
         * @function update
         * @param {string} primaryField Primary field
         * @param {string} id id
         * @param {object} data data
         * @param {string} [db] db
         * @returns {Object}
         */
        update: update,
        /**
         * Asynchronously deletes item.
         * @function delete
         * @param {string} primaryField Primary field
         * @param {string} id id
         * @param {string} [db] db
         * @returns {Object}
         */
        delete: deleteItem,
        /**
         * Asynchronously returns schema.
         * @function getSchema
         * @param {string} primaryField Primary field
         * @param {string} [db] db
         * @returns {Object}
         */
        getSchema: getSchema,
        /**
         * Asynchronously updates schema.
         * @function updateSchema
         * @param {string} primaryField Primary field
         * @param {object} schema Schema
         * @param {string} [db] db
         */
        updateSchema: updateSchema,
        updateUI: updateUI,
        /**
         * Asynchronously returns sort.
         * @function getSort
         * @param {string} primaryField Primary field
         * @param {string} [db] db
         * @returns {Object}
         */
        getSort: getSort,
        /**
         * Asynchronously updates sort.
         * @function updateSort
         * @param {string} primaryField Primary field
         * @param {object} sort sort
         * @param {string} [db] db
         */
        updateSort: updateSort,
        /**
         * Asynchronously posts print file.
         * @function postPrint
         * @param {string} primaryField Primary field
         * @param {object} filter filter
         * @param {object} sort sort
         * @param {object} fileType fileType
         * @param {object} schema schema
         * @param {string} [db] db
         * @returns {Object}
         */
        postPrint: postPrint,
        /**
         * Asynchronously returns response from get action. 
         * @function getTlac
         * @param {string} primaryField Primary field
         * @returns {Object}
         */
        getTlac: getTlac,
        /**
         * Asynchronously returns printed file. 
         * @function postTlac
         * @param {string} primaryField Primary field
         * @param {object} tlac Print request
         * @returns {Object}
         */
        postTlac: postTlac,
        /**
         * Asynchronously posts print file.
         * @function postPrint
         * @param {string} primaryField Primary field
         * @param {object} filter filter
         * @param {object} sort sort
         * @param {object} fileType fileType    
         * @param {object} schema schema
         * @param {string} formName name of form
         * @returns {Object}
         */
        postPrintNoMeta: postPrintNoMeta,
        /**
         * Asynchronously delete schema.
         * @function deleteSchema
         * @param {string} primaryField Primary field
         * @param {number} value Value
         * @param {string} [db] db
         */
        deleteSchema: deleteSchema,
        /**
         * Asynchronously create filter.
         * @function createFilter
         * @param {string} primaryField Primary field
         * @param {object} filter filter
         * @param {number} [iUz] iUz
         * @param {string} [db] db
         */
        createFilter: createFilter,
        /**
         * Asynchronously update filter.
         * @function updateFilter
         * @param {string} primaryField Primary field
         * @param {number} id id
         * @param {bool} disabled disabled
         * @param {number} type type
         * @param {number} [iUz] iUz
         * @param {string} [db] db
         */
        updateFilter: updateFilter,
        /**
         * Asynchronously delete filter.
         * @function deleteFilter
         * @param {string} primaryField Primary field
         * @param {string} id id
         * @param {number} [iUz] iUz
         * @param {string} [db] db
         */
        deleteFilter: deleteFilter,
        /**
         * Asynchronously returns response from get action. 
         * @function getAkc
         * @param {string} primaryField Primary field
         * @param {string} [db] db
         * @returns {Object}
         */
        getAkc: getAkc,
        getAkcByPrimaryField: getAkcByPrimaryField,
        /**
         * Asynchronously returns response from get action. 
         * @function getApl
         * @param {number} applicationId Id of current application from AA_APL
         * @returns {Object} 
         */
        getApl: getApl,
        /**
         * Get filter function
         * @function getFilters
         * @param {string} primaryField Primary field
         * @param {number} [iUz] iUz
         * @param {string} [db] db
         * @returns {object} Filter
         */
        getFilters: getFilters,
        /**
         * Set user permissions
         * @function setUzPravaPrj
         * @param {object} data data
         * @param {string} [db] db
         */
        setUzPravaPrj: setUzPravaPrj,
        /**
         * Set user permissions
         * @function setPravaPrj
         * @param {object} data data
         * @param {string} [db] db
         */
        setPravaPrj: setPravaPrj,
        /**
         * Set user permissions
         * @function setPravaUz
         * @param {object} data data
         * @param {string} [db] db
         */
        setPravaUz: setPravaUz,
        /**
         * Set user permissions
         * @function setPravaSkup
         * @param {object} data data
         * @param {string} [db] db
         */
        setPravaSkup: setPravaSkup,
        /**
         * Set seq
         * @function setSeq
         * @param {string} nSeq Seq
         * @param {string} [db] db
         */
        setSeq: setSeq,
        /**
         * Get sprav
         * @function getSprav
         * @param {string} [db] db
         */
        getSprav: getSprav,
        /**
         * Get user
         * @function getSprav
         * @param {string} [db] db
         */
        getUser: getUser,
        /**
         * Asynchronously returns page number
         * @function getPageNumber
         * @param {string} primaryField Primary field
         * @param {number} id Id
         * @param {object} filter filter
         * @param {object} sort sort
         * @param {object} page page
         * @param {string} db db
         * @returns {Object} page
         */
        getPageNumber: getPageNumber,
        /**
         * Get def
         * @function getDef
         * @param {number} iDefHl iDefHl
         * @param {number | string} defOb iDefHl | sNDefOb
         * @param {string} [iUz] iUz
         * @param {string} [iOrg] iOrg
         * @param {string} [db] db
         */
        getDef: getDef,

        /**
         * Get metabase Dashboard
         * @function getDashboard
         * @param {number} [iPrehl] id dashboardu
         * @param {object} [params] parametre pre filter v ramci tokenu
         * @param {number} [duration] dlzka platnosti tokenu v dnoch
         * @param {string} [db] db
         */
        getDashboard: getDashboard,

        get2faQR: get2faQR,
        enable2fa: enable2fa,
      }} >
      {props.children}
    </MetaContext.Provider>
  );
};

/***
 * @function menuItemHasChildren - Find out if the @param menuItem has children
 * @param menuItem - Menu item
 * @returns {bool} - true or false
 */
const menuItemHasChildren = (menuItem) => {
  return menuItem.children && menuItem.children.length > 0;
};

/***
 * @function getPreprocessedMenuData - From @param rootMenuItem creates a new menu data structure usable for rendering
 * @param rootMenuItem - Root menu item
 * @param menu - menu
 * @returns @var menuItem - Menu item with children
 */
export const getPreprocessedMenuData = (rootMenuItem, menu) => {
  let menuItem = { ...rootMenuItem };

  menuItem.children = menu.filter(subMenuItem => subMenuItem.NadMenuID === menuItem.MenuID);
  if (!menuItemHasChildren(menuItem)) {
    return menuItem;
  }

  for (let i = 0; i < menuItem.children.length; i++) {
    menuItem.children[i] = getPreprocessedMenuData(menuItem.children[i], menu);
  }

  menuItem.children = menuItem.children.sort((menuItem1, menuItem2) => menuItem1.Pozicia - menuItem2.Pozicia);
  return menuItem;
};

/***
 * @function getValueField - Add valueField to schema
 * @param data - Schema
 * @returns - Schema with valueField
 */
export const getValueField = (data, l_detail) => {
  data.map(item => {
    const toReplace = item.textField && item.textField.match(/{([^}]+)}/g);
    if (toReplace) {
      item.valueField = item.textField;
      item.textField = toReplace[0].replace(/[{}]/g, "");
    }

    if (l_detail) { item.format = fieldFormat(item.format); }

    if (item.schema) {
      item.schema = getValueField(item.schema);
    }

    if (!item.hasOwnProperty('OrderFilter') && item.hasOwnProperty('orderFilter'))
      Object.defineProperty(item, 'OrderFilter', { get() { return this.orderFilter; }, set(val) { this.orderFilter = val; } });
    if (!item.hasOwnProperty('Filterable') && item.hasOwnProperty('filterable'))
      Object.defineProperty(item, 'Filterable', { get() { return this.filterable; }, set(val) { this.filterable = val; } });
    if (!item.hasOwnProperty('OrderSort') && item.hasOwnProperty('orderSort'))
      Object.defineProperty(item, 'OrderSort', { get() { return this.orderSort; }, set(val) { this.orderSort = val; } });
    if (!item.hasOwnProperty('Sortable') && item.hasOwnProperty('sortable'))
      Object.defineProperty(item, 'Sortable', { get() { return this.sortable; }, set(val) { this.sortable = val; } });
    if (!item.hasOwnProperty('FilterTitle') && item.hasOwnProperty('filterTitle'))
      Object.defineProperty(item, 'FilterTitle', { get() { return this.filterTitle; }, set(val) { this.filterTitle = val; } });
    if (!item.hasOwnProperty('TrueText') && item.hasOwnProperty('trueText'))
      Object.defineProperty(item, 'TrueText', { get() { return this.trueText; }, set(val) { this.trueText = val; } });
    if (!item.hasOwnProperty('FalseText') && item.hasOwnProperty('falseText'))
      Object.defineProperty(item, 'FalseText', { get() { return this.falseText; }, set(val) { this.falseText = val; } });
    if (!item.hasOwnProperty('Identif') && item.hasOwnProperty('identif'))
      Object.defineProperty(item, 'Identif', { get() { return this.identif; }, set(val) { this.identif = val; } });
    if (!item.hasOwnProperty('Hidden') && item.hasOwnProperty('hidden'))
      Object.defineProperty(item, 'Hidden', { get() { return this.hidden; }, set(val) { this.hidden = val; } });

    if (!item.hasOwnProperty('Args') && item.hasOwnProperty('args'))
      Object.defineProperty(item, 'Args', { get() { return this.args; }, set(val) { this.args = val; } });
    if (!item.hasOwnProperty('EnumData') && item.hasOwnProperty('enumData'))
      Object.defineProperty(item, 'EnumData', { get() { return this.enumData; }, set(val) { this.enumData = val; } });

    if (!item.hasOwnProperty('IMriez') && item.hasOwnProperty('iMriez'))
      Object.defineProperty(item, 'IMriez', { get() { return this.iMriez; }, set(val) { this.iMriez = val; } });
    if (!item.hasOwnProperty('IDet') && item.hasOwnProperty('iDet'))
      Object.defineProperty(item, 'IDet', { get() { return this.iDet; }, set(val) { this.iDet = val; } });
    if (!item.hasOwnProperty('IHlad') && item.hasOwnProperty('iHlad'))
      Object.defineProperty(item, 'IHlad', { get() { return this.iHlad; }, set(val) { this.iHlad = val; } });
    if (!item.hasOwnProperty('IZorad') && item.hasOwnProperty('iZorad'))
      Object.defineProperty(item, 'IZorad', { get() { return this.iZorad; }, set(val) { this.iZorad = val; } });
    return item;
  });
  return data;
};

/**
 * Returns wrapped component with meta functions
 * @module
 * @param {element} WrappedComponent - Wrapped component
 */
const withMeta = (WrappedComponent) => forwardRef((props, ref) => (
  <MetaContext.Consumer>
    {x => <WrappedComponent
      ref={ref}
      {...props}
      meta={x}
    />
    }
  </MetaContext.Consumer>
));

export default withMeta;