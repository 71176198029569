import React from 'react';
import PropTypes from 'prop-types';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import TextField from '../../coraWebMComponents/inputs/TextField';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Button from '../../coraWebMComponents/inputs/Button';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import AppBar from '../appBar/AppBarDrawer';
import Error from '../../coraWebMComponents/feedBack/Error';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import List from '../../coraWebMComponents/dataDisplay/List';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import CheckBox from '../../coraWebMComponents/inputs/CheckBox';
import { connect } from 'react-redux';
import * as trespassActions from '../../actions/TrespassActions';
import * as streetActions from '../../actions/StreetActions';
import * as partTownActions from '../../actions/PartTownActions';
import * as parkingActions from '../../actions/ParkingActions';
import * as userActions from '../../actions/UserActions';
import * as personActions from '../../actions/PersonAction';
import * as validator from '../../coraWebMComponents/utils/validator';
import NumberMoneyInput from '../../coraWebMComponents/inputs/NumberMoneyInput';
import * as evoActions from '../../actions/EvoActions';
import routes from '../../routes';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _initState = {
  isLoad: false,
  ecv: '',
  errorTextEcv: '',
  errorTextEvoEcv: '',
  errorTextVin: '',
  errorTextReason: '',
  errorTextRc: '',
  errorTextId: '',
  errorTextPokuta: '',
  errorTextStreet: '',
  errorTextBlocksRequired: '',
  errorTextBlocksOrder: '',
  errorTextBlockOne: '',
  errorTextDescription: '',
  errorTextPozn: '',
  rc: '',
  name: '',
  surname: '',
  street: null,
  partTown: null,
  description: '',
  pozn: '',
  images: [],
  errorText: '',
  date: new Date(),
  time: new Date(),
  openAlert: false,
  prescripts: [],
  prescriptId: null,
  solutions: [],
  solutionId: null,
  IDNumber: '',
  L_BL: false,
  I_M: 0,
  I_O_TYP: 1,
  I_HLAS_PR: null,
  BLOKY: [],
  ZVAZKY: [],
  POKUTA: 0,
  L_REQUIRED_BLOCKS: 0,
  L_ORDER_BLOCKS: 0,
  L_ACCESS_PERSON: 0,
  EDITABLE_FIELDS: 0,
  I_UDAL_DR: null,
  I_FE_REG_LOG: null,
  visibleEvo: false,
  openEvo: false,
  vin: '',
  reason: '',
  person: { I_O: 0, OSOBA: '' }
};

class TrespassAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  }

  componentDidMount = async () => {
    this.setState({ isLoad: true });
    await this.props.dispatch(trespassActions.getPrescripts(this.state.I_UDAL_DR, this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getEventTypes(this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getSolutions(0, this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getRequiredBlocks(this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getOrderBlocks(this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getAccessPerson(this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getEditableFields(this.props.meta.checkAppOffline));
    const L_ACCESS_PERSON = parseInt(this.props.phAccessPerson.HODN);

    const state = this.props.location.state;
    if (state) {
      this.setState((oldState) => {
        return {
          ...oldState,
          ...state
        };
      });

      if (state.rc && L_ACCESS_PERSON === 1) {
        await this.props.dispatch(personActions.getPersonList(state.rc, window.config.autocompleteCountCharStart_person || 6, this.props.meta.checkAppOffline));
        let iO = 0;
        if (this.props.personList.length === 2)
          iO = this.props.personList[1].I_O;

        this.setState({ person: { I_O: iO, OSOBA: state.rc } });
      }
    }
    const visibleEvo = this.props.user.Groups.find(x => (x === 1700 || x === 1705)) ? true : false;
    this.setState({
      isLoad: false,
      EDITABLE_FIELDS: parseInt(this.props.isEditableFields.HODN),
      L_REQUIRED_BLOCKS: parseInt(this.props.phRequiredBlocks.HODN),
      L_ORDER_BLOCKS: parseInt(this.props.phOrderBlocks.HODN),
      L_ACCESS_PERSON,
      visibleEvo,
      date: new Date(),
      time: new Date(),
    });
  };

  handleOpenAlert = () => {
    this.setState({ openAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleEvoOpen = () => {
    this.setState({ openEvo: true });
  };

  handleEvoClose = () => {
    this.setState({
      openEvo: false,
      errorTextEvoEcv: '',
      errorTextVin: '',
      errorTextReason: '',
    });
  };

  addTrespass = async () => {
    this.handleCloseAlert();
    this.setState({ isLoad: true });
    const hlasenie = this.state.I_HLAS_PR ? true : false;
    const visibleEvo = this.state.visibleEvo;
    const L_REQUIRED_BLOCKS = this.state.L_REQUIRED_BLOCKS;
    const L_ORDER_BLOCKS = this.state.L_ORDER_BLOCKS;
    const L_ACCESS_PERSON = this.state.L_ACCESS_PERSON;
    const date = this.state.date;
    const time = this.state.time;

    if (await this.props.dispatch(trespassActions.addTrespass(this.state, this.props.meta.checkAppOffline))) {
      if (hlasenie)
        this.props.navigate(routes.MAP_REPORT);
      else if (this.state.EDITABLE_FIELDS == 1) {
        this.setState((oldState) => { return { ...oldState, ..._initState }; });
        this.setState({ L_REQUIRED_BLOCKS, L_ORDER_BLOCKS, L_ACCESS_PERSON, visibleEvo, EDITABLE_FIELDS: 1, date, time });
      } else {
        this.setState((oldState) => { return { ...oldState, ..._initState }; });
        this.setState({ L_REQUIRED_BLOCKS, L_ORDER_BLOCKS, L_ACCESS_PERSON, visibleEvo, date: new Date(), time: new Date() });
      }
    }
    else
      this.setState({ isLoad: false });

  };

  handleAdd = () => {
    const street = this.state.street;
    const partTown = this.state.partTown;
    const description = this.state.description;
    const date = new Date();
    const time = new Date();
    const ecv = this.state.ecv.trim();
    const id = this.state.IDNumber;
    const rc = this.state.rc.trim();
    const lBlocks = this.props.blocks && this.props.blocks.length > 0 ? true : false;


    const errorTextPozn = this.state.errorTextPozn;
    const errorTextStreet = !street && !partTown ? 'Je nutné zadať ulicu priestupku.' : '';
    const errorTextDescription = this.state.errorTextDescription ? this.state.errorTextDescription : (description ? '' : 'Je nutné zadať popis priestupku.');
    const errorTextDate = date ? '' : 'Je nutné vybrať dátum.';
    const errorTextTime = time ? '' : 'Je nutné vybrať čas.';
    const errorTextRc = !rc || validator.isValidRc(rc) ? '' : 'RČ môže obsahovať iba číslice (max. 10).';
    const errorTextEcv = ecv ? validator.isValidEcv(ecv) : '';
    const errorTextId = id ? validator.isValidID(id) : '';
    const errorTextPokuta = this.state.solutionId === 11 && this.state.POKUTA <= 0 && this.state.L_REQUIRED_BLOCKS === 1 && lBlocks ? 'Výška pokuty je povinná!' : '';
    const errorTextBlocksRequired = (this.state.solutionId === 3 || this.state.solutionId === 11) && this.state.BLOKY.length === 0 && this.state.L_REQUIRED_BLOCKS === 1 && lBlocks ? 'Pokute musia byť priradené pokutové bloky!' : '';
    const errorTextBlockOne = this.state.solutionId === 11 && this.state.BLOKY.length > 1 && this.state.L_REQUIRED_BLOCKS === 1 ? 'Môže byť priradený iba jeden pokutový blok!' : '';
    var TextBlocksOrder = '';
    if ((this.state.solutionId === 3 || this.state.solutionId === 11) && this.state.BLOKY.length > 0 && this.state.L_ORDER_BLOCKS === 1) {
      if (this.validOrderBlocks())
        TextBlocksOrder = 'Pokutové bloky musia byť priraďované podľa poradia!';
    }
    const errorTextBlocksOrder = TextBlocksOrder;
    if (this.state.EDITABLE_FIELDS == 1) {
      this.setState({
        errorTextStreet, errorTextDescription, errorTextDate, errorTextTime, errorTextRc, errorTextEcv, errorTextId,
        errorTextPokuta, errorTextBlocksRequired, errorTextBlocksOrder, errorTextBlockOne, errorTextPozn,
      });
    } else {
      this.setState({
        errorTextStreet, errorTextDescription, errorTextDate, errorTextTime, errorTextRc, errorTextEcv, errorTextId,
        errorTextPokuta, errorTextBlocksRequired, errorTextBlocksOrder, errorTextBlockOne, errorTextPozn, date, time
      });
    }

    if ((street || partTown) && description && date && time && !errorTextRc && !errorTextEcv && !errorTextId && !errorTextPokuta && !errorTextBlocksRequired && !errorTextBlocksOrder && !errorTextBlockOne && !errorTextPozn && !errorTextDescription)
      this.handleOpenAlert();

  };

  validOrderBlocks = () => {
    var i, x;
    var ret = false;
    var zvazky = this.state.ZVAZKY;
    var zvDis = [];
    var bloky = this.state.BLOKY;
    var blokyAll = this.props.blocks;

    zvazky.forEach(findDist);
    function findDist(val) {
      if (zvDis.find(w => w === val) ? false : true)
        zvDis.push(val);
    }

    zvDis.forEach(validZvazok);
    function validZvazok(zvazok) {
      if (!ret) {
        var blokyZvSel = []; //zoznam vybratych ID blokov pre konkretny zvazok
        for (i = 0; i < zvazky.length; i++) {

          if (zvazky[i] === zvazok)
            blokyZvSel.push(bloky[i]);
        }
        blokyZvSel.sort();

        var blokyZv = [];//zoznam vsetkych ID blokov pre konkretny zvazok
        blokyAll.forEach(addBlokyZv);
        function addBlokyZv(blk) {
          if (blk.C_EV.trim() === zvazok)
            blokyZv.push(blk.I_BLOK);
        }

        for (x = 0; x < blokyZvSel.length; x++) {

          if (blokyZvSel[x] !== blokyZv[x])
            ret = true;
        }
      }
    }
    return ret;
  };

  handleChangeECV = (name, value) => {
    const errorTextEcv = value ? validator.isValidEcv(value) : '';
    this.setState({ ecv: value.toUpperCase(), errorTextEcv });
  };

  handleChangeECVEvo = (name, value) => {
    const errorTextEvoEcv = value ? validator.isValidEcv(value) : '';
    this.setState({ ecv: value.toUpperCase(), errorTextEvoEcv });
  };

  handleChangeImageECV = async (image) => {
    await this.props.dispatch(parkingActions.getParkingAlpr(image.data, this.props.meta.checkAppOffline));
    if (this.props.alpr.Status === 200 && this.props.alpr.Data.length > 0) {
      this.setState({
        ecv: this.props.alpr.Data[0].Characters
      });
    } else this.setState({ ecv: '' });
  };

  handleChangeIdNumber = (name, value) => {
    this.setState({
      IDNumber: value.toUpperCase()
    });
  };

  handleChangeStreet = (searchText) => {
    this.props.dispatch(streetActions.getStreets(searchText, this.props.meta.checkAppOffline));
  };

  handleChangePartTown = (searchText) => {
    this.props.dispatch(partTownActions.getPartsTown(searchText, this.props.meta.checkAppOffline));
  };

  handleChangePersonList = (searchText) => {
    searchText = searchText.trim();
    const errorTextRc = validator.isValidRc(searchText) ? '' : 'RČ môže obsahovať iba číslice (max. 10).';
    this.setState({ errorTextRc });
    this.props.dispatch(personActions.getPersonList(searchText, window.config.autocompleteCountCharStart_person || 6, this.props.meta.checkAppOffline));
  };

  handleSelectPerson = async (name, value) => {

    if (value) {
      if (value.I_O > 0) {
        await this.props.dispatch(personActions.getPerson(value.I_O, this.props.meta.checkAppOffline));
        this.setState({
          person: { I_O: value.I_O, OSOBA: this.props.person.ICO_RC },
          rc: this.props.person.ICO_RC.trim(),
          name: this.props.person.MENO ? this.props.person.MENO : '',
          surname: this.props.person.PF,
          IDNumber: this.props.person.C_OP ? this.props.person.C_OP : '',
          I_O_TYP: this.props.person.I_O_TYP
        });
      }
      else
        this.setState({ person: value, rc: value.OSOBA, name: '', surname: '', IDNumber: '', I_O_TYP: 1 });
    }
    else
      this.setState({ person: { I_O: 0, OSOBA: '' }, rc: '', name: '', surname: '', IDNumber: '', I_O_TYP: 1 });

  };

  handleChangeImage = (image) => {
    const images = this.state.images;
    for (let i = 0; i < images.length; i++) {
      if (images[i].name === image.name) {
        return;
      }
    }

    this.setState(prevState => ({
      images: [...prevState.images, image]
    }));
  };

  handleRemoveImage = (index) => {
    this.setState(prevState => ({
      images: [
        ...prevState.images.slice(0, index),
        ...prevState.images.slice(index + 1)
      ]
    }));
  };

  handleChangePrescript = (name, value) => {
    const prescript = this.props.prescripts.find((prescript) => prescript.I_PRED === value);
    const description = prescript !== undefined ? prescript.TXT_OPIS : '';
    this.setState({
      description: description,
      prescriptId: value
    });
  };

  handleChangeEventType = async (name, value) => {
    this.setState({ I_UDAL_DR: value, prescriptId: null, description: '', solutionId: null });
    await this.props.dispatch(trespassActions.getPrescripts(value, this.props.meta.checkAppOffline));
    await this.props.dispatch(trespassActions.getSolutions(value || 0, this.props.meta.checkAppOffline));
  };

  handleChange = async (name, value) => {
    if (name == "time" && typeof value === 'string') {
      const date = this.state.date;
      date.setHours(...value.split(':'));
      this.setState({ time: date });

    }
    else if (name == "date") {
      this.setState({ date: value, time: value });
    }
    else {
      this.setState({ [name]: value });
    }


    if (name === 'solutionId') {
      if (this.setLBl(value))
        await this.props.dispatch(trespassActions.getBlocks(this.props.user.IdUz, value === 3 ? 0 : 1, this.state.I_M, this.props.meta.checkAppOffline));

    }

    if (name === 'street') {
      this.setState({ I_M: value ? value.I_M : null, BLOKY: [], ZVAZKY: [], POKUTA: 0 });

      if (this.state.L_BL)
        await this.props.dispatch(trespassActions.getBlocks(this.props.user.IdUz, this.state.solutionId === 3 ? 0 : 1, value.I_M, this.props.meta.checkAppOffline));

    }

    if (name === 'pozn') {

      if (value.length > 3999)
        this.setState({ errorTextPozn: 'Dĺžka textu presahuje 4000 znakov!' });
      else
        this.setState({ errorTextPozn: '' });

    }

    if (name === 'description') {

      if (value.length > 3999)
        this.setState({ errorTextDescription: 'Dĺžka textu presahuje 4000 znakov!' });
      else
        this.setState({ errorTextDescription: '' });

    }
  };

  setLBl = (i_ries) => {
    var lBl = false;

    this.props.solutions.forEach(findRies);

    function findRies(value) {
      if (value.I_RIES === i_ries && value.L_BL)
        lBl = true;
    }
    this.setState({ L_BL: lBl, BLOKY: [], ZVAZKY: [], POKUTA: 0 });

    return lBl;
  };

  handleClickBlock = (zvazok, id, suma) => {
    var blockList = this.state.BLOKY;
    var zvazList = this.state.ZVAZKY;
    var pokuta = this.state.POKUTA;

    if (blockList.find(w => w === id) ? true : false) {
      var indexBlok = blockList.indexOf(id);
      blockList.splice(indexBlok, 1);
      zvazList.splice(indexBlok, 1);

      if (this.state.solutionId === 3)
        pokuta = pokuta - suma;
    }
    else {
      blockList.push(id);
      zvazList.push(zvazok.trim());

      if (this.state.solutionId === 3)
        pokuta = pokuta + suma;
    }

    this.setState({ BLOKY: blockList, ZVAZKY: zvazList, POKUTA: pokuta, errorTextBlocksOrder: '' });
  };

  handleEvoSearch = async () => {
    const reason = this.state.reason;
    const ecv = this.state.ecv.trim();
    const vin = this.state.vin;
    const errorTextVin = !ecv && !vin ? 'Je nutné zadať EČV alebo VIN' : '';
    const errorTextEvoEcv = errorTextVin ? errorTextVin : (!vin ? validator.isValidEcv(ecv) : '');
    const errorTextReason = reason ? '' : 'Je nutné zadať dôvod.';

    this.setState({ errorTextVin, errorTextEvoEcv, errorTextReason });

    if (!errorTextVin && !errorTextEvoEcv && !errorTextReason) {
      this.setState({ isLoad: true, openEvo: false });

      let evoFrm = { ecv: ecv, vin: vin, reason: reason };
      await this.props.dispatch(evoActions.getEvo(evoFrm, this.props.meta.checkAppOffline));

      let success = false;
      let successPerson = false;
      if (this.props.evo && this.props.evo.EVIDENCNE_CISLO.trim === this.state.ecv.trim)
        success = true;

      let ico_rc = '';
      if (success) {
        ico_rc = this.props.evo.DRZITEL.RODNE_CISLO ? this.props.evo.DRZITEL.RODNE_CISLO : this.props.evo.DRZITEL.ICO;
        ico_rc = ico_rc.trim();
        ico_rc = ico_rc.replace("/", "");

        if (this.state.L_ACCESS_PERSON === 1) {
          await this.props.dispatch(personActions.getPersonList(ico_rc, window.config.autocompleteCountCharStart_person || 6, this.props.meta.checkAppOffline));

          if (this.props.personList.length === 2) {
            let iO = this.props.personList[1].I_O;

            if (iO > 0) {
              await this.handleSelectPerson('', { I_O: iO, OSOBA: ico_rc });
              successPerson = true;
            }

          }
        }
      }
      else
        this.props.dispatch(userActions.showErrorMsg("Nenašli sa žiadne údaje o vozidle"));

      if (successPerson) {
        const I_FE_REG_LOG = this.props.evoIFeRegLog;
        this.setState({ isLoad: false, I_FE_REG_LOG });
      } else {
        const rc = success ? ico_rc : '';
        const name = success ? this.props.evo.DRZITEL.MENO : '';
        const surname = success ? (this.props.evo.DRZITEL.PRIEZVISKO ? this.props.evo.DRZITEL.PRIEZVISKO : this.props.evo.DRZITEL.NAZOV) : '';
        const I_FE_REG_LOG = success ? this.props.evoIFeRegLog : '';
        const I_O_TYP = success ? (this.props.evo.DRZITEL.ICO ? 2 : 1) : 1;
        this.setState({ isLoad: false, rc, name, surname, I_FE_REG_LOG, I_O_TYP, person: { I_O: 0, OSOBA: rc } });
      }
    }
  };

  render() {
    const { blocks } = this.props;
    if (this.state.ecv) {
      if (this.state.ecv.Ecv) {
        this.setState({ ecv: this.state.ecv.Ecv });
      }
    }
    const fMelements =
      <Box sx={{
        width: "100vw",
        maxWidth: "500px",
        boxSizing: "border-box",
      }}
      >
        <Stack
          boxSizing="border-box"
          direction={"column"}
          p={1}
          mx={2}
          mb={1}
          spacing={1}
        >
          <Typography variant="subtitle1" style={{ marginBottom: '10px', textAlign: "center" }} >Lustrácia vozidla v EVO</Typography>
          <TextField
            label="EČV vozidla"
            errorText={this.state.errorTextEvoEcv}
            error={this.state.errorTextEvoEcv ? true : false}
            onChange={this.handleChangeECVEvo}
            value={this.state.ecv}
            fullwidth={true}
            name='ecv'
            variant="standard"
          />
          <TextField
            label="VIN"
            errorText={this.state.errorTextVin}
            error={this.state.errorTextVin ? true : false}
            onChange={this.handleChange}
            value={this.state.vin}
            fullwidth={true}
            name='vin'
            variant="standard"
          />
          <TextField
            label="Dôvod"
            errorText={this.state.errorTextReason}
            error={this.state.errorTextReason ? true : false}
            onChange={this.handleChange}
            value={this.state.reason}
            fullwidth={true}
            name='reason'
            style={{ marginBottom: '10px' }}
            variant="standard"
          />
        </Stack>
        <Divider variant='fullWidth' style={{ height: '1px', backgroundColor: "#1565c0" }} />
        <div className="row">
          <Button
            variant="text"
            color="primary"
            size="large"
            onClick={() => this.handleEvoClose()}
            style={{ height: '45px', margin: 0, borderRadius: '0px', width: '100%' }}
          ><Typography variant="body1" >Zrušiť</Typography></Button>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={() => this.handleEvoSearch()}
            style={{ height: '45px', margin: 0, borderRadius: '0px', width: '100%' }}
          ><Typography variant="body1" style={{ color: 'white' }}>Vyhľadať</Typography></Button>
        </div>
      </Box >;
    return (
      <div>
        <AppBar title='Evidencia priestupku' />
        <div className='container'>
          <Paper className='content' elevation={1} >
            <>
              <Stack flexDirection='row' alignItems="flex-end" gap={2}
              >
                <DatePicker
                  disabled={this.state.EDITABLE_FIELDS === 1 ? false : true}
                  label="Dátum"
                  style={{ width: '49%' }}
                  value={this.state.date.toISOString()}
                  onChange={this.handleChange}
                  clearable={false}
                  name='date'
                  keyboards={this.state.EDITABLE_FIELDS === 1 ? true : false}
                  disablePast={false}
                  disableFuture={true}
                />
                <TextField
                  name="time"
                  id="time"
                  label="Čas"
                  type="time"
                  disabled={this.state.EDITABLE_FIELDS === 1 ? false : true}
                  clearable={false}
                  value={this.state.time.toLocaleTimeString(("sk-SK"), { hour: "2-digit", minute: "2-digit" })}
                  onChange={this.handleChange}
                  style={{ width: '49%' }}
                  variant="standard"
                />
              </Stack>
              <div style={{ marginTop: "8px" }}>
                <Autocomplete
                  data={this.props.partsTown}
                  isLoading={this.props.isLoadingPartsTown}
                  total={this.props.totalPartsTown}
                  schema={{ label: 'N_CM', value: 'I_CM' }}
                  onChange={this.handleChangePartTown}
                  onSelect={this.handleChange}
                  selectedItem={this.state.partTown}
                  label="Časť mesta"
                  name='partTown'
                  countCharStart={_autocompleteCountCharStart}
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <Autocomplete
                  data={this.props.streets}
                  isLoading={this.props.isLoadingStreets}
                  total={this.props.totalStreets}
                  schema={{ label: 'N_U', value: 'I_U' }}
                  onChange={this.handleChangeStreet}
                  onSelect={this.handleChange}
                  selectedItem={this.state.street}
                  label="Ulica priestupku"
                  errorText={this.state.errorTextStreet}
                  name='street'
                  countCharStart={_autocompleteCountCharStart}
                />
              </div>
              {this.props.eventTypes.length > 0 &&
                <div style={{ marginTop: "8px" }}>
                  <SelectField
                    disabled={this.props.disabled}
                    floatingLabelText="Druh udalosti"
                    value={this.state.I_UDAL_DR}
                    onChange={this.handleChangeEventType}
                    fullwidth={true}
                    schema={{ label: 'N_UDAL_DR', value: 'I_UDAL_DR' }}
                    data={this.props.eventTypes}
                    name='I_UDAL_DR'
                  />
                </div>
              }
              <div style={{ marginTop: "8px" }}>
                <SelectField
                  disabled={this.props.disabled}
                  floatingLabelText="Porušenie predpisu"
                  value={this.state.prescriptId}
                  onChange={this.handleChangePrescript}
                  fullwidth={true}
                  schema={{ label: 'SKR_N_PRED', value: 'I_PRED' }}
                  data={this.props.prescripts}
                  name='prescriptId'
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <TextField
                  disabled={this.props.disabled}
                  label="Opis priestupku"
                  fullwidth={true}
                  multiLine={true}
                  rows={2}
                  value={this.state.description}
                  errorText={this.state.errorTextDescription}
                  error={this.state.errorTextDescription ? true : false}
                  onChange={this.handleChange}
                  name='description'
                  variant="standard"
                />
              </div>
              <div style={{ marginTop: "8px" }}>
                <SelectField
                  disabled={(this.state.street === null && this.state.partTown === null) ? true : false}
                  floatingLabelText="Spôsob vyriešenia"
                  value={this.state.solutionId}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_RIES', value: 'I_RIES' }}
                  data={this.props.solutions}
                  name='solutionId'
                />
              </div>
              {this.state.L_BL && blocks.length > 0 &&
                <div>
                  <NumberMoneyInput
                    style={{ width: '99%' }}
                    disabled={this.state.solutionId === 3 ? true : false}
                    label="Pokuta"
                    fullwidth={true}
                    value={this.state.POKUTA}
                    onChange={this.handleChange}
                    name='POKUTA'
                    id='POKUTA'
                    errorText={this.state.errorTextPokuta}
                    error={this.state.errorTextPokuta ? true : false}
                    decimalScale={0}
                  />
                  {this.state.errorTextBlocksRequired &&
                    <div>
                      <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }} >{this.state.errorTextBlocksRequired}</Typography>
                    </div>}
                  {this.state.errorTextBlocksOrder &&
                    <div>
                      <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }} >{this.state.errorTextBlocksOrder}</Typography>
                    </div>}
                  {this.state.errorTextBlockOne &&
                    <div>
                      <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px', marginTop: '10px', fontWeight: 'bold' }} >{this.state.errorTextBlockOne}</Typography>
                    </div>}
                  <List style={{ position: 'relative', overflow: 'auto', maxHeight: 180, width: '100%' }}>
                    {blocks.map(x => <Block
                      key={x.I_BLOK.toString()}
                      data={x}
                      onChange={() => this.handleClickBlock(x.C_EV, x.I_BLOK, x.SUMA)}
                      checked={this.state.BLOKY.find(y => y === x.I_BLOK) ? true : false}
                    />
                    )}
                  </List>
                  <Divider />
                </div>
              }

              <div className='row' style={{ display: 'flex', alignItems: 'flex-end' }}>
                <span style={{ flex: 1 }}>
                  <div style={{ marginTop: "8px" }}>
                    <TextField
                      style={{ width: this.state.visibleEvo ? '96%' : '100%' }}
                      disabled={this.props.disabled}
                      label="EČV vozidla"
                      fullwidth={true}
                      value={this.state.ecv}
                      errorText={this.state.errorTextEcv}
                      error={this.state.errorTextEcv ? true : false}
                      onChange={this.handleChangeECV}
                      name='ecv'
                      variant="standard"
                    />
                  </div>
                </span>
                {this.state.visibleEvo &&
                  <RaisedButton
                    fullwidth={false}
                    onClick={this.handleEvoOpen}
                    disabled={this.state.isLoad}
                    style={{ width: '30%' }}
                  >
                    {'EVO'}
                  </RaisedButton>
                }
              </div>
              {this.state.L_ACCESS_PERSON === 0 &&
                <div style={{ marginTop: "8px" }}>
                  <TextField
                    disabled={this.props.disabled}
                    label="RČ/IČO"
                    fullwidth={true}
                    value={this.state.rc}
                    errorText={this.state.errorTextRc}
                    error={this.state.errorTextRc ? true : false}
                    onChange={this.handleChange}
                    name='rc'
                    variant="standard"
                  />
                </div>}

              {this.state.L_ACCESS_PERSON === 1 &&
                <div style={{ marginTop: "8px" }}>
                  <Autocomplete
                    data={this.props.personList}
                    total={this.props.totalPerson}
                    isLoading={this.props.isLoadingPersonList}
                    schema={{ label: 'OSOBA', value: 'I_O' }}
                    onChange={this.handleChangePersonList}
                    onSelect={this.handleSelectPerson}
                    selectedItem={this.state.person}
                    label="RČ/IČO"
                    errorText={this.state.errorTextRc}
                    name='person'
                    countCharStart={window.config.autocompleteCountCharStart_person || 6}
                  />
                </div>}

              <div style={{ marginTop: "8px" }}>
                <div className='row'>
                  <TextField
                    disabled={this.props.disabled}
                    style={{ width: '49%' }}
                    label="Meno"
                    value={this.state.name}
                    onChange={this.handleChange}
                    name='name'
                    variant="standard"
                  />


                  <TextField
                    disabled={this.props.disabled}
                    style={{ width: '49%' }}
                    label="Priezvisko/Názov"
                    value={this.state.surname}
                    onChange={this.handleChange}
                    name='surname'
                    variant="standard"
                  />
                </div>
              </div>
              <div className='row' style={{ marginTop: "8px" }}>
                <TextField
                  disabled={this.props.disabled}
                  style={{ width: '100%' }}
                  label="Číslo OP"
                  fullwidth={true}
                  value={this.state.IDNumber}
                  errorText={this.state.errorTextId}
                  error={this.state.errorTextId ? true : false}
                  onChange={this.handleChangeIdNumber}
                  name='IDNumber'
                  variant="standard"
                />
              </div>
              <div className='attachments'>
                {this.state.images.map((image, index) =>
                  <Attachment
                    disabled={this.props.disabled}
                    key={index}
                    image={image}
                    onRemove={() => this.handleRemoveImage(index)}
                  />
                )}
              </div>
              <AttachmentInput
                disabled={this.props.disabled}
                onChange={this.handleChangeImage}
                multiple={true}
              />
              <div style={{ marginTop: "8px" }}>
                <TextField
                  disabled={this.props.disabled}
                  label="Poznámka"
                  fullwidth={true}
                  multiLine={true}
                  rows={2}
                  value={this.state.pozn}
                  errorText={this.state.errorTextPozn}
                  error={this.state.errorTextPozn ? true : false}
                  onChange={this.handleChange}
                  name='pozn'
                  variant="standard"
                />
              </div>
              <RaisedButton
                fullwidth={true}
                onClick={this.handleAdd}
                disabled={this.state.isLoad}
              >
                {'Zaevidovať priestupok'}
              </RaisedButton>
              <Error text={this.state.errorText} />
            </>
          </Paper>
        </div>
        <Dialog
          open={this.state.openAlert}
          onClose={this.handleCloseAlert}
          contentText='Zaevidovať priestupok?'
          title='Otázka'
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAlert,
              keyboardFocused: false
            },
            {
              label: 'Evidovať',
              color: 'primary',
              onClick: this.addTrespass,
              keyboardFocused: true
            }
          ]}
        />
        <FormModal
          disableMargins={true}
          open={this.state.openEvo}
          onClose={this.handleEvoClose}
          elements={fMelements}
          width="100%"
        />
        <CircularProgress loading={this.state.isLoad || this.props.isLoadingBlocks || this.props.isLoadingStreets || this.props.isLoadingPartsTown || this.props.isLoadingSolutions || this.props.isLoadingPerson} />
      </div >
    );
  }
}

class Block extends React.Component {
  render() {
    return (
      <ListItem style={{ width: '100%', paddingBottom: '0px', paddingTop: '0px', backgroundColor: this.props.data.L_COLOR ? '#eeeeee' : '' }}>
        <div className='row' style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
          <CheckBox
            toolTip="Označte blok ktorý chcete použiť."
            toolTipPlace="right"
            color='secondary'
            checked={this.props.checked ? this.props.checked : false}
            onChange={() => this.props.onChange()}
            value='check'
            style={{ marginLeft: '20px' }}
          />
          <Typography >{this.props.data.BLOK}</Typography>
          <Typography style={{ marginRight: '20px', fontWeight: 'bold' }}>{this.props.data.SUMA}€</Typography>
        </div>
      </ListItem>

    );
  }
}

Block.propTypes = {
  onChange: PropTypes.func,
  checked: PropTypes.bool.isRequired,
};


const mapStateToProps = (state) => {
  return {
    prescripts: state.prescripts.data,
    eventTypes: state.eventTypes.data,
    streets: state.streets.data,
    isLoadingStreets: state.streets.isLoading,
    totalStreets: state.streets.total,
    partsTown: state.partsTown.data,
    isLoadingPartsTown: state.partsTown.isLoading,
    totalPartsTown: state.partsTown.total,
    disabled: state.trespass.disabled,
    solutions: state.solutions.data,
    isLoadingSolutions: state.solutions.isLoading,
    blocks: state.blocks.data,
    isLoadingBlocks: state.blocks.isLoading,
    user: state.user.data,
    alpr: state.alpr,
    phRequiredBlocks: state.phRequiredBlocks.data,
    phOrderBlocks: state.phOrderBlocks.data,
    evo: state.evo.data,
    evoIFeRegLog: state.evo.i_fe_reg_log,
    personList: state.personList.data,
    totalPerson: state.personList.total,
    isLoadingPersonList: state.personList.isLoading,
    person: state.person.data.Data,
    isLoadingPerson: state.person.isLoading,
    phAccessPerson: state.phAccessPerson.data,
    isEditableFields: state.editableFields.data
  };
};

export default withRouter(withMeta(connect(mapStateToProps)(TrespassAdd)));