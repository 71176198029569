import React from 'react';
import PropTypes from 'prop-types';
import Dialog from '../feedBack/Dialog';

/**
 * Detail component
 * @module
 * @param {bool} open - Whether the dialog is open
 * @param {func} [handleClose] - Close handler function
 */
class Detail extends React.Component {
  render() {
    return (
      <Dialog
        modal={false}
        open={this.props.open}
        onClose={this.props.handleClose}
        content={this.props.detail}
        title=''
        actions={[
          {
            label: 'Zrušiť',
            color: 'primary',
            onClick: this.props.handleClose,
            keyboardFocused: true
          }
        ]}
      />
    );
  }
}

Detail.propTypes = {
  open: PropTypes.bool.isRequired,
  detail: PropTypes.element,
  handleClose: PropTypes.func,
}

export default Detail;