const routes = {
  PARKING_TICKET: '/parkovanie/parkovne',
  PARKING_CARD: '/parkovanie/parkovacia-karta',
  PARKING_SEARCH: '/parkovanie',
  TRESPASS_PERSON: '/osoba/priestupky',
  PERSON_SEARCH: '/osoba',
  PERSON: '/osoba/evidencia',
  TRESPASS_CAR: '/vozidlo/priestupky',
  TRESPASS_CAR_SEARCH: '/vozidlo',
  TRESPASS_ADD: '/evidencia',
  DOG: '/pes/znamky',
  DOG_SEARCH: '/pes',
  EVO: '/evo/vozidlo',
  EVO_REALTIME: '/evo/realtime',
  EVO_SEARCH: '/evo',
  HOME: '/',
  LOGIN: '/prihlasenie',
  MSG_READ: '/spravy_precitane',
  MSG_UNREAD: '/spravy_neprecitane',
  MAP_REPORT: '/hlasenia',
}

export default routes;