import actionTypes from '../../actions/ActionTypes';

const _initState = { 
  isLoading: false, 
  data: []
}

const trespassesByPerson = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_TRESPASSES_BY_PERSON_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_TRESPASSES_BY_PERSON_SUCCESS:
      return { isLoading: false, data: action.res.Data }

    case actionTypes.GET_TRESPASSES_BY_PERSON_FAILURE:
      return _initState;
  
    default:
      return state
  }
}

export default trespassesByPerson;