import actionTypes from '../../actions/ActionTypes';

const _initState = { 
    HasUrl: true,
    IsValidUrl: true
}

const extInfo = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_PARKING_EXT_INFO: 
      return { ...action.res }
  
    default:
      return state
  }
}

export default extInfo;