import actionTypes from '../actions/ActionTypes';

const errorDialog = (state = [], action) => {
  switch (action.type) {
    case actionTypes.SHOW_REQUEST_FAILURE_DIALOG: {
      if(state.includes(action.message))
        return [...state];
        
      return  [...state, action.message];
    }
    case actionTypes.HIDE_REQUEST_FAILURE_DIALOG:
        return [];
    default:
      return state;
  }
}

export default errorDialog;