import React from 'react';
import List from './List';
import PropTypes from 'prop-types';
import CircularProgress from '../feedBack/progress/CircularProgress';

const style = {
  display: 'flex',
  justifyContent: 'center',
}

/**
 * Infinite list component
 * @module
 * @param {bool} [loading] - Whether the list is loading
 */
class InfiniteList extends React.Component {

  componentWillMount() {
    window.onscroll = (event) => {
      if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight) {
        this.props.onEnd();
      }
    };
  }

  componentWillUnmount() {
    window.onscroll = null;
  }

  render() {
    return (
      <div>
        <List>
          {this.props.children}
        </List>
        <div style={style}>
          <CircularProgress loading={this.props.loading} />
        </div>
      </div>
    );
  }
}

InfiniteList.propTypes = {
  loading: PropTypes.bool,
}

export default InfiniteList;