import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';

/**
 * Typography component
 * @module
 * @param {string} [align] - Set the text-align on the component
 * @param {string} [color] - The color of the component
 * @param {string} [variant] - Applies the theme typography styles
 * @param {object} [style] - Typography styles
 * @param {object} [classes] - Typography classes
 * @param {string} [className] - Typography classes
 */
class TypographyComponent extends React.Component {
  render() {
    return (
      <Typography
        align={this.props.align}
        color={this.props.color}
        variant={this.props.variant}
        style={this.props.style}
        classes={this.props.classes}
        className={this.props.className}
      >
        {this.props.children}
      </Typography>
    )
  }
}

TypographyComponent.propTypes = {
  align: PropTypes.string,
  color: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
  classes: PropTypes.object,
  className: PropTypes.string,
}

export default TypographyComponent;