import React from 'react';
import PropTypes from 'prop-types';

/**
 * Telephone container component
 * @module
 * @param {array} data - Array of phone numbers
 */
class TelContainer extends React.Component {
  render() {
    return (
      <span>
        {this.props.data.map((tel, index) =>
          <span key={index}>
            {index > 0 && <span>, </span>}
            <a href={'tel:' +tel}>{tel}</a>
            </span>
        )}
      </span>
    );
  }
}  

TelContainer.propTypes = {  
  data: PropTypes.array.isRequired,
}

export default TelContainer;