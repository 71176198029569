import React from 'react';
import HomeIcon from '@mui/icons-material/Home';

class HomeIconComponent extends React.Component {
  render() {
    return (
      <HomeIcon />
    )
  }
}

export default HomeIconComponent;