import React from 'react';
import PropTypes from 'prop-types';
import Footer from '../../coraWebMComponents/surfaces/Footer';
import { connect } from 'react-redux';

import AppBar from '../../coraWebMComponents/surfaces/AppBar';

class AppBarComponent extends React.Component {
  render() {
    return (
      <div>
        <AppBar
          title={this.props.title}
          onLeftIconButtonTouchTap={this.props.onLeftIconButtonTouchTap}
          iconElementLeft={this.props.iconElementLeft}
          closable={this.props.closable}
        />
        <Footer text={'Prihlásený: ' + this.props.user.UserFullName} />
      </div>
    )
  }
}

AppBarComponent.propTypes = {
  title: PropTypes.string,
  onLeftIconButtonTouchTap: PropTypes.func,
  iconElementLeft: PropTypes.element,
  closable: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data
  }
}

export default connect(mapStateToProps)(AppBarComponent);