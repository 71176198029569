import React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import NumberFormat from 'react-number-format';
import { withStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

const styles = theme => ({
  formControl: {
    margin: theme.spacing.unit,
  },
});

function TextMaskCustom(props) {
  const { inputRef, ...other } = props;

  return (
    <MaskedInput
      {...other}
      ref={ref => { inputRef(ref ? ref.inputElement : null); }}
      mask={['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
      placeholderChar={'\u2000'}
      showMask
    />
  );
}

TextMaskCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
};


function getNumberFormatCustom(scale) {
  return (props) => {
    const { inputRef, onChange, ...other } = props;
    return (
      <NumberFormat
        {...other}
        getInputRef={inputRef}
        thousandSeparator="."
        suffix=" €"
        decimalSeparator=","
        decimalScale={scale}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
      />
    );
  }
}

class NumberMoneyInputsComponent extends React.Component {
  constructor(props) {
    super(props);
    this.nFc = getNumberFormatCustom(this.props.decimalScale);

  }
  render() {
    const { classes } = this.props;
    return (
      <TextField
        disabled={this.props.disabled}
        style={this.props.style}
        fullWidth={this.props.fullwidth}
        className={classes.formControl}
        label={this.props.label}
        value={this.props.value}
        onChange={event => this.props.onChange(this.props.name, parseFloat(event.target.value))}
        id={this.props.id}
        InputProps={{ inputComponent: this.nFc }}
        inputProps={this.props.inputProps}
        helperText={this.props.errorText}
        error={this.props.error}
      />
    )
  }
}

NumberMoneyInputsComponent.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object,
  errorText: PropTypes.string,
  error: PropTypes.bool,
  fullwidth: PropTypes.bool,
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  classes: PropTypes.object,
  decimalScale: PropTypes.number,
  inputProps: PropTypes.object,
}

export default withStyles(styles)(NumberMoneyInputsComponent);
