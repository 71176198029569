import React from 'react';
import PropTypes from 'prop-types';
import AppBarDrawer from '../../coraWebMComponents/surfaces/AppBarDrawer';
import Footer from '../../coraWebMComponents/surfaces/Footer';
import * as storage from '../../coraWebMComponents/utils/storage';
import * as userActions from '../../actions/UserActions';
import Routes from '../../routes';
import { connect } from 'react-redux';
import menu from './menu';
import withRouter from '../../coraWebMComponents/utils/withRouter';

class AppBarComponent extends React.Component {

  handleLogout = async (event) => {
    storage.removeUser();
    await this.props.dispatch(userActions.logout());
    this.props.navigate(Routes.LOGIN);
  };

  render() {
    return (
      <div>
        <AppBarDrawer
          title={this.props.title}
          onLeftIconButtonTouchTap={this.props.onLeftIconButtonTouchTap}
          onLogout={this.handleLogout}
          logoutStyle={{ marginTop: 'auto' }}
          allowedURLS={this.props.user.AllowedURLS}
          menu={menu}
        />
        <Footer text={'Prihlásený: ' + this.props.user.UserFullName} />
      </div>
    )
  }
}

AppBarComponent.propTypes = {
  title: PropTypes.string,
  onLeftIconButtonTouchTap: PropTypes.func,
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data
  }
}

export default withRouter(connect(mapStateToProps)(AppBarComponent));