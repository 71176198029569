import actionTypes from '../../actions/ActionTypes';

const msg = (state = null, action) => {
  switch (action.type) {
    case actionTypes.SHOW_MSG:
      return action.msg;
    
    case actionTypes.HIDE_MSG:
      return null;
  
    default:
      return state
  }
}

export default msg;