import actionTypes from './ActionTypes';
import * as parkingUtils from '../utils/ParkingUtils';
import * as userActions from './UserActions';
import CoraError from '../coraWebMComponents/utils/coraError';

const getParkingAlprSuccess = (res) => ({
  type: actionTypes.GET_PARKING_ALPR_SUCCESS,
  res
});

const getParkingAlprRequest = () => ({
  type: actionTypes.GET_PARKING_ALPR_REQUEST
});

const getParkingAlprFailure = (error) => ({
  type: actionTypes.GET_PARKING_ALPR_FAILURE,
  error
});

export const getParkingAlpr = (image, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingAlprRequest(image));
      let res = await parkingUtils.getParkingAlpr(image);
      dispatch(getParkingAlprSuccess(res));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
        dispatch(getParkingAlprFailure(error))
        console.error(error);
      }
    }
  }
}

const getParkingExtInfoSuccess = (res) => ({
  type: actionTypes.GET_PARKING_EXT_INFO,
  res
});

export const getParkingExtInfo = () => {
  return async (dispatch) => {
    try {
      let res = await parkingUtils.getParkingExtInfo();
      dispatch(getParkingExtInfoSuccess(res));
    } catch (e) {
      dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
      console.error(e);
    }
  }
}

const clearParkingCardsSuccess = () => ({
  type: actionTypes.CLEAR_PARKING_CARDS
});

export const clearParkingCards = () => {
  return async (dispatch) => {
    dispatch(clearParkingCardsSuccess());
  }
}

const getParkingCardsSuccess = (res, page) => ({
  type: actionTypes.GET_PARKING_CARDS_SUCCESS,
  res,
  page
});

const getParkingCardsRequest = (page) => ({
  type: actionTypes.GET_PARKING_CARDS_REQUEST,
  page
});

const getParkingCardsFailure = (error) => ({
  type: actionTypes.GET_PARKING_CARDS_FAILURE,
  error
});

export const getParkingCards = (ecv, page, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingCardsRequest(page));
      let res = await parkingUtils.getParkingCards(ecv, page);
      dispatch(getParkingCardsSuccess(res, page));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
        dispatch(getParkingCardsFailure(error))
        console.error(error);
      }
    }
  }
}

const clearParkingCardsListSuccess = () => ({
  type: actionTypes.CLEAR_PARKING_CARDS_LIST
});

export const clearParkingCardsList = () => {
  return async (dispatch) => {
    dispatch(clearParkingCardsListSuccess());
  }
}

const getParkingCardsListSuccess = (data) => ({
  type: actionTypes.GET_PARKING_CARDS_LIST_SUCCESS,
  data
});

const getParkingCardsListRequest = () => ({
  type: actionTypes.GET_PARKING_CARDS_LIST_REQUEST
});

const getParkingCardsListFailure = (error) => ({
  type: actionTypes.GET_PARKING_CARDS_LIST_FAILURE,
  error
});

export const getParkingCardsList = (ecv, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingCardsListRequest());
      let data = await parkingUtils.getParkingCardsList(ecv);
      dispatch(getParkingCardsListSuccess(data));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
        dispatch(getParkingCardsListFailure(error))
        console.error(error);
      }
    }
  }
}

const getParkingTicketsSuccess = (res, page) => ({
  type: actionTypes.GET_PARKING_TICKETS_SUCCESS,
  res,
  page
});

const getParkingTicketsRequest = (page) => ({
  type: actionTypes.GET_PARKING_TICKETS_REQUEST,
  page
});

const getParkingTicketsFailure = (error) => ({
  type: actionTypes.GET_PARKING_TICKETS_FAILURE,
  error
});

export const getParkingTickets = (ecv, page, checkAppOffline) => {
  return async (dispatch) => {
    try {
      dispatch(getParkingTicketsRequest(page));
      let res = await parkingUtils.getParkingTickets(ecv, page);
      dispatch(getParkingTicketsSuccess(res, page));
    } catch (error) {
      if (error instanceof CoraError && error.code === "503" && checkAppOffline) {
        await checkAppOffline();
      } else {
        dispatch(userActions.showErrorMsg('Neočakávaná chyba.'));
        dispatch(getParkingTicketsFailure(error))
        console.error(error);
      }
    }
  }
}