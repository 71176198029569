import * as convert from '../coraWebMComponents/utils/convert';
import * as requests from '../coraWebMComponents/utils/requests';


export const getDogs = async (form) => {
  try {
    return await requests.get(`/api/Dog?znamka=${form.sign}&cip=${form.cip}&rc=${form.rc}&meno=${form.name}&priezvisko=${form.surname}&datumNarodenia=${convert.dateToDatestamp(form.birthDate)}`);
  } 
  catch (error) {
   throw error; 
  }
}