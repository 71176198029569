import React from 'react';
import ListItem from '../../coraWebMComponents/dataDisplay/ListItem';
import List from '../../coraWebMComponents/dataDisplay/List';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import AppBar from '../appBar/AppBar';
import LinearProgress from '../../coraWebMComponents/feedBack/progress/LinearProgress';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import { connect } from 'react-redux';
import routes from '../../routes';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as trespassActions from '../../actions/TrespassActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withMeta from '../../coraWebMComponents/utils/withMeta';

class ItemComponent extends React.Component {

  personSearchStateFromPerson = (person) => {
    return {
      rc: person.ICO_RC ? person.ICO_RC : '',
      name: person.MENO ? person.MENO : '',
      surname: person.PF ? person.PF : '',
      IDNumber: person.C_OP ? person.C_OP : ''
    };
  }

  handleOpenPersonSearch = (person) => {
    this.props.navigate(routes.TRESPASS_ADD, { state: this.personSearchStateFromPerson(person) });
  }

  handleOpenTrespassSearch = (person) => {
    this.props.navigate(routes.TRESPASS_PERSON, { state: this.personSearchStateFromPerson(person) });
  }

  render() {
    return (
      <ListItem >
        <div className="item">
          <div><b>Meno a priezvisko osoby:</b> {this.props.data.OSOBA}</div>
          <div><b>Dát. nar.:</b> {convert.convertDate(this.props.data.D_NAR)}</div>
          <div><b>Adresa TP:</b> {this.props.data.ADRESA}</div>
          <div><b>Rodinní príslušníci:</b> {this.props.data.PRIBUZNI}</div>
          <div><b>Číslo OP:</b>{this.props.data.C_OP}</div>
          <RaisedButton
            fullwidth={false}
            onClick={() => { this.handleOpenTrespassSearch(this.props.data) }}
          >
            {'Priestupky osoby'}
          </RaisedButton>
        </div>
      </ListItem>
    );
  }
}

const Item = withRouter(ItemComponent);

class Person extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openDialog: true,
      disableTrespassAdd: true,
    };
  }

  handleCloseAlert = () => {
    this.setState({ openDialog: false });
    this.props.navigate(routes.PERSON_SEARCH);
  };

  newTrespass = (person) => {
    this.handleCloseAlert();
    this.props.navigate(routes.TRESPASS_ADD, { state: this.props.location.state });
  }

  render() {
    return (
      <div>
        <AppBar title='Osoba v evidencii' closable />
        <LinearProgress loading={this.props.isLoading} />
        <div className='container'>
          <Paper className='content' elevation={1}>
            <React.Fragment>
              {(this.props.people.length === 0 && !this.props.isLoading) &&
                <Dialog
                  open={this.state.openDialog}
                  onClose={this.handleCloseAlert}
                  contentText={'Osoba sa nenachádza v evidencií.'}
                  title='Otázka'
                  actions={[
                    {
                      label: 'Zrušiť',
                      color: 'primary',
                      onClick: this.handleCloseAlert,
                      keyboardFocused: false
                    },
                    {
                      disabled: this.state.disableTrespassAdd,
                      label: 'Zaevidovať priestupok',
                      color: 'primary',
                      onClick: this.newTrespass,
                      keyboardFocused: true
                    }
                  ]}
                />
              }
              <List>
                {this.props.people.map((person) =>
                  <Item key={person.I_O} data={person} />
                )}
              </List>
            </React.Fragment>
          </Paper>
        </div>
      </div>
    );
  }

  componentDidMount() {
    const form = this.props.location.state;
    if (form) {
      this.props.dispatch(trespassActions.getPeople(form, this.props.meta.checkAppOffline));
    }
    else {
      this.props.navigate(routes.PERSON_SEARCH);
    }

    var disTresAdd = true;
    if (this.props.user.Groups.find(x => (x === 1700 || x === 1706)) ? true : false)
      disTresAdd = false;

    this.setState({ disableTrespassAdd: disTresAdd });
  }
}

const mapStateToProps = (state) => {
  return {
    people: state.people.data,
    isLoading: state.people.isLoading,
    user: state.user.data,
  }
}

export default withRouter(withMeta(connect(mapStateToProps)(Person)));